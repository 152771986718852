import React from "react";
import {
  Container,
  Box,
  styled,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";

const TitleHeaderBox = styled(Box)(() => ({
  padding: "2rem",
  display: "flex",
  alignItems: "center",
  backgroundImage: `
      linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url("https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/2149352235+1.png")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  minHeight: 350,
}));

const TermsOfUse = () => {
  return (
    <Box>
      <TitleHeaderBox
        sx={{
          "@media (max-width: 600px)": {
            padding: "1rem",
            minHeight: "100px",
            maxHeight: "200px",
          },
        }}
      >
        <Typography
          component="h4"
          fontWeight={600}
          sx={{
            color: "#ffffff",
            fontSize: "3.5rem",
            "@media (max-width: 600px)": {
              fontSize: "2rem",
            },
          }}
        >
          Terms of use
        </Typography>
      </TitleHeaderBox>
      <Container sx={{ my: 5 }} maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          sx={{
            my: 5,
            width: "100%",
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              flexDirection: "column",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              Disclaimer
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/Disclaimer.pdf"
              download
              sx={{
                "@media (max-width: 600px)": {
                  my: 2,
                  textAlign: "center",
                },
              }}
            >
              Download Disclaimer
            </PrimaryButton>
          </Grid>
        </Grid>

        {/* Privacy */}

        <Grid
          container
          justifyContent="space-between"
          sx={{
            my: 5,
            width: "100%",
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              flexDirection: "column",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/PrivacyPolicy.pdf"
              download
              sx={{
                "@media (max-width: 600px)": {
                  my: 2,
                  textAlign: "center",
                },
              }}
            >
              Download Privacy Policy
            </PrimaryButton>
          </Grid>
        </Grid>

        {/* Terms and conditions */}

        <Grid
          container
          justifyContent="space-between"
          sx={{
            my: 5,
            width: "100%",
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              flexDirection: "column",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              Terms and Conditions
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/TermsConditions.pdf"
              download
              sx={{
                "@media (max-width: 600px)": {
                  my: 2,
                  textAlign: "center",
                },
              }}
            >
              Download Terms & Conditions
            </PrimaryButton>
          </Grid>
        </Grid>

        {/* Return and refund */}

        <Grid
          container
          justifyContent="space-between"
          sx={{
            my: 5,
            width: "100%",
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              flexDirection: "column",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              Return and Refund Policy
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/Return%26RefundPolicy.pdf"
              download
              sx={{
                "@media (max-width: 600px)": {
                  my: 2,
                  textAlign: "center",
                },
              }}
            >
              Download Return and Refund Policy
            </PrimaryButton>
          </Grid>
        </Grid>

        {/* Saa Agreement */}
        <Grid
          container
          justifyContent="space-between"
          sx={{
            my: 5,
            width: "100%",
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              flexDirection: "column",
            },
          }}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  textAlign: "center",
                },
              }}
            >
              SASS Agreement
            </Typography>
          </Grid>
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/SAASAgreementPremium.pdf"
              download
              sx={{
                "@media (max-width: 600px)": {
                  my: 2,
                  textAlign: "center",
                },
              }}
            >
              Download Sass Agreement
            </PrimaryButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TermsOfUse;
