import { Box, Checkbox, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import SolrufTextField from '../../components/SolrufTextField/SolrufTextField';
import LoadingButtonPrimary from '../../components/LoadingButtonPrimary/LoadingButtonPrimary';
import SendIcon from '@mui/icons-material/Send';
import {
   ColHeadingWrapper,
   DetailsFieldBox,
   FieldsWrapper,
   IntroWrapper,
   Wrapper,
} from './offerForm.style';
import { axiAuth, axiosInstance } from '../../utils/axiosInstance';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { saveUser } from '../../redux/slices/userSlice';
import { product } from './defaultProduct';
import useSolrufPinCode from '../../hooks/useSolrufPinCode';
import { useDebounce } from 'use-debounce/lib';
import BackToButton from '../../components/BackToButton/BackToButton';
import otpAnimation from '../../assets/otp.gif';
import Lottie from 'lottie-react';
import solarHouse from '../../assets/lottie/solar-house.json';
import solarWindMill from '../../assets/lottie/solar-windmill.json';
import PrimaryButton from '../../components/Custom/PrimaryButton/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// import { saveUser } from '../../redux/slices/userSlice';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { withStyles } from '@mui/styles';
import { sendWhatsappNotification } from '../../utils/sendWhatsappNotification';
import saveFormDataToGoogleSheet from '../../utils/saveFormDataToSheet';

const StyledCheckbox = withStyles({
   root: {
      '& .MuiSvgIcon-root': {
         borderRadius: '50%',
      },
   },
})((props) => (
   <Checkbox
      disableRipple
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
      {...props}
   />
));

const StyledSolrufTextField = styled(SolrufTextField)(({ theme }) => ({
   '& label.Mui-focused': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
   },
   '& .MuiOutlinedInput-root': {
      '& fieldset': {
         borderColor: theme.palette.primary.main,
         borderWidth: '2px',
         borderRadius: '20px',
      },
      '&:hover fieldset': {
         borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
         borderColor: theme.palette.primary.main,
      },
   },
}));

const OfferForm = () => {
   const [sendingOtp, setSendingOtp] = useState(false);
   const [verifying, setVerifying] = useState(false);
   const [page, setPage] = useState('intro');

   const dispatch = useDispatch();

   const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { isSubmitting, errors: formErrors },
   } = useForm();

   const [watchPinCode] = watch(['pincode']);
   const [debouncedPinCode] = useDebounce(watchPinCode, 500);

   const { indiaState, district } = useSolrufPinCode(debouncedPinCode);

   const [whatsappChecked, setWhatsappChecked] = useState(true);

   const handleWhatsappChecked = (event) => {
      setWhatsappChecked(event.target.checked);
   };

   const submitHandler = async (formData) => {
      const { mobile } = formData;

      if (page === 'intro') {
         setPage('sendOtp');
      }

      if (page === 'sendOtp') {
         try {
            setSendingOtp(true);
            const timestamp = Date.now();
            const formattedDate = new Date(timestamp).toLocaleString();

            await saveFormDataToGoogleSheet(
               {
                  name: formData?.name,
                  phone: formData?.mobile,
                  email: formData?.email,
                  address: formData?.address,
                  whatsapp_subscribe: whatsappChecked ? 'yes' : 'no',
                  pincode: formData.pincode,
                  timestamp: formattedDate,
               },
               'https://script.google.com/macros/s/AKfycbwi72Fx2dFtVvZtDfwwhoMnHgE6hiMzGJRJOymSy-GoPtWO3xqbgh61z0JmXSK0oYGc6w/exec'
            );

            const { status } = await axiosInstance.post('api/login', {
               mobile,
            });

            if (status === 200) {
               setSendingOtp(false);
               toast.success('OTP Sent');
               setPage('verifyOtp');
            }
         } catch (error) {
            try {
               const registerData = {
                  first_name: formData.name,
                  last_name: 'n/a',
                  mobile: formData.mobile,
                  role: 'User',
                  // email: formData.email,
                  opt_for_whatsapp_messages: whatsappChecked,
               };
               const { status } = await axiosInstance.post(
                  // register  user
                  'api/register',
                  registerData
               );

               if (status === 200 || status === 201) {
                  toast.success('Otp Sent');
                  setSendingOtp(false);
                  setPage('verifyOtp');
                  // reset();
               }
            } catch (error) {
               setSendingOtp(false);
               toast.error(error.response.data.message);
            }
         }
      }
      console.log(mobile);

      if (page === 'verifyOtp') {
         try {
            setVerifying(true);
            const { status, data } = await axiosInstance.post(
               'api/verify-otp',
               {
                  mobile,
                  otp: formData.otp,
               }
            );
            if (status === 200) {
               reset();
               setPage('outro');
               setTimeout(() => {
                  setPage('intro');
               }, 300000);
               toast.success(`Welcome back ${data.user.first_name}!`);
               dispatch(saveUser(data));
               await createAnEnquiry(formData);
                sendWhatsappNotification(
                  data.user.first_name,
                  data.user.mobile,
                  'site_visit_regard',
                  [data.user.first_name, '+91-8600694140', 'sumit@solruf.com'],
                  {
                     url: 'https://i.ibb.co/9hC7bzw/site-visit-regard.png',
                     filename: 'test',
                  }
               );
               setVerifying(false);
               window.location.href(
                  'https://calendly.com/solruf/solruf-installer-platform-onboarding'
               );
            }
         } catch (error) {
            setVerifying(false);
            toast.error(error?.response?.data?.message);
         }
      }
   };

   const createAnEnquiry = async (formData) => {
      const d = new Date();
      const mySqlFormatDate = d.toISOString().split('T')[0];
      const enquiryData = {
         product_id: product?.product_id,
         quantity: 1,

         delivery_date: mySqlFormatDate,
         accept_other_brands: true,
         share_company_information: false,
         user_address: {
            pin_code: debouncedPinCode,
            state: indiaState,
            city: district,
            address: formData?.address,
         },
         product: {
            // name: productDetails?.product_name,
            other: {
               attributes: product?.attributes?.filter(
                  (attribute) =>
                     attribute?.attribute_values[
                        attribute?.attribute_values?.length - 1
                     ]?.views?.procurement_card?.visibility
               ),
               defaultImage: product?.default_image,
               productSlug: product?.product_slug,
               productId: product?.product_id,
               productName: product?.product_name,
               hsn_sac_code: product?.hsn_sac_code,
               tax_igst: product?.tax_igst,
               tax_cgst: product?.tax_cgst,
               tax_sgst: product?.tax_sgst,
            },
         },
      };

      try {
         const { status } = await axiAuth.post('api/enquiries', enquiryData);
         if (status === 200) {
            toast.success('Enquiry Created');
         }
      } catch (error) {
         toast.error('Enquiry Failed');
      }
   };

   return (
      <>
         <Box
            sx={{
               top: '0',
               transform: 'translateY(-30rem)',
               '@media (max-width: 600px)': {
                  transform: 'translateY(-10rem)',
               },
            }}
            id='offer-form'
         />
         <Wrapper
            component='form'
            onSubmit={handleSubmit(submitHandler)}
            sx={{
               px: 6,
               '@media (max-width: 600px)': {
                  px: 2,
               },
            }}
         >
            <Box sx={{ width: '100%' }}>
               {page === 'verifyOtp' && (
                  <BackToButton
                     onClick={() => setPage('sendOtp')}
                  ></BackToButton>
               )}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
               {page === 'outro' && (
                  <CloseIcon
                     sx={{
                        cursor: 'pointer',
                        fontSize: '2rem',
                        color: 'primary.dark',
                        opacity: '0.5',
                     }}
                     onClick={() => setPage('intro')}
                  />
               )}
            </Box>

            {page === 'intro' && (
               <>
                  <Box />
                  <IntroWrapper>
                     <Lottie
                        animationData={solarWindMill}
                        loop={true}
                        style={{ height: '22rem' }}
                     />
                     <Typography
                        variant='h3'
                        sx={{
                           fontWeight: 600,
                           '@media (max-width: 1200px)': {
                              fontSize: '1.8rem',
                           },
                           '@media (max-width: 600px)': {
                              fontSize: '1.6rem',
                           },
                        }}
                     >
                        Book a Solar site visit
                     </Typography>

                     <Typography
                        variant='h5'
                        fontWeight={500}
                        sx={{
                           '@media (max-width: 1200px)': {
                              fontSize: '1rem',
                           },
                           '@media (max-width: 600px)': {
                              fontSize: '0.9rem',
                           },
                        }}
                     >
                        Get upto top 10 solar quotation in just 60 mins.
                     </Typography>
                  </IntroWrapper>
               </>
            )}

            {page === 'outro' && (
               <>
                  <Box />
                  <IntroWrapper>
                     <Lottie
                        animationData={solarHouse}
                        loop={true}
                        style={{ height: '14rem', transform: 'scale(2.2)' }}
                     />
                     <Typography
                        component='h2'
                        variant='h4'
                        sx={{ fontWeight: 600 }}
                     >
                        Thank you for your Booking! 🎉
                     </Typography>

                     <Typography
                        variant='p'
                        fontWeight={500}
                        textAlign='center'
                     >
                        We wil get back to you shortly.
                     </Typography>
                     <Typography
                        variant='p'
                        fontWeight={500}
                        textAlign='center'
                     >
                        You can check your solar installation enquiry status in
                        your dashboard or by the link below. 👇 Get top 10 solar
                        installer quotation in just 60 mins.
                     </Typography>
                     <PrimaryButton>Go to Dashboard</PrimaryButton>
                  </IntroWrapper>
               </>
            )}

            {page === 'sendOtp' && (
               <ColHeadingWrapper>
                  <Typography
                     component='h2'
                     variant='h4'
                     sx={{ fontWeight: 600 }}
                  >
                     Book a Solar site visit
                  </Typography>

                  <Typography
                     variant='p'
                     fontWeight={500}
                     sx={{ fontSize: '1.5rem' }}
                  >
                     Get top 10 solar quotation in just 60 mins.
                  </Typography>
               </ColHeadingWrapper>
            )}

            <DetailsFieldBox>
               {page === 'sendOtp' && (
                  <>
                     <FieldsWrapper>
                        <StyledSolrufTextField
                           label='Name'
                           type='text'
                           {...register('name', {
                              required: {
                                 value: true,
                                 message: 'Name is required',
                              },
                           })}
                           error={formErrors.name}
                           helperText={
                              formErrors.name && formErrors.name.message
                           }
                        />

                        <StyledSolrufTextField
                           label='Email'
                           type='email'
                           {...register('email', {
                              pattern: {
                                 value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                 message: 'Invalid email address',
                              },
                           })}
                           error={formErrors.email}
                           helperText={
                              formErrors.email ? formErrors.email.message : ''
                           }
                        />
                     </FieldsWrapper>

                     <FieldsWrapper>
                        <StyledSolrufTextField
                           label='Mobile'
                           type='number'
                           {...register('mobile', {
                              required: {
                                 value: true,
                                 message: 'Please input a number to continue',
                              },
                              minLength: {
                                 value: 10,
                                 message:
                                    'Number must be at least 10 characters long',
                              },
                              maxLength: {
                                 value: 10,
                                 message:
                                    'Number must be at most 10 characters long',
                              },
                           })}
                           error={formErrors.mobile}
                           helperText={
                              formErrors.mobile && formErrors.mobile.message
                           }
                        />

                        <StyledSolrufTextField
                           label='Address'
                           type='text'
                           {...register('address', {
                              required: {
                                 value: true,
                                 message: 'Address is Required',
                              },
                           })}
                           error={formErrors.address}
                           helperText={
                              formErrors.address
                                 ? formErrors.address.message
                                 : ''
                           }
                        />
                     </FieldsWrapper>

                     <StyledSolrufTextField
                        label='Pin code'
                        {...register('pincode', {
                           required: {
                              value: true,
                              message: 'Pin Code is required',
                           },
                        })}
                        error={formErrors.pincode ? true : false}
                        helperText={
                           formErrors.pincode && formErrors.pincode.message
                        }
                     />

                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           mt: 2,
                        }}
                     >
                        <StyledCheckbox
                           label='Label'
                           defaultChecked
                           checked={whatsappChecked}
                           onChange={handleWhatsappChecked}
                           sx={{}}
                        />
                        <Typography variant='subtitle1'>
                           Opt in for Solar Products Whatsapp Updates
                        </Typography>
                     </Box>
                  </>
               )}

               {page === 'verifyOtp' && (
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                     }}
                  >
                     <img src={otpAnimation} alt='' style={{ width: '50%' }} />
                     <Typography sx={{ mb: 1, textAlign: 'center' }}>
                        We have send a code verification to your mobile number
                     </Typography>
                     <Typography sx={{ mb: 1, fontSize: '1.5rem' }}>
                        {watch('mobile')}
                     </Typography>
                     <StyledSolrufTextField
                        label='OTP'
                        type='number'
                        sx={{ mt: 1, mb: 3, maxWidth: '20rem' }}
                        {...register('otp', {
                           required: {
                              value: true,
                              message: 'Please input OTP to continue',
                           },
                           minLength: {
                              value: 4,
                              message: 'OTP must be at least 4 characters long',
                           },
                           maxLength: {
                              value: 4,
                              message: 'OTP must be at most 4 characters long',
                           },
                        })}
                        error={formErrors.otp}
                        helperText={
                           formErrors.otp ? formErrors.otp.message : ''
                        }
                     />
                  </Box>
               )}
            </DetailsFieldBox>

            {page !== 'outro' && (
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     gap: 2,
                     mt: 3,
                     mb: 2,
                  }}
               >
                  <LoadingButtonPrimary
                     onClick={() => {}}
                     endIcon={<SendIcon />}
                     loading={isSubmitting || sendingOtp || verifying}
                     type='submit'
                     fullWidth
                     sx={{
                        py: 1.5,
                        borderRadius: '10px',
                        maxWidth: '20rem',
                        fontSize: '1.2rem',
                        width: '200px',
                        '&:hover': {
                           color: '#000000',
                        },
                     }}
                  >
                     {page === 'intro'
                        ? 'Book Now'
                        : page === 'sendOtp'
                        ? 'Send OTP'
                        : 'Verify OTP'}
                  </LoadingButtonPrimary>
                  {/* <PrimaryButton
                     sx={{
                        py: 1.5,
                        borderRadius: '10px',
                        maxWidth: '20rem',
                        fontSize: '1.2rem',
                        textTransform: 'capitalize',
                        width: '200px',
                        '&:hover': {
                           color: '#000000',
                        },
                     }}
                     IconEnd={WhatsAppIcon}
                     component='a'
                     href='https://api.whatsapp.com/send?phone=+918169737450&text=Hello%20there%21%20I%20would%20like%20to%20know%20more%20about%20SOLRUF%20or%20would%20like%20to%20purchase%20a%20solar%20product.%20Can%20you%20help%20us%20with%20this%3F'
                  >
                     WhatsApp
                  </PrimaryButton> */}
               </Box>
            )}
         </Wrapper>
      </>
   );
};

export default OfferForm;
