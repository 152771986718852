import { Box, Container, styled } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogIntroSection from '../../../components/BlogIntroSection/BlogIntroSection';
import PageTitle from '../../../components/PageTitle/PageTitle';

import IntroSection from './IntroSection';
import LanguageSelector from '../../../components/LanguageSelector/LanguageSelector ';
import { useState } from 'react';
import English from '../SolarKits/English';
import Hindi from './Hindi';

const Wrapper = styled(Box)(({ theme }) => ({
   // background: '#D0D7D9',
}));

const FAQPage = () => {
   const [language, setLanguage] = useState('en');

   return (
      <Wrapper>
         <PageTitle title='Blog - Solar Maintenance' />
         <Helmet>
            <meta name='title' content='Solar panel maintenance' />
            <meta property='og:title' content='Solar panel maintenance' />
            <meta
               name='description'
               content='Solar panel maintenance is very easy. We will tell you what you need to do, and how often to keep solar panel working efficiently.'
            />
         </Helmet>
         <BlogIntroSection title='Frequently Asked Questions' />
         <Box sx={{ mx: 'auto', width: 200, mb: 1 }}>
            <LanguageSelector setLanguage={setLanguage} language={language} />
         </Box>
         <Container maxWidth='lg'>
            <IntroSection />

            {language === 'en' ? <English /> : <Hindi />}
         </Container>
      </Wrapper>
   );
};

export default FAQPage;
