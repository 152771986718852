import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { axiAuth } from '../../../utils/axiosInstance';
import {
   Typography,
 
} from '@mui/material';
import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
import { customStyles } from '../../MyPortfolio/MyPortfolio';
import { ServiceChip } from '../../MyPortfolio/updatePortfolio.style';
import Select from 'react-select';
import { toast } from 'react-toastify';

const Enterprise = () => {
   const [nbfcs, setNbfcs] = useState([]);
   const [editMode, setEditMode] = useState(false);
   const [editNbfcId, setEditNbfcId] = useState(null)

   const [manufacturerList, setManufacturerList] = useState([]);
   const [selectedManufacturer, setSelectedManufacturer] = useState([]);
   const [manufacturerError, setManufacturerError] = useState('');


   useEffect(() => {
      if(editMode) return
      axiAuth('api/admin/manufacturer-nbfc')
         .then(({ data }) => {
            setNbfcs(data?.data);
         })
         .catch((err) => {
             
            setManufacturerError(
               'Something went wrong while fetching manufacturer'
            );
         });
   }, [editMode]);

   useEffect(() => {
      axiAuth('api/manufacturers')
         .then(({ data }) => {
            setManufacturerList(
               data?.data.map(({ id, name }) => ({ value: id, label: name }))
            );
         })
         .catch((err) => {
             
         });
   }, []);



   const handleEdit = (nbfcId, nbfc) => {
      setEditNbfcId(nbfcId)
      setEditMode(true);
      setSelectedManufacturer([...selectedManufacturer, ...nbfc.manufacturers.map(manu => ({value: manu.id, label: manu.name}))])
   };

   const handleServices = (serviceSelected) => {
      setManufacturerError('');
      if (
         selectedManufacturer
            .map((service) => service.label)
            .includes(serviceSelected.label)
      ) {
         // setSelectedServices([...selectedService]);
         return;
      } else {
         setSelectedManufacturer((selectedService) => [
            ...selectedService,
            serviceSelected,
         ]);
      }
   };

   const chipDeleteHandler = (deletedService) => {
      setSelectedManufacturer(
         selectedManufacturer.filter(
            (service) => service.label !== deletedService
         )
      );
   };

    

   const saveHandler = async (nbfcId) => {
      try {
         const { status } = await axiAuth.post('api/admin/manufacturer-nbfc', {
            nbfc_id: nbfcId,
            manufacturer_ids: selectedManufacturer.map(
               (manufacturer) => manufacturer.value
            ),
         });

         if (status === 200) {
            toast.success('Manufacturer updated successfully');
            setSelectedManufacturer([])
            setEditMode(false);
         }
      } catch (err) {
         toast.warn(err?.response?.data?.message)
      }

   };

   return (
      <Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
            }}
         >
            {nbfcs.map((item) => (
               <Box
                  key={item.id}
                  sx={{ mb: 4, p: 2, borderRadius: 2, boxShadow: 25 }}
               >
                  <Box
                     sx={{
                        bgcolor: '#F3F3F3',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 2,
                        gap: 2,
                        p: 2,
                        '& img': {
                           width: '60px',
                           height: 'auto',
                        },
                     }}
                  >
                     <img src={item.logo} alt={item.name} />
                     <Box>
                        <Typography variant='h4'>{item.name}</Typography>
                        <Typography variant='body1'>
                           {item.description}
                           Lorem ipsum dolor sit amet consectetur adipisicing
                           elit. Vero, praesentium! Omnis soluta, voluptatum
                           architecto laudantium aperiam perspiciatis, velit
                           impedit hic eum labore a fugiat minima!
                        </Typography>
                     </Box>
                  </Box>
                  <Typography variant='h5' sx={{ mt: 2 }}>
                     Manufacturers
                  </Typography>
                  {item.manufacturers.length > 0 && (
                     <Box sx={{ mt: 2 }}>
                        <Box
                           sx={{
                              display: 'flex',
                              gap: 2,
                              alignItems: 'center',
                           }}
                        >
                           {item.manufacturers.map((manufacturer) => (
                              <Box
                                 sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    borderBottom: '1px solid #f3f3f3',
                                    '& img': {
                                       width: '50px',
                                       height: 'auto',
                                    },
                                 }}
                                 key={manufacturer.id}
                              >
                                 <img src={manufacturer.logo} alt='logo' />
                                 <Typography variant='h6'>
                                    {manufacturer.name}
                                 </Typography>
                              </Box>
                           ))}
                        </Box>
                     </Box>
                  )}

                  {editMode && item.id === editNbfcId && (
                     <Box sx={{ mt: 2 }}>
                        <Select
                           // value={selectedService}
                           onChange={handleServices}
                           options={manufacturerList}
                           placeholder='Search Manufacturer'
                           className='react-select-container'
                           styles={customStyles}
                        />

                        {manufacturerError ? (
                           <Typography
                              variant='body2'
                              color='error'
                              sx={{ mb: 1 }}
                           >
                              {manufacturerError}
                           </Typography>
                        ) : (
                           <Typography
                              variant='body2'
                              color='error'
                              sx={{ mb: 1 }}
                           >
                              {`  `}
                           </Typography>
                        )}

                        <Box sx={{ my: 2 }}>
                           {selectedManufacturer.map((item, index) => {
                              return (
                                 <ServiceChip
                                    label={item.label}
                                    key={index}
                                    onDelete={() =>
                                       chipDeleteHandler(item.label)
                                    }
                                 />
                              );
                           })}
                        </Box>
                     </Box>
                  )}
                  <PrimaryButton
                     variant='contained'
                     onClick={() =>
                        editMode ? saveHandler(item.id) : handleEdit(item.id, item)
                     }
                     sx={{ mt: 2 }}
                  >
                     {editMode ? 'Save' : 'Edit'}
                  </PrimaryButton>
               </Box>
            ))}
         </Box>
      </Container>
   );
};

export default Enterprise;
