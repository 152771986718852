import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const SectionHeader = ({ title }) => {
   return (
      <Box sx={{ mb: 2 }}>
         <Typography variant='h6' fontWeight={600}>
            {title}
         </Typography>

         <Divider
            sx={{
               position: 'relative', // Set position relative for absolute positioning of ::after
               '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  border: '2px solid #000000', // Yellow border
                  width: '5%',
                  boxSizing: 'border-box', // Include border in the dimensions
                //   width: 'calc(100% + 8px)', // 100% + 2 times border width
                  height: 'calc(100% + 4px)', // 100% + 2 times border width
                  margin: '-2px', // Negative margin to make the ring effect
               },
            }}
         />
      </Box>
   );
};

export default SectionHeader;
