import React from "react";
import {
  Container,
  Box,
  styled,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";

const TitleHeaderBox = styled(Box)(() => ({
  padding: "2rem",
  display: "flex",
  alignItems: "center",
  backgroundImage: `
      linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url("https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/2149352235+1.png")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  minHeight: 350,
}));

const Disclaimer = () => {
  return (
    <Box>
      <TitleHeaderBox>
        <Typography
          component="h4"
          fontWeight={600}
          sx={{ color: "#ffffff", fontSize: "3.5rem" }}
        >
          Disclaimer
        </Typography>
      </TitleHeaderBox>
      <Container sx={{ my: 5 }} maxWidth="lg">
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            "@media (max-width: 600px)": {
              justifyContent: "flex-start",
              mb: 4,
            },
          }}
        >
          <Grid item>
            <PrimaryButton
              href="https://solrufv1.s3.ap-south-1.amazonaws.com/sites-static-files-new/Disclaimer.pdf"
              download
            >
              Download
            </PrimaryButton>
          </Grid>
        </Grid>
        <Typography variant="body1" component="div" gutterBottom>
          <p
            style={{
              color: "#000000",
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            Disclaimer
          </p>

          <p>
            In these Terms of Use, references to "you" or "your" or "User" shall
            mean the user subscribing to or using the products and services and
            "we", "us", "our" or "Solruf" shall mean the Company.
          </p>
          <p>
            Any participation in this site will constitute acceptance of this
            agreement. If you do not agree to abide by the below, please do not
            use this site. These terms of use (Terms of Use) constitute a legal
            and binding contract between you and the company (SOLRUF INDIA
            PRIVATE LIMITED) with regard to the use of (a) Company's website
            (www.solruf.com); (b) Company's mobile application; and (c) any
            service that may be provided by the Company using the Website and/or
            the mobile application ((a), (b) and (c) above collectively),
          </p>
          <p>
            The company allows, amongst others, manufacturers, distributors, and
            dealers (Suppliers) to list their products on the website and mobile
            application and permits end-users to purchase those products.
          </p>
          <p>
            These Terms of Use are applicable to all the services provided by
            the company. In addition to these Terms of Use and depending on the
            services opted for by the user, the user may be required to read and
            accept or may have read and accepted, additional terms and
            conditions of service (TOS) for each such service and/or may be
            required to execute or may have executed, additional agreements with
            the company (Additional Agreements).
          </p>
        </Typography>
      </Container>
    </Box>
  );
};

export default Disclaimer;
