import { Editor } from "@tinymce/tinymce-react";
import React from "react";

const RichEditor = ({ value, handleChange, placeholder }) => {
  return (
    <div style={{ marginBottom: "10px", marginTop: "10px" }}>
      <Editor
        // apiKey="hugai0lsihziyi558myc52ql4uje0k71agnw31g2pspa69ap"
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
        //   onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={handleChange}
        init={{
          height: 200,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          placeholder: placeholder,
        }}
      />
    </div>
  );
};

export default RichEditor;
