import { IconButton, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";

import { ErrorText, QuantityBox } from "./itemlist.style";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../../redux/slices/newCartSlice/newCartSlice";
import { calculateItemPrice } from "../../pages/Checkout/checkout.utils";
import { updateQuantityInCart } from "./itemList.api";
import { axiAuth } from "../../utils/axiosInstance";

const ItemWrapper = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  background: "#ffffff",
  padding: theme.spacing(1.5),
  borderRadius: "8px",
  marginBottom: "1rem",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  position: "relative",
}));

const ItemDetailBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
}));

const QuantityAndPriceBox = styled("div")(({ theme }) => ({}));

const ItemList = ({ item }) => {
  const [quantity, setQuantity] = useState(item?.quantity);
  const [quantityError, setQuantityError] = useState("");
  const dispatch = useDispatch();

  const handleQuantity = async (isIncrease) => {
    setQuantityError("");

    // Calculate the new quantity based on the current quantity state
    const newQuantity = isIncrease ? quantity + 1 : Math.max(quantity - 1, 1);
    console.log(newQuantity);

    try {
      // Make sure to use the new quantity when calling the API
      const response = await updateQuantityInCart(item.product_id, newQuantity);

      if (response.status === 200) {
        // Update the quantity state only if the API call is successful
        dispatch(
          isIncrease
            ? increaseQuantity(item.product_id)
            : decreaseQuantity(item.product_id)
        );
        // Update the local quantity state
        setQuantity(newQuantity);
      } else {
        console.error("Error updating quantity:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const deleteHandler = async () => {
    try {
      const clearCartResponse = await axiAuth.delete(
        `/api/shoppingcarts/${item.product_id}`
      );

      if (clearCartResponse.status === 200) {
        dispatch(removeFromCart(item.product_id));
      } else {
        console.error(
          "Error clearing shopping cart:",
          clearCartResponse.statusText
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <ItemWrapper>
      <ItemDetailBox>
        <Box
          sx={{
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            maxHeight: "200px",
            width: "30%",
            cursor: "pointer",
          }}
        >
          <img
            src={`${
              item.product_thumbnail
                ? item.product_thumbnail
                : "https://i.ibb.co/7vQHPjD/Rectangle-35.png"
            }`}
            alt="item"
            style={{
              maxWidth: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box sx={{ ml: 5, width: "70%" }}>
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ mb: 1, cursor: "pointer", mt: 2 }}
          >
            {item.product_name}
          </Typography>
          {/* <Typography
                  variant='body2'
                  fontWeight={600}
                  sx={{ mb: 1, cursor: 'pointer', color: '#8E8E8E', mt: -1.5 }}
               >
                  Vendor Name
               </Typography> */}
          <QuantityAndPriceBox>
            <div>
              <Typography variant="h6" fontWeight={600} gutterBottom>
                Rs. {calculateItemPrice(item)}
              </Typography>
            </div>
            <Box
              sx={{
                mt: 1.5,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <QuantityBox>
                  <MinusIcon
                    style={{
                      width: "30px",
                      boxSizing: "content-box",
                      padding: "0 .7rem",
                    }}
                    onClick={() => handleQuantity(false)}
                  />

                  <input type="text" value={item?.quantity} />

                  <PlusIcon
                    style={{
                      width: "30px",
                      boxSizing: "content-box",
                      padding: "0 .7rem",
                    }}
                    onClick={() => handleQuantity(true)}
                  />
                </QuantityBox>
                {quantityError && <ErrorText>{quantityError}</ErrorText>}
              </Box>
            </Box>
          </QuantityAndPriceBox>
        </Box>
      </ItemDetailBox>

      <IconButton
        sx={{
          mt: 1,
          cursor: "pointer",
          position: "absolute",
          top: "0",
          right: "5px",
        }}
        color="error"
        onClick={deleteHandler}
      >
        <DeleteIcon />
      </IconButton>
    </ItemWrapper>
  );
};

export default ItemList;
