import { Box, Container } from '@mui/material';
import React from 'react';
import { useState } from 'react';

const SolarPumpPayment = () => {
   const [loaded, setLoaded] = useState(false);

   const handleLoad = () => {
      setLoaded(true);
   };
   return (
      <Container>
         <Box sx={{ my: 5 }}>
            {!loaded && <div>Loading the portal...</div>}
            <iframe
               title='Payment Form'
               src='https://rzp.io/l/MpMkmOkEVu'
               width='100%'
               height='1150px'
               onLoad={handleLoad}
               style={{ display: loaded ? 'block' : 'none' }}
            />
         </Box>
      </Container>
   );
};

export default SolarPumpPayment;
