import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import BuildIcon from '@mui/icons-material/Build';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const UnderConstructionPage = () => {
   return (
      <>
         <Box
            sx={{
               width: '100%',
               maxWidth: 400,
               mx: 'auto',
               mt: 10,
               '& img': {
                  width: '100%',
               },
            }}
         >
            <img src='https://i.ibb.co/hRCXC48/og-image.jpg' alt='logo' />
         </Box>
         <Box mb={10}>
            <Typography variant='h5' gutterBottom textAlign={'center'}>
               Will keep you posted on the next solar session
            </Typography>
         </Box>
      </>
   );
};

export default UnderConstructionPage;
