import {
   Box,
   Checkbox,
   Container,
   FormControlLabel,
   Radio,
   Typography,
   useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ProductCardForMobileDrawer from '../../../components/ProductCardForMobile/ProductCardForMobileDrawer';
import HorizontalProductCardForEnquiryDrawer from '../../../components/EnquiryDetailsForUser/HorizontalProductCardForEnquiryDrawer';
import {
   DownloadChip,
   Flex,
   TermLinkButton,
} from '../../../components/CustomerDetailsDrawer/customerDetailsDrawer.style';
import FeatureDetail from '../../../components/FeatureDetail/FeatureDetail';

import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CustomAccordionForDrawer from '../../../components/Custom/CustomAccordionForDrawer/CustomAccordionForDrawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BidDetails from './BidDetails';

const EnquiryDetailsDrawer = ({ enquiry }) => {
   const { enquiries: enquiryDetails } = enquiry || {};
   const matchSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
   console.log({ enquiryDetails });

   const [showBidDetails, setShowBidDetails] = useState(false);
   const [selectedBid, setSelectedBid] = useState(null);

   const onBidSelect = (e, vendor) => {
      setSelectedBid(vendor);
   };

   console.log(selectedBid);

   return (
      <Container>
         <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <Typography
               variant='h5'
               sx={{ mr: 2, color: '#000', fontWeight: 500 }}
            >
               Enquiry Id - # {enquiryDetails?.enquiry_id}
            </Typography>

            {/* <Box bgcolor="#E21F30" sx={{ px: 1, borderRadius: "5px" }}>
                  <Typography variant="h6" sx={{ color: "#fff" }}>
                    Enquiry Cancelled{" "}
                  </Typography>
                </Box> */}

            <Box bgcolor='#0097D3' sx={{ px: 1, borderRadius: '5px' }}>
               <Typography variant='h6' sx={{ color: '#fff' }}>
                  Enquiry generated
               </Typography>
            </Box>
         </Box>
         <Box sx={{ width: '100%' }}>
            {matchSm ? (
               <ProductCardForMobileDrawer
                  product={enquiryDetails?.product?.other}
                  productImage={enquiryDetails?.product?.other.defaultImage}
                  productName={enquiryDetails?.product?.other.productName}
                  attributes={enquiryDetails?.product?.other.attributes}
                  showBook={false}
               />
            ) : (
               <HorizontalProductCardForEnquiryDrawer
                  product={enquiryDetails?.product?.other}
                  productImage={enquiryDetails?.product?.other.defaultImage}
                  productName={enquiryDetails?.product?.other.productName}
                  attributes={enquiryDetails?.product?.other.attributes}
                  sx={{ borderRadius: '25px', mx: 'auto' }}
                  type='enquiry'
                  productId={enquiryDetails?.product?.other?.productId}
               />
            )}
         </Box>

         <CustomAccordionForDrawer
            title='Order Details'
            noPadding={true}
            paddingOff={true}
            sx={{
               my: 2,
               boxShadow: 0,
               '& .MuiAccordionSummary-root': {
                  borderBottom: '1px solid #D0D7D9',
               },
            }}
         >
            <Box sx={{ p: 2 }}>
               <Flex
                  sx={{
                     justifyContent: 'flex-start',
                     alignItems: 'flex-start',
                     mt: 1,
                  }}
               >
                  <Box sx={{ mr: 2, minWidth: '50%' }}>
                     <FeatureDetail
                        icon={<ProductionQuantityLimitsIcon />}
                        title='Quantity'
                        value={enquiryDetails?.quantity}
                     />
                     <FeatureDetail
                        icon={<LocationCityIcon />}
                        title='City/District'
                        value={enquiryDetails?.others?.user_address?.city}
                     />
                     <FeatureDetail
                        icon={<LocationOnIcon />}
                        title='Address'
                        value={enquiryDetails?.others?.user_address?.address}
                     />
                  </Box>
                  <Box>
                     <FeatureDetail
                        icon={<DateRangeIcon />}
                        title='Date'
                        value={enquiryDetails?.delivery_date}
                     />

                     <FeatureDetail
                        icon={<FlagIcon />}
                        title='Pin Code / Zip Code'
                        value={enquiryDetails?.others?.user_address?.pin_code}
                     />
                  </Box>
               </Flex>
               <Flex
                  sx={{
                     flexDirection: 'column',
                     '& .MuiFormControlLabel-root': {
                        margin: 0,
                     },
                     mt: 0,
                  }}
               >
                  <FormControlLabel
                     sx={{
                        '& .MuiTypography-root': {
                           fontWeight: 'bold',
                        },
                     }}
                     control={
                        <Checkbox
                           checked={enquiryDetails?.others?.accept_other_brands}
                        />
                     }
                     label='Accept Products from other Brand?'
                  />
               </Flex>
            </Box>
         </CustomAccordionForDrawer>

         <CustomAccordionForDrawer
            title='Order Documents'
            titleStyle={{ fontSize: '1rem' }}
            sx={{
               my: 2,
               boxShadow: 0,
               '& .MuiAccordionSummary-root': {
                  borderBottom: '1px solid #D0D7D9',
               },
            }}
         >
            <Flex sx={{ mt: 1.5, flexWrap: 'wrap', rowGap: 1 }}>
               {enquiryDetails?.documents?.length === 0 ? (
                  <Box>
                     <Typography>No Document Found</Typography>
                  </Box>
               ) : (
                  enquiryDetails?.documents?.map((doc) => (
                     <>
                        <DownloadChip
                           label={doc?.name}
                           onClick={() => {
                              window.open(doc.url, '_blank');
                           }}
                           //   onDelete={(e) => handleDocDeleteClick(doc.id)}
                        />
                     </>
                  ))
               )}
            </Flex>
         </CustomAccordionForDrawer>

         <CustomAccordionForDrawer
            title='Vendor Bids'
            paddingOff={true}
            sx={{
               my: 2,
               boxShadow: 0,
               '& .MuiAccordionSummary-root': {
                  borderBottom: '1px solid #D0D7D9',
               },
            }}
         >
            {showBidDetails ? (
               <BidDetails
                  vendorData={selectedBid}
                  enquiryId={enquiryDetails.id}
                  quantity={enquiryDetails.quantity}
                  onClose={() => setShowBidDetails(false)}
               />
            ) : (
               <TableContainer component={Box} sx={{ height: '600px' }}>
                  <Table
                     sx={{
                        width: '100%',
                        minWidth: 600,
                     }}
                     aria-label='simple table'
                  >
                     <TableHead>
                        <TableRow>
                           <TableCell
                              align='left'
                              sx={{
                                 fontWeight: 600,
                                 fontSize: '1rem',
                                 pl: 8,
                              }}
                           >
                              Name
                           </TableCell>
                           <TableCell
                              align='center'
                              sx={{
                                 fontWeight: 600,
                                 fontSize: '1rem',
                              }}
                           >
                              Bid
                           </TableCell>
                           <TableCell
                              align='center'
                              sx={{
                                 fontWeight: 600,
                                 fontSize: '1rem',
                              }}
                           >
                              Portfolio & Supply terms
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {enquiryDetails?.vendors?.map((row, index) => (
                           <TableRow
                              key={index}
                              style={
                                 row?.id === selectedBid?.id
                                    ? {
                                         background: 'rgb(255 230 169)',
                                      }
                                    : {
                                         background: 'transparent',
                                      }
                              }
                              onClick={() => {
                                 if (selectedBid?.id === row.id) {
                                    setSelectedBid(null);
                                 } else {
                                    setSelectedBid(row);
                                 }
                              }}
                           >
                              <TableCell
                                 component='th'
                                 scope='row'
                                 align='center'
                                 sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Radio
                                    value={index}
                                    checked={row?.id === selectedBid?.id}
                                    onChange={(event) =>
                                       onBidSelect(event, row)
                                    }
                                    name='radio-buttons'
                                    inputProps={{
                                       'aria-label': 'A',
                                    }}
                                    sx={{
                                       color: '#666F73 !important',
                                    }}
                                 />

                                 <Typography
                                    sx={{
                                       fontWeight: 600,
                                    }}
                                 >
                                    {row.portfolio.name}
                                 </Typography>
                              </TableCell>
                              <TableCell
                                 component='th'
                                 scope='row'
                                 align='center'
                              >
                                 {row.bid.price}
                              </TableCell>
                              <TableCell align='center'>
                                 <TermLinkButton
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       if (selectedBid?.id !== row.id) {
                                          setSelectedBid(row);
                                       }

                                       setShowBidDetails(true);
                                    }}
                                    //   disabled={
                                    //     profileData?.business
                                    //       ?.company_name
                                    //       ? false
                                    //       : true
                                    //   }
                                 >
                                    Link
                                 </TermLinkButton>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            )}
         </CustomAccordionForDrawer>
      </Container>
   );
};

export default EnquiryDetailsDrawer;
