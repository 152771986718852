import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Autoplay } from "swiper";
import Project1 from "../../../../assets/projects/PortfolioProject1.png";
import Project2 from "../../../../assets/projects/PortfolioProject2.png";
import Project3 from "../../../../assets/projects/PortfolioProject3.png";
import Project4 from "../../../../assets/projects/PortfolioProject4.png";
import Project5 from "../../../../assets/projects/PortfolioProject5.png";
import Project6 from "../../../../assets/projects/PortfolioProject6.png";
import Project7 from "../../../../assets/projects/PortfolioProject7.png";
import Project8 from "../../../../assets/projects/PortfolioProject8.png";

import { Box, Typography, Avatar, Divider } from "@mui/material";
import "./ProjectsSlider.css";

const projectsData = [
  {
    image_url: Project1,
  },
  {
    image_url: Project2,
  },

  {
    image_url: Project3,
  },
  {
    image_url: Project4,
  },
  {
    image_url: Project5,
  },
  {
    image_url: Project6,
  },
  {
    image_url: Project7,
  },
  {
    image_url: Project8,
  },
];

const ProjectsSlider = () => {
  return (
    <Box sx={{ mt: 14, mb: 12 }}>
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: 16,
              md: 30,
            },
          }}
        >
          Projects
        </Typography>
        <Divider
          sx={{
            position: "relative",
          }}
        />
      </Box>
      <Swiper
        loop={true}
        slidesPerView={3}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
        modules={[Autoplay]}
        className="review_slider"
      >
        {projectsData.map((review, i) => (
          <SwiperSlide>
            <Box
              sx={{
                display: "flex",
                maxWidth: 600,
                justifyContent: "center",
                alignItems: "center",
                py: 4,
                px: 1,
              }}
            >
              <Box
                sx={{
                  maxWidth: "100%",
                  width: 600,
                  borderRadius: 2,
                  // boxShadow:
                  //   "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <img
                  src={review.image_url}
                  alt="project"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ProjectsSlider;
