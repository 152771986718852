import React, { useEffect } from "react";
import Chip from "@mui/material/Chip";
import { Stack, styled, useMediaQuery, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setProductData } from "../../redux/slices/viewProductSlice";
import ReviewSlider from "./CategorySlider/CategorySlider";
import { SwiperSlide } from "swiper/react/swiper-react";
import { useNavigate, useLocation } from "react-router-dom";

const StyledChip = styled(Chip)(({ theme, active, current }) => ({
  "& .MuiChip-label": {
    color: "#000000",
    fontWeight: "normal",
    fontSize: 11,
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  border: "1px solid #dddddd",
  background: active === current ? theme.palette.primary.main : "transparent",
  "&:hover": {
    background: `${theme.palette.primary.main} !important`,
    cursor: "pointer",
  },
}));

export const toSlug = (name) => {
  return name.toLowerCase().replace(/\s+/g, "-");
};

const fromSlug = (slug) => {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ProcurementCategories = ({ categories, setValue }) => {
  const matchSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const selectedCategoryId = useSelector(
    (state) => state.viewProduct.productCategoryData
  );

  useEffect(() => {
    console.log("useEffect ran");
    if (category) {
      const categorySlugToName = fromSlug(category);
      const selectedCategory = categories.find(
        (cat) => cat.name === categorySlugToName
      );
      console.log(categorySlugToName);
      dispatch(
        setProductData({
          productCategoryData: selectedCategory?.category_id || 1,
        })
      );
      setValue("subCategory", "");
    } else {
      dispatch(setProductData({ productCategoryData: 1 }));
      setValue("subCategory", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleClick = (categoryId, categoryName) => {
    dispatch(setProductData({ productCategoryData: categoryId }));
    setValue("subCategory", "");
    navigate(`?category=${categoryName}`);
  };

  return (
    <>
      {matchSm ? (
        <ReviewSlider>
          {categories.map(({ category_id, name }) => (
            <SwiperSlide key={category_id}>
              <Box sx={{ my: 3 }}>
                <StyledChip
                  active={selectedCategoryId}
                  current={category_id}
                  label={name}
                  variant="outlined"
                  onClick={() => handleClick(category_id, toSlug(name))}
                />
              </Box>
            </SwiperSlide>
          ))}
        </ReviewSlider>
      ) : (
        // <Stack direction='row' gap={1} flexWrap='wrap'>
        //    {categories.map(({ category_id, name }) => (
        //       <StyledChip
        //          key={category_id}
        //          active={selectedCategoryId}
        //          current={category_id}
        //          label={name}
        //          variant='outlined'
        //          onClick={() => handleClick(category_id, toSlug(name))}
        //       />
        //    ))}
        // </Stack>

        <ReviewSlider>
          {categories.map(({ category_id, name }) => (
            <SwiperSlide key={category_id}>
              <Box sx={{ my: 3 }}>
                <StyledChip
                  active={selectedCategoryId}
                  current={category_id}
                  label={name}
                  variant="outlined"
                  onClick={() => handleClick(category_id, toSlug(name))}
                />
              </Box>
            </SwiperSlide>
          ))}
        </ReviewSlider>
      )}
    </>
  );
};

export default ProcurementCategories;
