import { Container, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import saveFormDataToGoogleSheet from '../../utils/saveFormDataToSheet';
import useDiscountCalculator from '../../hooks/useDiscountCalculator';
import SolrufTextField from '../SolrufTextField/SolrufTextField';
import { Flex } from '../CustomerDetailsDrawer/customerDetailsDrawer.style';
import QuantityController from '../QuantityController/QuantityController';
import Coupon from '../Coupon/Coupon';
import CouponAppliedCard from '../Coupon/CouponAppliedCard';
import PrimaryButton from '../Custom/PrimaryButton/PrimaryButton';
import { RadioGroup } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';

import { loginToShipRocket, makeCodApiCall } from './checkout.api';
import { useDispatch, useSelector } from 'react-redux';
import {
   openLoginModal,
   setName,
   setPhone,
} from '../../redux/slices/loginModalSlice';
import PincodeCityState from '../PincodeCityState/PincodeCityState';
import { axiAuth } from '../../utils/axiosInstance';
import OrderConfirmationPage from '../../pages/OrderConfirmationPage';

const FormWrapper = styled(Box)(({ theme }) => ({
   width: '100%',
   maxWidth: '600px',
   padding: '1.5rem',
   margin: '0 auto',
   display: 'flex',
   justifyContent: 'center',
   flexDirection: 'column',
   gap: '1rem',
   background: '#ffffff',
   borderRadius: '1rem',
}));

const CheckoutModalContent = ({ productDetails }) => {
   const { user } = useSelector((state) => state.user);

   const dispatch = useDispatch();

   const [quantity, setQuantity] = useState(1);
   const [quantityError, setQuantityError] = useState('');
   const [paymentMethod, setPaymentMethod] = useState('razorpay');
   const [paymentSuccess, setPaymentSuccess] = useState(false);
   const [paymentID, setPaymentId] = useState('');
   const [paymentLoading, setPaymentLoading] = useState(false);

   const formMethods = useForm({
      defaultValues: {
         pincode: '',
         city: '',
      },
   });

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isSubmitting },
   } = formMethods;

   const pincode = watch('pincode');

   const [minFreightCharge, setMinFreightCharge] = useState(0);

   const averagePrice = productDetails?.attributes?.find(
      (item) => item.name === 'Average Price'
   );
   const price = averagePrice?.attribute_values[0]?.value || 'n/a';

   const [couponInfo, setCouponInfo] = useState({
      less: 0,
      title: '',
      expiresAt: '',
      valid: false,
   });


   const submitHandler = async (formData) => {
      setQuantityError('');
      if (quantity < 1) {
         setQuantityError('Quantity cannot be less than 1');
         return;
      }

      if (paymentMethod === '') return;
      if (!price || price === 'n/a') return;

      dispatch(setPhone(formData.phone));
      dispatch(setName(formData.name));

      if (!user) {
         dispatch(openLoginModal());
         return;
      }

      if (paymentMethod === 'razorpay') {
         try {
            await handlePayment(formData);
         } catch (error) {
            console.log(error);
         }
      } else if (paymentMethod === 'cod') {
         setLoading(true);
         try {
            const { order_id } = await makeCodApiCall(
               price,
               formData,
               couponInfo,
               minFreightCharge,
               productDetails,
               grandTotal
            );

            await saveToGoogleSheet(
               price,
               couponInfo,
               formData,
               paymentMethod,
               order_id
            );

            setPaymentId(order_id);
            setPaymentSuccess(true);
            setPaymentLoading(false);
            setLoading(false);
         } catch (error) {
            console.log(error);
            setPaymentSuccess(false);
            setPaymentLoading(false);
            setLoading(false);
            toast.warn('Something went wrong while creating order');
         }
      }
   };

   async function saveToGoogleSheet(
      price,
      couponInfo,
      formData,
      paymentMethod,
      order_id
   ) {
      const orderInfo = {
         quantity,
         product_id: productDetails?.product_id,
         product_name: productDetails?.product_name,
         payment_method: paymentMethod,
         ...formData,
         order_id,
      };
      try {
         orderInfo.pickup_pincode = '444606';
         orderInfo.product_cost = price;
         orderInfo.delivery_cost = minFreightCharge;
         orderInfo.date_of_order = new Date().toLocaleString();
         orderInfo.coupon = couponInfo.title;
         orderInfo.grand_total = grandTotal;
         orderInfo.price = price;

         const { status } = await saveFormDataToGoogleSheet(
            orderInfo,
            'https://script.google.com/macros/s/AKfycby1EetiX_mP0gI88rnVrDBc2SVmHbym5bt5mgCToZpL31pf7MHNFQaMAGSNE_ZoCE8o/exec'
         );

         if (status === 200) {
            toast.success('Order Placed Successfully');
         }
      } catch (error) {
         toast('Something went wrong while creating order');
         //  console.error(error);
      }
   }

   useEffect(() => {
      if (pincode?.length === 6) {
         loginToShipRocket().then((token) => {
            const config = {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            };

            axios
               .get(
                  `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?cod=1&pickup_postcode=444606&delivery_postcode=${pincode}&weight=${
                     productDetails.weight * quantity
                  }&length=${productDetails.length * quantity}&breadth=${
                     productDetails.breadth
                  }&height=${productDetails.height}`,
                  config
               )
               .then((data) => {
                  console.log(data.data);
                  setMinFreightCharge(
                     Math.min(
                        ...data.data?.data.available_courier_companies?.map(
                           (c) => c.rate
                        )
                     )
                  );
               })
               .catch((err) => {
                  toast.error(
                     err?.response?.data?.message || 'Something Went Wrong'
                  );
               });
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pincode, quantity]);

   const { discount, grandTotal } = useDiscountCalculator(
      price,
      quantity,
      couponInfo
   );

   const couponRemoveHandler = () => {
      setCouponInfo({ less: 0, title: '', expiresAt: '', valid: false });
   };

   const [loading, setLoading] = useState(false);

   const handlePayment = async (formData) => {
      setLoading(true);
      setPaymentLoading(true);

      try {
         const {
            data: { order },
         } = await axiAuth.post(
            // `https://api-dev-v2.solruf.com/api/v2/payment/create`,
            `http://localhost:5000/api/v2/payment/create`,
            {
               // amount: Math.round(grandTotal + minFreightCharge),
               amount: 1,
               orderDetails: {
                  product_id: productDetails.product_id,
                  product_name: productDetails.product_name,
                  price,
                  couponInfo,
                  minFreightCharge,
                  formData,
                  quantity,
               },
            }
         );

         const options = {
            // key: 'rzp_test_5n3r2EkkJI8wZe',
            key: 'rzp_live_Aqc829jh4Ocj5u',
            amount: order.amount,
            currency: 'INR',
            name: 'Solruf',
            description: `Payment for ${productDetails.product_name}`,
            image: 'https://i.ibb.co/rws9hV3/Whats-App-Image-2023-05-27-at-12-56-54.jpg',
            order_id: order.id,
            handler: async function (response) {
               setPaymentLoading(true);

               try {
                  const {
                     // razorpay_order_id,
                     razorpay_payment_id,
                     razorpay_signature,
                  } = response;
                  const { status, data } = await axiAuth.post(
                     // 'https://api-dev-v2.solruf.com/api/v2/payment/verification',
                     'http://localhost:5000/api/v2/payment/verification',
                     {
                        razorpay_payment_id,
                        razorpay_order_id: order.id,
                        razorpay_signature,
                        amount: Math.round(grandTotal + minFreightCharge),
                        product_name: productDetails.product_name,
                        formData,
                     }
                  );

                  if (status === 200) {
                     setPaymentId(data.razorpay_payment_id);
                     setPaymentSuccess(true);
                     setPaymentLoading(false);
                  }
               } catch (error) {
                  setPaymentSuccess(false);
                  setPaymentLoading(false);
               }
            },
            prefill: {
               name: formData?.name,
               email: 'test@mail.com',
               contact: formData?.phone,
            },
            notes: {
               address: 'Mumbai',
            },
            theme: {
               color: '#ffd05b',
            },
            config: {
               display: {
                  blocks: {
                     utib: {
                        //name for Axis block
                        name: 'Pay using Axis Bank',
                        instruments: [
                           {
                              method: 'card',
                              issuers: ['UTIB'],
                           },
                           {
                              method: 'netbanking',
                              banks: ['UTIB'],
                           },
                        ],
                     },
                     other: {
                        //  name for other block
                        name: 'Other Payment modes',
                        instruments: [
                           {
                              method: 'card',
                              issuers: ['ICIC'],
                           },
                           {
                              method: 'netbanking',
                           },
                           {
                              method: 'upi',
                              apps: ['google_pay', 'phonepe'],
                           },
                        ],
                     },
                  },
                  hide: [
                     {
                        method: '',
                     },
                  ],
               },
            },
         };
         const razor = new window.Razorpay(options);
         razor.open();
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setPaymentLoading(false);
         setPaymentSuccess(false);

         console.log(error);
      }
   };

   if (paymentSuccess && paymentID) {
      return <OrderConfirmationPage paymentId={paymentID} />;
   }

   return (
      <Box>
         <Box>
            <Container>
               <Box>
                  <FormWrapper
                     component='form'
                     onSubmit={handleSubmit(submitHandler)}
                  >
                     <Typography
                        sx={{ textAlign: 'center' }}
                        variant='h4'
                        component='h1'
                     >
                        Enter Order Details
                     </Typography>

                     <SolrufTextField
                        size='small'
                        sx={{ mr: 2 }}
                        type='text'
                        label='Name'
                        {...register('name', {
                           required: {
                              value: true,
                              message: 'Name is required',
                           },
                        })}
                        error={errors.name}
                        helperText={errors.name && errors.name.message}
                     />

                     <SolrufTextField
                        size='small'
                        type='number'
                        label='Mobile Number'
                        {...register('phone', {
                           required: {
                              value: true,
                              message: 'Please input a number to continue',
                           },
                        })}
                        error={errors.phone}
                        helperText={errors.phone && errors.phone.message}
                     />

                     <PincodeCityState
                        formMethods={formMethods}
                        customerDetails={{}}
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: 1,
                        }}
                     />

                     <SolrufTextField
                        size='small'
                        label='Address'
                        {...register('address', {
                           required: {
                              value: true,
                              message: 'Address is required',
                           },
                        })}
                        error={errors.address}
                        helperText={errors.address && errors.address.message}
                     />

                     <Flex>
                        <QuantityController
                           quantity={quantity}
                           setQuantity={setQuantity}
                           quantityError={quantityError}
                           setQuantityError={setQuantityError}
                        />
                     </Flex>

                     {!couponInfo?.valid && (
                        <Coupon
                           sx={{ padding: 0 }}
                           couponInfo={couponInfo}
                           setCouponInfo={setCouponInfo}
                        />
                     )}

                     {couponInfo.valid && (
                        <CouponAppliedCard
                           sx={{ padding: 0 }}
                           couponInfo={couponInfo}
                           couponRemoveHandler={couponRemoveHandler}
                        />
                     )}

                     <Box
                        sx={{
                           width: '100%',
                           display: 'flex',
                           justifyContent: 'flex-end',
                        }}
                     >
                        <Box
                           component='table'
                           sx={{
                              width: '100%',
                              fontSize: '1rem',
                              '& tr': {
                                 width: '100%',
                              },
                              '& th': {
                                 width: '50%',
                                 minWidth: '10rem',
                                 textAlign: 'left',
                              },
                              '& td': {
                                 width: '50%',
                                 textAlign: 'right',
                              },
                           }}
                        >
                           <tr>
                              <th>Freight Rate:</th>
                              <td>₹ {minFreightCharge}</td>
                           </tr>
                           <tr>
                              <th>Discount:</th>
                              <td>₹ -{discount}</td>
                           </tr>
                           <tr>
                              <th>Total:</th>
                              <td>₹ {grandTotal + minFreightCharge}</td>
                           </tr>
                        </Box>
                     </Box>

                     <RadioGroup
                        aria-label='payment-method'
                        name='payment-method'
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        value={paymentMethod}
                     >
                        <FormControlLabel
                           value='razorpay'
                           control={<Radio />}
                           label='Razorpay'
                        />
                        <FormControlLabel
                           value='cod'
                           control={<Radio />}
                           label='Cash on Delivery (COD)'
                        />
                     </RadioGroup>

                     <PrimaryButton
                        type='submit'
                        disabled={isSubmitting || loading || paymentLoading}
                     >
                        {paymentLoading ? 'Verifying Payment..' : 'Submit'}
                     </PrimaryButton>
                  </FormWrapper>
                  {/* form end */}
               </Box>
            </Container>
         </Box>
      </Box>
   );
};

export default CheckoutModalContent;
