import { Box, Chip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { axiAuth } from '../../../utils/axiosInstance';
import useAuth from '../../../hooks/useAuth';

const ProfileDetails = () => {
   const [businessProfileInfo, setBusinessProfileInfo] = useState();
   const [loading, setLoading] = useState(true);
   const [categories, setCategories] = useState([]);
   // const [categoryLoading, setCategoryLoading] = useState(true);
   const { role } = useAuth();

   useEffect(() => {
      setLoading(true);
      axiAuth
         .get('/api/profile')
         .then(({ data, status }) => {
             
            if (status === 200) {
               setBusinessProfileInfo(data?.data?.business);
               setLoading(false);
            }
         })
         .catch((err) => {
            setLoading(false);
            toast.error('Something went wrong');
         });
   }, []);

   useEffect(() => {
      // setCategoryLoading(true);

      const url = role === 'NBFC' ? 'nbfc-services-profile' : 'manufacturer-categories-profile'

      axiAuth
         .get(`/api/${url}`)
         .then(({ data, status }) => {
             
            if (status === 200) {
               setCategories(data?.data);
               // setCategoryLoading(false);
            }
         })
         .catch((err) => {
            // setCategoryLoading(false);
            toast.error('Something went wrong');
         });
   }, [role]);

    

   return (
      <Box>
         {!loading && (
            <Box
               sx={{
                  bgcolor: '#ffffff',
                  borderRadius: 2,
                  my: 2,
               }}
            >
               <Box
                  sx={{
                     '& img': {
                        width: 200,
                        height: 'auto',
                        display: 'block',
                        mx: 'auto',
                     },
                  }}
               >
                  <img src={businessProfileInfo?.photo} alt='logo' />
               </Box>
               <Typography
                  variant='h6'
                  sx={{
                     textAlign: 'center',
                  }}
               >
                  {businessProfileInfo?.company_name}
               </Typography>

               <Typography variant='h6' sx={{ fontWeight: 600 }}>
                  {role === 'Manufacturer' ? 'Categories' : 'Services'}
               </Typography>
               <Box
                  sx={{
                     justifyContent: ['flex-start', 'flex-start', 'flex-end'],
                     mb: [2, 0, 0],
                     mt: 2,
                  }}
               >
                  {categories?.map((service, i) => (
                     <Chip
                        key={i}
                        label={service.name}
                        sx={{
                           ml: 1,
                           color: '#0173BB',
                           borderRadius: 4,
                           bgcolor: '#C9E7F7',
                           fontWeight: 600,
                           fontSize: '1.1rem',
                           mb: 1,
                        }}
                     />
                  ))}
               </Box>
            </Box>
         )}
      </Box>
   );
};

export default ProfileDetails;
