import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { theme } from "./theme/theme";
import { store } from "./redux/store";

import "animate.css";
import "./App.css";

import Toast from "./components/Toast";
import Layout from "./components/Layout/Layout";

import { removeUser } from "./redux/slices/userSlice";
import { axiAuth } from "./utils/axiosInstance";

import ProductBooking from "./pages/ProductBooking/ProductBooking";

import Profile from "./pages/UserDashboard/Profile/Profile";
import useAuth from "./hooks/useAuth";

// swiper css imports
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import "swiper/modules/scrollbar/scrollbar.scss";
import "swiper/modules/scrollbar/scrollbar.scss";

import { Helmet } from "react-helmet";
import {
  saveStates,
  setNetworkSnackbar2Open,
  setNetworkSnackbarOpen,
  setSnackbarInitiated,
} from "./redux/slices/utils/utils.slice";
import LoginModal from "./components/LoginModal/LoginModal";
import AuthGuard from "./components/AuthGuard/AuthGuard";
import { useSelector } from "react-redux";
import VendorHome from "./pages/home/vendorHome";
import { removeCart } from "./redux/slices/cart/cartSlice";

// import ErrorPage from "./pages/404";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./components/Fallback/Fallback";
import NetworkFallback from "./components/Fallback/NetworkFallback";
import { useNetwork } from "./hooks/useNetwork";
import SnackbarAlert from "./components/Custom/SnackbarAlert/SnackbarAlert";
import SnackbarAlert2 from "./components/Custom/SnackbarAlert/SnackbarAlert2";
// import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import OrderStatus from "./pages/OrderStatus/OrderStatus";
// import PurchaseDetailsPage from './pages/OrderStatus/PurchaseDetailsPage';
import OrderStatusFromProfile from "./pages/OrderStatus/OrderStatusFromProfile";

import Loader from "./components/Loader/Loader";

import AddProduct from "./pages/AdminPages/AddProduct/AddProduct";
import ProductList from "./pages/AdminPages/ProductList/ProductList";
import UpdateProduct from "./pages/AdminPages/AddProduct/UpdateProduct";
import Parallax404 from "./pages/Parallax404/Parallax404";
import BackDor from "./pages/BackDor/BackDor";
import Redirect from "./components/Redirect/Redirect";
import Home from "./pages/home/home";
import RegisterBackDoor from "./pages/RegisterBackdoor/RegisterBackdoor";
import OurSocialMediaBlogs from "./pages/OurSocialMediaBlogs/OurSocialMediaBlogs";
import ScrollToTop from "./utils/scrollToTop";
import CalculationPdfTemplate from "./pages/home/CalculationPdfTemplate/CalculationPdfTemplate";
import Login from "./pages/Login/Login";
import EnterpriseLogin from "./pages/Login/EnterpriseLogin";
// import OtpTimeoutBox from './pages/OtpTimeOutBox/OtpTimeOutBox';
import EnterpriseOutlet from "./privateOutlets/EnterpriseOutlet";
import EnterpriseDashboard from "./pages/NBFCDashboard/EnterpriseDashboard";
// import ProfileDetails from './pages/NBFCDashboard/ProfileDetails/ManufacturerList';
import Manufacturers from "./pages/NBFCDashboard/Manufacturers/Manufacturers";
import Enterprise from "./pages/AdminPages/Enterprise/Enterprise";
import NbfcManagement from "./pages/AdminPages/NbfcManagement/NbfcManagement";
import SolarPumpForm from "./pages/SolarPumpForm/SolarPumpForm";
import Nbfcs from "./pages/NBFCDashboard/Nbfcs/Nbfcs";
import ManufacturerManagement from "./pages/AdminPages/ManufacturerManagement/ManufacturerManagement";
import SolarPaymentPage from "./pages/SolarPaymentPage/SolarPaymentPage";
import SolarPumpPayment from "./pages/SolarPumpPayment/SolarPumpPayment";
import SolarLeadForm from "./pages/SolarLeadForm/SolarLeadForm";
import WhatsappEnquiryPage from "./pages/WhatsappEnquiryPage/WhatsappEnquiryPage";
import EnquiryBank from "./pages/AdminPages/EnquiryBank/EnquiryBank";
import SolarCalculatorPage from "./pages/SolarCalculatorPage/SolarCalculatorPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import EnquiryListForAdmin from "./pages/AdminPages/EnquiryBank/EnquiryBank";
import ManageAttribute from "./pages/AdminPages/ManageAttribute/ManageAttribute";
import KusumSolarPumps from "./pages/Blogs/KusumSolarPumps/KusumSolarPumps";
// import SSRPagesLinks from './pages/SSRPagesLinks/SSRPagesLinks';
import PurchasePage from "./pages/PurchasePage/PurchasePage";
// import PurchaseProductPage2 from './pages/PurchaseProductPage/PurchaseProductPage2';
import TestPaymentProduct from "./pages/TestPaymentProduct";
import OrderConfirmationPage from "./pages/OrderConfirmationPage";
import SolarKits from "./pages/Blogs/SolarKits/SolarKits";
import FAQPage from "./pages/Blogs/FAQPage/FAQPage";
import CheckoutProductPage from "./pages/CheckoutProductPage/CheckoutProductPage";
import Quote from "./pages/Quote/Quote";
// import Agreement from './pages/Dashboard/Agreement/Agreement';
import Thankyou from "./pages/Thankyou/Thankyou";
import RoadmapBlog from "./pages/NewBlogs/RoadmapBlog/RoadmapBlog.js";
import NewHomePage from "./pages/home/NewHomePage/NewHomePage.js";
import NewBlogsHome from "./pages/NewBlogs/NewBlogsHome.js";
import EnergizingHomes from "./pages/NewBlogs/EnergizingHomes/EnergizingHomes.js";
import HarvestBlog from "./pages/NewBlogs/Harvesting/Harvesting.js";
import Illuminating from "./pages/NewBlogs/Illuminating/Illuminating.js";
import Powering from "./pages/NewBlogs/Powering/Powering.js";
import Harmony from "./pages/NewBlogs/Harmony/Harmony.js";
import ChoosingSolar from "./pages/NewBlogs/ChoosingSolar/ChoosingSolar.js";
import Inverters from "./pages/NewBlogs/Inverters/Inverters.js";
import FutureSolar from "./pages/NewBlogs/FutureSolar/FutureSolar.js";
import UnderConstructionPage from "./pages/UnderConstructionPage/UnderConstructionPage.js";
import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy.js";
import Disclaimer from "./pages/Disclaimer/Disclaimer.js";
import Partner from "./pages/Partner/Partner.js";
import EPC from "./pages/Partner/EPC.js";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse.js";
import TypesOfProjects from "./pages/NewBlogs/TypesOfSolarProjects/TypesOfProjects";
import TagManager from "react-gtm-module";
import EcofyBlog from "./pages/NewBlogs/Ecofy/Ecofy.js";
import SolarLeadForm2 from "./pages/SolarLeadForm/SolarLeadForm2.js";
import WareeProposalService from "./components/ServiceComponents/WareeProposalService.js";
import { WareeQuotation } from "./components/ServiceComponents/WaareeQuotation.js";

// import Catalogue from './pages/Dashboard/Catalogue/Catalogue';
// import TrialSignup from './pages/TrialSignup/TrialSignup';

const TrialSignup = lazy(() => import("./pages/TrialSignup/TrialSignup"));

const CustomerLeads = lazy(() =>
  import("./pages/Dashboard/CustomerLeads/CustomerLeads")
);
const SalesEnquiries = lazy(() =>
  import("./pages/Dashboard/SalesEnquiries/SalesEnquiries")
);
const NewSalesEnquiries = lazy(() =>
  import("./pages/Dashboard/SalesEnquiries/NewSalesEnquiries.js")
);
const Purchases = lazy(() => import("./pages/Dashboard/Purchase/Purchases"));
const Consultation = lazy(() =>
  import("./pages/UserDashboard/Consultation/Consultation")
);

// import CustomerLeads from './pages/Dashboard/CustomerLeads/CustomerLeads';
// import SalesEnquiries from './pages/Dashboard/SalesEnquiries/SalesEnquiries';
// import Purchases from './pages/Dashboard/Purchase/Purchases';
// import Consultation from './pages/UserDashboard/Consultation/Consultation';

// const AddProduct = lazy(() =>
//   import("./pages/AdminPages/AddProduct/AddProduct")
// );
// const ProductList = lazy(() =>
//   import("./pages/AdminPages/ProductList/ProductList")
// );

const SolarMaintenance = lazy(() =>
  import("./pages/Blogs/SolarMaintenance/SolarMaintenance")
);
const PvSystems = lazy(() => import("./pages/Blogs/PvSystems/PvSystems"));
const SolarSteps = lazy(() =>
  import("./pages/Dashboard/SolarSteps/SolarSteps")
);
const SolarComponents = lazy(() =>
  import("./pages/Blogs/SolarComponents/SolarComponents")
);
const SolarInstallationProcess = lazy(() =>
  import("./pages/Blogs/SolarInstallationProcess/SolarInstallationProcess")
);
const SolarDesign = lazy(() => import("./pages/Blogs/SolarDesign/SolarDesign"));
const SolarProducts = lazy(() =>
  import("./pages/Blogs/SolarProducts/SolarProducts")
);

const KwattCourses = lazy(() =>
  import("./pages/Blogs/KwattCourses/KwattCourses")
);

const Procurement = lazy(() => import("./pages/Procurement/Procurement"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs.js"));
const Checkout = lazy(() => import("./pages/Checkout/Checkout"));
const SearchProduct = lazy(() => import("./pages/SearchProduct/SearchProduct"));
const PurchaseProductPage = lazy(() =>
  import("./pages/PurchaseProductPage/PurchaseProductPage")
);
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const UserPortfolioProfile = lazy(() =>
  import("./portfolio/UserPortfolioProfile")
);
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const MyDashboard = lazy(() => import("./pages/MyDashboard/MyDashboard"));
const ServicesPage = lazy(() => import("./pages/ServicesPage/ServicesPage"));
const UserDashboard = lazy(() => import("./pages/UserDashboard/UserDashboard"));

const EditProjectForMobile = lazy(() =>
  import("./pages/EditProjectForMobile/EditProjectForMobile")
);
const AddProjectForMobile = lazy(() =>
  import("./pages/AddProjectForMobile/AddProjectForMobile")
);

const DashboardPortfolio = lazy(() =>
  import("./pages/Dashboard/DashboardPortfolio/DashboardPortfolio")
);

// const Blogs = lazy(() => import('./pages/Blogs/Blogs'));

const NetMetering = lazy(() => import("./pages/Blogs/NetMetering/NetMetering"));

const UserOutlet = lazy(() => import("./privateOutlets/UserOutlet"));
const VendorOutlet = lazy(() => import("./privateOutlets/VendorOutlet"));
const AdminOutlet = lazy(() => import("./privateOutlets/AdminOutlet"));

const Maharashtra = lazy(() =>
  import("./pages/Blogs/NetMetering/Maharashtra/Maharashtra")
);
const Dilhi = lazy(() => import("./pages/Blogs/NetMetering/Dilhi/Dilhi"));
const Gujrat = lazy(() => import("./pages/Blogs/NetMetering/Gujrat/Gujrat"));
const Haryana = lazy(() => import("./pages/Blogs/NetMetering/Haryana/Haryana"));
const Karnataka = lazy(() =>
  import("./pages/Blogs/NetMetering/Karnataka/Karnataka")
);
const Punjab = lazy(() => import("./pages/Blogs/NetMetering/Punjab/Punjab"));
const WestBengal = lazy(() =>
  import("./pages/Blogs/NetMetering/WestBengal/WestBengal")
);

const EnquiryPage = lazy(() => import("./pages/EnquiryPage/EnquiryPage"));

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/thankyou") {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: {
            path: location.pathname,
            title: "Thank You Page",
            location: window.location.href,
          },
        },
      });
    }
  }, [location]);
};

function App() {
  usePageTracking();
  const { user } = useAuth();

  if (user) {
  }

  const description = `
         Solruf is a B2B solar marketplace where solar installers can instantly
          create & share their business portfolios, get quality leads and
          procure products directly from verified manufacturers/distributors. We
          aim to enable access and build solar infrastructure for the transition
          to clean energy for the next billion consumers.`;

  const { statesOfIndia, snackbarInitiated } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (statesOfIndia.length === 0) {
      axiAuth
        .get("api/states")
        .then(({ data }) => {
          store.dispatch(
            saveStates(
              data.states.map((state) => {
                return state.charAt(0) + state.slice(1).toLowerCase();
              })
            )
          );
        })
        .catch((err) => {});
    }
  }, [statesOfIndia.length]);

  const errorHandler = (error, errorInfo) => {};

  const { networkStatus } = useNetwork();

  if (!networkStatus.online) {
    dispatch(setNetworkSnackbarOpen(true));
    dispatch(setSnackbarInitiated(true));
  } else {
    if (snackbarInitiated) {
      dispatch(setNetworkSnackbarOpen(false));
      dispatch(setNetworkSnackbar2Open(true));
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <Router> */}
      <ScrollToTop />
      <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
        <div className="App">
          <Helmet>
            {/* <title>Solruf | Solar market place</title>
              <meta name="description" content={description} /> */}
            <meta name="theme-color" content="#ffd05b" />
            <body class="light" />
          </Helmet>
          <Routes>
            <Route
              path="/products"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <Procurement />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-pump-form"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarPumpForm />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-design-payment"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarPaymentPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-pump-payment"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarPumpPayment />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-enquiry-form"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarLeadForm />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-enquiry-of-services"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarLeadForm2 />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-whatsapp-enquiry"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <WhatsappEnquiryPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-calculator"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <SolarCalculatorPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <ContactUsPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/solar-session"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <UnderConstructionPage />
                  </Suspense>
                </Layout>
              }
            />
            {/* <Route
                        path='/links-solruf'
                        element={
                           <Layout fixedFooter>
                              <Suspense fallback={<Loader />}>
                                 <SSRPagesLinks />
                              </Suspense>
                           </Layout>
                        }
                     /> */}

            <Route
              path="/"
              element={
                <Layout fixedFooter homeHeader>
                  {/* <Home /> */}
                  <NewHomePage />
                </Layout>
                // <UnderConstructionPage />
              }
            />
            <Route
              path="/vendors"
              element={
                <Layout fixedFooter>
                  <VendorHome />
                </Layout>
              }
            />

            <Route
              path="/register"
              element={
                <Layout fixedFooter contactUs>
                  <Home register />
                </Layout>
              }
            />

            <Route
              path="/about-us"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <AboutUs />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/partner-affiliate"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <Partner />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/partner-epc"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <EPC />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/checkout"
              element={
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <Checkout />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/portfolio-checkout"
              element={
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <Checkout />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/searchProduct"
              element={
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <SearchProduct />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/purchase-product/:vendorSlug/:vendorId/:productSlug/:productId"
              element={
                <Layout dynamicHeader noFooter>
                  <Suspense fallback={<Loader />}>
                    <PurchaseProductPage />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <PrivacyPolicy />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/return-policy"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <ReturnPolicy />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/disclaimer"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <Disclaimer />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/terms-of-use"
              element={
                <Layout fixedFooter>
                  <Suspense fallback={<Loader />}>
                    <TermsOfUse />
                  </Suspense>
                </Layout>
              }
            />

            {/* //? ================== All user protected routes will go here ================== */}
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <UserOutlet />
                </Suspense>
              }
            >
              <Route
                path="user-dashboard/"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <UserDashboard />
                    </Suspense>
                  </Layout>
                }
              >
                <Route
                  path="purchase-enquiries"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Purchases />
                    </Suspense>
                  }
                />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
            {/* //? ================== All vendor routes will go here ================== */}
            <Route
              path="/vendor/"
              element={
                <Suspense fallback={<Loader />}>
                  <VendorOutlet />
                </Suspense>
              }
            >
              <Route
                path="dashboard/"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <Dashboard />
                    </Suspense>
                  </Layout>
                }
              >
                <Route
                  path="portfolio"
                  element={
                    <Suspense fallback={<Loader />}>
                      <MyDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="service-tools"
                  element={
                    <Suspense fallback={<Loader />}>
                      <ServicesPage />
                    </Suspense>
                  }
                />
                <Route
                  // path='portfolio'
                  index
                  element={
                    <Suspense fallback={<Loader />}>
                      <DashboardPortfolio noPadding={true} />
                    </Suspense>
                  }
                />
                <Route
                  path="customerLeads"
                  element={
                    <Suspense fallback={<Loader />}>
                      <CustomerLeads />
                    </Suspense>
                  }
                />
                <Route
                  path="sale"
                  element={
                    // <Suspense fallback={<Loader />}>
                    // <SalesEnquiries />
                    <NewSalesEnquiries />
                    // </Suspense>
                  }
                />
                <Route
                  path="purchase"
                  element={
                    // <Suspense fallback={<Loader />}>
                    <Purchases />
                    // </Suspense>
                  }
                />
                <Route
                  path="consultation"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Consultation />
                    </Suspense>
                  }
                />
                <Route path="profile" element={<Profile />} />
                {/* <Route
                              path='agreement'
                              element={
                                 <Suspense fallback={<Loader />}>
                                    <Agreement />
                                 </Suspense>
                              }
                           /> */}

                {/* <Route
                              path='catalogue'
                              element={
                                 <Suspense fallback={<Loader />}>
                                    <Catalogue />
                                 </Suspense>
                              }
                           /> */}
              </Route>
            </Route>

            {/* //? ================== All admin routes will go here ================== */}
            <Route
              path="/admin/"
              element={
                <Suspense fallback={<Loader />}>
                  <AdminOutlet />
                </Suspense>
              }
            >
              <Route
                path="create/new"
                element={
                  <Layout>
                    <Suspense fallback={<Loader />}>
                      <AddProduct />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="update/product/:product_id"
                element={
                  <Layout>
                    <Suspense fallback={<Loader />}>
                      <UpdateProduct />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="products"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <ProductList />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="nbfc-management"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <NbfcManagement />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="manufacturer-management"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <ManufacturerManagement />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="enquiry-bank"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <EnquiryListForAdmin />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="manage-attribute"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <ManageAttribute />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="enterprise"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <Enterprise />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="enquiry-bank"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <EnquiryBank />
                    </Suspense>
                  </Layout>
                }
              />
            </Route>

            {/*  //? ================== all enterprise routes will go here ================== */}
            <Route
              path="/enterprise"
              element={
                <Suspense fallback={<Loader />}>
                  <EnterpriseOutlet />
                </Suspense>
              }
            >
              <Route
                path="dashboard"
                element={
                  <Layout noFooter={true}>
                    <Suspense fallback={<Loader />}>
                      <EnterpriseDashboard />
                    </Suspense>
                  </Layout>
                }
              >
                <Route
                  path="manufacturers"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Manufacturers />
                    </Suspense>
                  }
                />
                <Route
                  path="nbfcs"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Nbfcs />
                    </Suspense>
                  }
                />
                <Route path="profile" element={<Profile enterprise={true} />} />
              </Route>
            </Route>

            <Route
              exact
              path="/editProjectMobile/:projectId"
              element={
                <>
                  <Layout>
                    <Container maxWidth="xl">
                      <Suspense fallback={<Loader />}>
                        <EditProjectForMobile />
                      </Suspense>
                    </Container>
                  </Layout>
                </>
              }
            />
            <Route
              exact
              path="/m.addProject"
              element={
                <>
                  <Layout>
                    <Container maxWidth="xl">
                      <Suspense fallback={<Loader />}>
                        <AddProjectForMobile />
                      </Suspense>
                    </Container>
                  </Layout>
                </>
              }
            />
            <Route
              path="/portfolio/:name"
              element={
                <Layout profileHeader noFooter>
                  <Suspense fallback={<Loader />}>
                    <UserPortfolioProfile />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/trial-signup"
              element={
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <TrialSignup />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/quote"
              element={
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <Quote />
                  </Suspense>
                </Layout>
              }
            />

            {/*  ============================== blogs ================================= */}
            <Route path="/blogs">
              <Route
                index
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <NewBlogsHome />
                    </Suspense>
                  </Layout>
                }
              />
              {/* <Route
                path="pm-kusum-solar-pumps/maharashtra"
                element={
                  <Layout header={false}>
                    <KusumSolarPumps />
                  </Layout>
                }
              />
              <Route
                path="roadmap"
                element={
                  <Layout header={false}>
                    <RoadmapBlog />
                  </Layout>
                }
              />
              <Route
                path="energize"
                element={
                  <Layout header={false}>
                    <EnergizingHomes />
                  </Layout>
                }
              />
              <Route
                path="harvest"
                element={
                  <Layout header={false}>
                    <HarvestBlog />
                  </Layout>
                }
              />
              <Route
                path="making-solar-energy-accessible-with-solruf-and-ecofy"
                element={
                  <Layout header={false}>
                    <EcofyBlog />
                  </Layout>
                }
              />
              <Route
                path="illuminating"
                element={
                  <Layout header={false}>
                    <Illuminating />
                  </Layout>
                }
              />
              <Route
                path="powering"
                element={
                  <Layout header={false}>
                    <Powering />
                  </Layout>
                }
              />
              <Route
                path="harmony"
                element={
                  <Layout header={false}>
                    <Harmony />
                  </Layout>
                }
              />
              <Route
                path="types-of-solar-projects-financing"
                element={
                  <Layout header={false}>
                    <TypesOfProjects />
                  </Layout>
                }
              />
              <Route
                path="choosingSolar"
                element={
                  <Layout header={false}>
                    <ChoosingSolar />
                  </Layout>
                }
              />
              <Route
                path="inverters"
                element={
                  <Layout header={false}>
                    <Inverters />
                  </Layout>
                }
              />
              <Route
                path="futureSolar"
                element={
                  <Layout header={false}>
                    <FutureSolar />
                  </Layout>
                }
              />
              <Route
                path="our-blogs"
                element={
                  <Layout header={false}>
                    <OurSocialMediaBlogs />
                  </Layout>
                }
              />
              <Route
                path="maintenance"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarMaintenance />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solar-kits"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarKits />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="faq"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <FAQPage />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="typesOfPvSystems"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <PvSystems />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solarSteps"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarSteps />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solarInstallationProcess"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarInstallationProcess />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solarComponents"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarComponents />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solarDesign"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarDesign />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="solarProducts"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <SolarProducts />
                    </Suspense>
                  </Layout>
                }
              />

              <Route
                path="kWatt-solar-courses"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <KwattCourses />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="netMetering"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <NetMetering />
                    </Suspense>
                  </Layout>
                }
              /> */}

              {/* net metering policy, state wise */}

              {/* <Route
                path="netMetering/maharashtra"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Maharashtra />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="netMetering/dilhi"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Dilhi />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="netMetering/gujrat"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Gujrat />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="netMetering/haryana"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Haryana />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="net-metering/karnataka"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Karnataka />
                    </Suspense>
                  </Layout>
                }
              />
              <Route
                path="net-metering/punjab"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <Punjab />
                    </Suspense>
                  </Layout>
                }
              />

              <Route
                path="net-metering/west-bengal"
                element={
                  <Layout header={false}>
                    <Suspense fallback={<Loader />}>
                      <WestBengal />
                    </Suspense>
                  </Layout>
                }
              /> */}
            </Route>

            {/*  product booking  */}
            <Route path="/product-booking" element={<ProductBooking />} />
            {/* <Route
              path="/products/:productId/:productSlug"
              element={
                <Layout noFooter>
                  <Suspense fallback={<Loader />}>
                    <EnquiryPage />
                  </Suspense>
                </Layout>
              }
            /> */}
            <Route
              path="/products/:productId"
              element={
                <Layout noFooter>
                  <Suspense fallback={<Loader />}>
                    <EnquiryPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/checkout-product/:productId/:productSlug"
              element={
                <Layout noFooter>
                  <Suspense fallback={<Loader />}>
                    <CheckoutProductPage />
                  </Suspense>
                </Layout>
              }
            />
            <Route
              path="/purchase/:productId/:productSlug"
              element={
                <Layout noFooter>
                  <Suspense fallback={<Loader />}>
                    <PurchasePage />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/purchase/:productId"
              element={
                <Layout noFooter>
                  <Suspense fallback={<Loader />}>
                    <PurchasePage />
                  </Suspense>
                </Layout>
              }
            />

            <Route
              path="/order-status"
              element={
                <Layout noFooter={true}>
                  <OrderStatus />
                </Layout>
              }
            />

            <Route
              path="/test-payment"
              element={
                <Layout noFooter={true}>
                  <TestPaymentProduct />
                </Layout>
              }
            />
            <Route
              path="/paymentsuccess/:paymentId"
              element={
                <Layout noFooter={true}>
                  <OrderConfirmationPage />
                </Layout>
              }
            />
            <Route
              path="/aYJqr4o4gX9WAISrvTlOX4hZO9+MS8uQo7tZbL5DfpQ="
              element={
                <Layout>
                  <BackDor />
                </Layout>
              }
            />
            <Route
              path="/aYJqr4o4gX9WAISrvTlOX4hZO9+MS8uQo7tZbL5DfpQ=register"
              element={
                <Layout>
                  <RegisterBackDoor />
                </Layout>
              }
            />

            <Route
              path="/portfolio/order-status"
              element={
                <Layout profileHeader noFooter={true}>
                  <OrderStatusFromProfile />
                </Layout>
              }
            />
            <Route
              path="/login-register"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/enterprise-login"
              element={
                <Layout>
                  <EnterpriseLogin />
                </Layout>
              }
            />

            <Route path="/fallback" element={<Fallback />} />

            <Route path="/net" element={<NetworkFallback />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route
              path="/waree-proposal"
              element={
                <Layout>
                  <WareeProposalService />
                </Layout>
              }
            />
            <Route
              path="/waaree-quotation"
              element={
                <Layout>
                  <WareeQuotation />
                </Layout>
              }
            />

            {/* <Route path="/pdp" element={<PurchaseDetailsPage />} /> */}
            <Route path="/purchase" element={<Redirect />} />
            <Route path="/sale" element={<Redirect />} />
            <Route path="/calc-pdf" element={<CalculationPdfTemplate />} />

            <Route path="/*" element={<Parallax404 />} />
          </Routes>
          <Toast />
          <LoginModal>
            <AuthGuard />
          </LoginModal>
        </div>
        <SnackbarAlert />
        <SnackbarAlert2 />
      </ErrorBoundary>
      {/* </Router> */}
      {/* <OtpTimeoutBox /> */}
    </ThemeProvider>
  );
}

export default App;

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/
const UNAUTHORIZED = 401;
const { dispatch } = store; // direct access to redux store.

axiAuth.interceptors.request.use((config) => {
  const token = store.getState().user.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error?.response;
      if (status === UNAUTHORIZED) {
        dispatch(removeUser());
        dispatch(removeCart());
      }
      return Promise.reject(error);
    }
  }
);
