import { createSlice } from '@reduxjs/toolkit';

const initialState = { isOpen: false, from: '', phone: '', name: '' };

export const loginModalSlice = createSlice({
   name: 'loginModal',
   initialState,
   reducers: {
      openLoginModal: (state, action) => {
         state.isOpen = true;
      },
      closeLoginModal: (state, action) => {
         state.isOpen = false;
      },
      setLoginRedirect: (state, action) => {
         state.from = action.payload;
      },
      removeLoginRedirect: (state, action) => {
         state.from = '';
      },
      setPhone: (state, action) => {
         state.phone = action.payload;
      },
      setName: (state, action) => {
         state.name = action.payload;
      }
   },
});

// Action creators are generated for each case reducer function
export const {
   openLoginModal,
   closeLoginModal,
   setLoginRedirect,
   removeLoginRedirect,
   setPhone,
   setName
} = loginModalSlice.actions;

export default loginModalSlice.reducer;
