import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import ProductDetailList from "../../components/ProductDetailList/ProductDetailList";
// import ProductSlider from '../../components/ProductSlider/ProductSlider';
// import noImage from '../../assets/no-image.jpeg';

import {
  ImageFeatureListWrapper,
  ProductCardWrapper,
  ProductFeatureListWrapper,
} from "./procurementProductCard.style";
import { truncateString } from "../../utils/utils";

// const noImages = [noImage, noImage, noImage, noImage, noImage, noImage];

const ProcurementProductCard = ({ product, actionType }) => {
  const matchSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    product_name = "New Product",
    product_slug = "na",
    product_id,
  } = product || {};
  const navigate = useNavigate();

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (product.attributes && product.attributes.length > 0) {
      setAttributes(
        product.attributes
          .filter(
            (attribute) =>
              attribute?.attribute_values[0]?.views?.procurement_card
                ?.visibility
          )
          .sort((a, b) => {
            const levelA =
              a?.attribute_values[0]?.views?.procurement_card?.level || 0;
            const levelB =
              b?.attribute_values[0]?.views?.procurement_card?.level || 0;
            return levelA - levelB;
          })
      );
    }
  }, [product.attributes]);

  return (
    <ProductCardWrapper>
      <Typography
        sx={{
          fontWeight: "600",
          color: "#000000",
          textAlign: "center",
          fontSize: "0.9rem",
          display: ["block", "none"],
        }}
        className="title"
      >
        {truncateString(product_name, 45)}
      </Typography>

      <Box
        sx={{
          borderBottom: "1px dashed #ddd",
          width: "100%",
          my: 1,
          display: ["block", "none"],
        }}
      ></Box>

      <ImageFeatureListWrapper>
        <Box
          sx={{
            width: "100%",
            height: [150, 250],
            overflow: "hidden",
            flex: 1,
          }}
        >
          <img
            src={product.product_thumbnail}
            alt="thumbnail"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "4px 4px 0 0",
            }}
          />
        </Box>

        <ProductFeatureListWrapper
          // onClick={() =>
          //   product.sales_type === "purchase"
          //     ? navigate(`/purchase/${product_id}/${product_slug}`)
          //     : navigate(`/products/${product_id}/${product_slug}`)
          // }
          onClick={() =>
            product.sales_type === "purchase"
              ? navigate(`/purchase/${product_id}`)
              : navigate(`/products/${product_id}`)
          }
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "#000000",
              textAlign: "center",
              fontSize: "0.9rem",
            }}
            className="title"
          >
            {truncateString(product_name, 45)}
          </Typography>

          <ProductDetailList
            description={product.main_category?.name}
            list="Category"
          />
          <ProductDetailList
            description={product.sub_category?.sub_category_name}
            list="Sub Category"
          />
          <ProductDetailList
            list="Brand"
            description={product?.product_brand_name}
          />
          {!matchSm &&
            attributes.length > 0 &&
            attributes
              .slice(0, 2)
              .map((attribute) => (
                <ProductDetailList
                  key={attribute.id}
                  list={attribute.name}
                  description={`${attribute.attribute_values[0].value} ${attribute.attribute_values[0].value_unit}`}
                />
              ))}
        </ProductFeatureListWrapper>
      </ImageFeatureListWrapper>

      {actionType === "enquiry" && (
        <PrimaryButton
          fullWidth
          // onClick={() =>
          //   product.sales_type === "purchase"
          //     ? navigate(`/purchase/${product_id}/${product_slug}`)
          //     : navigate(`/products/${product_id}/${product_slug}`)
          // }
          onClick={() =>
            product.sales_type === "purchase"
              ? navigate(`/purchase/${product_id}`)
              : navigate(`/products/${product_id}`)
          }
          sx={{
            py: [0.7, 0.8, 0.9, 1],
            marginTop: "auto", // Push the button to the bottom
            fontSize: [11, 13],
          }}
        >
          {product.sales_type === "purchase" ? "Purchase" : "Enquiry"}
        </PrimaryButton>
      )}
    </ProductCardWrapper>
  );
};

export default ProcurementProductCard;
