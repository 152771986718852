import React from 'react';
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Autoplay } from 'swiper';
import { CircularBox } from './Categories';
import { setProductData } from '../../../../redux/slices/viewProductSlice';

// import banner2 from '../../../../assets/banners/Banner 2.svg';
// import banner4 from '../../../../assets/banners/Banner 4.svg';
// import banner6 from '../../../../assets/banners/Banner 6.svg';
// import banner3 from '../../../../assets/banners/Banner 3.svg';

const CategoriesCarousel = ({ productCategories, navigate, dispatch }) => {
   return (
      <Box>
         <Swiper
            pagination={{
               dynamicBullets: true,
               clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className='homeBannerSlider'
            autoplay={{
               delay: 3000,
               disableOnInteraction: false,
               pauseOnMouseEnter: true,
            }}
            loop={true}
         >
            

            {productCategories.map((cat) => (
               <SwiperSlide>

                  <Box
                     onClick={() => {
                        dispatch(
                           setProductData({
                              productCategoryData: cat.CategoryId,
                              productSubCategoryData: cat.SubCategoryId,
                           })
                        );
                        navigate(`/products`);
                     }}
                     sx={{
                        '&:hover': {
                           transform: 'scale(1.05)',
                           transition: 'ease-in 0.3s',
                           cursor: 'pointer',
                        },
                        pb: 7
                     }}
                  >
                     <CircularBox>
                        <img src={cat.image} alt='' />
                     </CircularBox>
                     <Typography
                        variant='body'
                        fontWeight={600}
                        mt={4}
                        sx={{ textAlign: 'center', display: 'block' }}
                     >
                        {cat.name}
                     </Typography>
                  </Box>
               </SwiperSlide>
            ))}
         </Swiper>
      </Box>
   );
};

export default CategoriesCarousel;
