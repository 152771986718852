// export const unitRateInfo = {
//     andhrapradesh: [
//        {
//           minUnit: 1,
//           maxUnit: 30,
//           totalUnit: 30,
//           rate: 1.9,
//           maxCost: 57,
//        },
//        {
//           minUnit: 31,
//           maxUnit: 75,
//           totalUnit: 45,
//           rate: 3,
//           maxCost: 135,
//        },
//        {
//           minUnit: 76,
//           maxUnit: 125,
//           totalUnit: 50,
//           rate: 4.5,
//           maxCost: 225,
//        },
//        {
//           minUnit: 126,
//           maxUnit: 225,
//           totalUnit: 100,
//           rate: 6,
//           maxCost: 600,
//        },
//        {
//           minUnit: 226,
//           maxUnit: 400,
//           totalUnit: 175,
//           rate: 8.75,
//           maxCost: 1531.25,
//        },
//  {
//     minUnit: 401,
//     rate: 9.75,
//  },
//     ],
//     andamannicobar: [
//        {
//           minUnit: 1,
//           maxUnit: 100,
//           totalUnit: 100,
//           rate: 2.25,
//           maxCost: 225,
//        },
//        {
//           minUnit: 101,
//           maxUnit: 200,
//           totalUnit: 100,
//           rate: 5,
//           maxCost: 1000,
//        },
//        {
//           minUnit: 201,
//           maxUnit: 500,
//           totalUnit: 300,
//           rate: 7.2,
//           maxCost: 3600,
//        },
//        {
//           minUnit: 501,
//           rate: 7.5,
//        },
//     ],
//     assam: [
//        {
//           minUnit: 1,
//           maxUnit: 120,
//           totalUnit: 120,
//           rate: 5.3,
//           maxCost: 636, // 120 * 5.3
//        },
//        {
//           minUnit: 121,
//           maxUnit: 240,
//           totalUnit: 120,
//           rate: 6.6,
//           maxCost: 1584, // 240 * 6.6
//        },
//        {
//           minUnit: 241,
//           rate: 7.6,
//        },
//     ],
//     bihar: [
//        {
//           minUnit: 1,
//           maxUnit: 100,
//           totalUnit: 100,
//           rate: 6.1,
//           maxCost: 610, // 100 * 6.1
//        },
//        {
//           minUnit: 101,
//           maxUnit: 200,
//           totalUnit: 100,
//           rate: 6.95,
//           maxCost: 1390, // 200 * 6.95
//        },
//        {
//           minUnit: 201,
//           rate: 8.05,
//        },
//     ],
//     chandigarh: [
//        {
//           minUnit: 1,
//           maxUnit: 150,
//           totalUnit: 150,
//           rate: 2.5,
//           maxCost: 375, // 150 * 2.5
//        },
//        {
//           minUnit: 151,
//           maxUnit: 400,
//           totalUnit: 250,
//           rate: 4.25,
//           maxCost: 1700, // 400 * 4.25
//        },
//        {
//           minUnit: 401,
//           rate: 4.65,
//        },
//     ],
//     chattisgarh: [
//        {
//           minUnit: 1,
//           maxUnit: 100,
//           totalUnit: 100,
//           rate: 3.7,
//           maxCost: 370, // 100 * 3.7
//        },
//        {
//           minUnit: 101,
//           maxUnit: 200,
//           totalUnit: 100,
//           rate: 3.9,
//           maxCost: 780, // 200 * 3.9
//        },
//        {
//           minUnit: 201,
//           maxUnit: 400,
//           totalUnit: 200,
//           rate: 5.3,
//           maxCost: 2120, // 400 * 5.3
//        },
//        {
//           minUnit: 401,
//           maxUnit: 600,
//           totalUnit: 200,
//           rate: 6.3,
//           maxCost: 3780, // 600 * 6.3
//        },
//        {
//           minUnit: 601,
//           rate: 7.9,
//        },
//     ],
//  };

export const unitRateInfo = {
  andhra_pradesh: [
    {
      minUnit: 1,
      maxUnit: 30,
      totalUnit: 30,
      rate: 1.9,
      maxCost: 57,
    },
    {
      minUnit: 31,
      maxUnit: 75,
      totalUnit: 45,
      rate: 3,
      maxCost: 135,
    },
    {
      minUnit: 76,
      maxUnit: 125,
      totalUnit: 50,
      rate: 4.5,
      maxCost: 225,
    },
    {
      minUnit: 126,
      maxUnit: 225,
      totalUnit: 100,
      rate: 6,
      maxCost: 600,
    },
    {
      minUnit: 226,
      maxUnit: 400,
      totalUnit: 175,
      rate: 8.75,
      maxCost: 1531.25,
    },
    {
      minUnit: 401,
      rate: 9.75,
    },
  ],
  andaman_and_nicobar: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 2.25,
      maxCost: 225,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 5,
      maxCost: 500,
    },
    {
      minUnit: 201,
      maxUnit: 500,
      totalUnit: 300,
      rate: 7.2,
      maxCost: 2160,
    },
    {
      minUnit: 501,
      rate: 7.5,
    },
  ],
  arunachal_pradesh: [
    {
      minUnit: 1,
      rate: 4,
    },
  ],
  assam: [
    {
      minUnit: 1,
      maxUnit: 120,
      totalUnit: 120,
      rate: 5.3,
      maxCost: 636,
    },
    {
      minUnit: 121,
      maxUnit: 240,
      totalUnit: 120,
      rate: 6.6,
      maxCost: 792,
    },
    {
      minUnit: 241,
      rate: 7.6,
    },
  ],
  bihar: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 6.1,
      maxCost: 610,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 6.95,
      maxCost: 695,
    },
    {
      minUnit: 201,
      rate: 8.05,
    },
  ],
  chandigarh: [
    {
      minUnit: 1,
      maxUnit: 150,
      totalUnit: 150,
      rate: 2.5,
      maxCost: 375,
    },
    {
      minUnit: 151,
      maxUnit: 400,
      totalUnit: 250,
      rate: 4.25,
      maxCost: 1062.5,
    },
    {
      minUnit: 401,
      rate: 4.65,
    },
  ],
  chattisgarh: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 3.7,
      maxCost: 370,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 3.9,
      maxCost: 390,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 5.3,
      maxCost: 1060,
    },
    {
      minUnit: 401,
      maxUnit: 600,
      totalUnit: 200,
      rate: 6.3,
      maxCost: 1260,
    },
    {
      minUnit: 601,
      rate: 7.9,
    },
  ],
  damananddiu: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 1.6,
      maxCost: 80,
    },
    {
      minUnit: 51,
      maxUnit: 200,
      totalUnit: 150,
      rate: 2.3,
      maxCost: 345,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 2.8,
      maxCost: 560,
    },
    {
      minUnit: 401,
      rate: 3.4,
    },
  ],
  delhi: [
    {
      minUnit: 0,
      maxUnit: 200,
      totalUnit: 200,
      rate: 3,
      maxCost: 600,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 4.5,
      maxCost: 900,
    },
    {
      minUnit: 401,
      maxUnit: 800,
      totalUnit: 400,
      rate: 6.5,
      maxCost: 2600,
    },
    {
      minUnit: 801,
      maxUnit: 1200,
      totalUnit: 400,
      rate: 7,
      maxCost: 2800,
    },
    {
      minUnit: 1201,
      rate: 8,
    },
  ],
  goa: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 1.6,
      maxCost: 160,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 2.35,
      maxCost: 235,
    },
    {
      minUnit: 201,
      maxUnit: 300,
      totalUnit: 100,
      rate: 2.95,
      maxCost: 295,
    },
    {
      minUnit: 301,
      maxUnit: 400,
      totalUnit: 100,
      rate: 3.9,
      maxCost: 390,
    },
    {
      minUnit: 401,
      rate: 4.5,
    },
  ],
  gujarat: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 3.05,
      maxCost: 152.5,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 3.5,
      maxCost: 175,
    },
    {
      minUnit: 101,
      maxUnit: 250,
      totalUnit: 150,
      rate: 4.15,
      maxCost: 622.5,
    },
    {
      minUnit: 251,
      rate: 5.2,
    },
  ],
  // gujarattorrentahd: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 50,
  //     totalUnit: 50,
  //     rate: 3.2,
  //     maxCost: 160,
  //   },
  //   {
  //     minUnit: 51,
  //     maxUnit: 200,
  //     totalUnit: 150,
  //     rate: 3.95,
  //     maxCost: 592.5,
  //   },
  //   {
  //     minUnit: 201,
  //     rate: 5,
  //   },
  // ],
  // gujarattorrentsurat: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 50,
  //     totalUnit: 50,
  //     rate: 3.2,
  //     maxCost: 160,
  //   },
  //   {
  //     minUnit: 51,
  //     maxUnit: 100,
  //     totalUnit: 50,
  //     rate: 3.65,
  //     maxCost: 182.5,
  //   },
  //   {
  //     minUnit: 101,
  //     maxUnit: 250,
  //     totalUnit: 150,
  //     rate: 4.25,
  //     maxCost: 637.5,
  //   },
  //   {
  //     minUnit: 251,
  //     rate: 5.05,
  //   },
  // ],
  haryana: [
    {
      minUnit: 0,
      maxUnit: 50,
      totalUnit: 50,
      rate: 2,
      maxCost: 100,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 2.5,
      maxCost: 125,
    },
    {
      minUnit: 251,
      maxUnit: 500,
      totalUnit: 250,
      rate: 6.3,
      maxCost: 1575,
    },
    {
      minUnit: 501,
      maxUnit: 800,
      totalUnit: 300,
      rate: 7.1,
      maxCost: 2130,
    },
    {
      minUnit: 801,
      rate: 7.1,
    },
  ],

  himachal_pradesh: [
    {
      minUnit: 1,
      maxUnit: 125,
      totalUnit: 125,
      rate: 4.15,
      maxCost: 518.75,
    },
    {
      minUnit: 126,
      maxUnit: 300,
      totalUnit: 175,
      rate: 5.05,
      maxCost: 883.75,
    },
    {
      minUnit: 301,
      rate: 5.65,
    },
  ],
  jammu_and_kashmir: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 1.69,
      maxCost: 169,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 2.2,
      maxCost: 220,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 3.3,
      maxCost: 660,
    },
    {
      minUnit: 401,
      rate: 3.52,
    },
  ],
  jharkhand: [
    {
      minUnit: 1,
      rate: 6.25,
    },
  ],
  karnataka: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 4.15,
      maxCost: 202.5,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 5.6,
      maxCost: 280,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 7.15,
      maxCost: 715,
    },
    {
      minUnit: 201,
      rate: 8.2,
    },
  ],
  kerala: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 3.15,
      maxCost: 157.5,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 3.7,
      maxCost: 185,
    },
    {
      minUnit: 101,
      maxUnit: 150,
      totalUnit: 50,
      rate: 4.8,
      maxCost: 240,
    },
    {
      minUnit: 151,
      maxUnit: 200,
      totalUnit: 50,
      rate: 6.4,
      maxCost: 320,
    },
    {
      minUnit: 201,
      maxUnit: 250,
      totalUnit: 50,
      rate: 7.6,
      maxCost: 380,
    },
    {
      minUnit: 251,
      maxUnit: 300,
      totalUnit: 50,
      rate: 5.8,
      maxCost: 290,
    },
    {
      minUnit: 301,
      maxUnit: 350,
      totalUnit: 50,
      rate: 6.6,
      maxCost: 330,
    },
    {
      minUnit: 351,
      maxUnit: 400,
      totalUnit: 50,
      rate: 6.9,
      maxCost: 345,
    },
    {
      minUnit: 401,
      maxUnit: 500,
      totalUnit: 100,
      rate: 7.1,
      maxCost: 710,
    },
    {
      minUnit: 501,
      rate: 7.9,
    },
  ],
  lakshadweep: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 1.6,
      maxCost: 80,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 3.3,
      maxCost: 330,
    },
    {
      minUnit: 201,
      maxUnit: 300,
      totalUnit: 100,
      rate: 5.5,
      maxCost: 550,
    },
    {
      minUnit: 301,
      rate: 7,
    },
  ],
  madhya_pradesh: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 4.21,
      maxCost: 210.5,
    },
    {
      minUnit: 51,
      maxUnit: 150,
      totalUnit: 100,
      rate: 5.17,
      maxCost: 517,
    },
    {
      minUnit: 151,
      maxUnit: 300,
      totalUnit: 150,
      rate: 6.55,
      maxCost: 982.5,
    },
    {
      minUnit: 301,
      rate: 6.74,
    },
  ],
  maharashtra: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 4.71,
      maxCost: 471,
    },
    {
      minUnit: 101,
      maxUnit: 300,
      totalUnit: 200,
      rate: 8.69,
      maxCost: 1738,
    },
    {
      minUnit: 301,
      maxUnit: 500,
      totalUnit: 200,
      rate: 11.72,
      maxCost: 2344,
    },
    {
      minUnit: 501,
      rate: 13.21,
    },
  ],
  manipur: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 5.1,
      maxCost: 510,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 4.5,
      maxCost: 450,
    },
    {
      minUnit: 201,
      rate: 6.1,
    },
  ],
  meghalaya: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 4.1,
      maxCost: 410,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 4.5,
      maxCost: 450,
    },
    {
      minUnit: 201,
      rate: 6.1,
    },
  ],
  mizoram: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 4.9,
      maxCost: 490,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 7.1,
      maxCost: 710,
    },
    {
      minUnit: 201,
      rate: 8.2,
    },
  ],
  // mumbaiAdani: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 100,
  //     totalUnit: 100,
  //     rate: 4.52,
  //     maxCost: 452,
  //   },
  //   {
  //     minUnit: 101,
  //     maxUnit: 300,
  //     totalUnit: 200,
  //     rate: 6.47,
  //     maxCost: 1294,
  //   },
  //   {
  //     minUnit: 301,
  //     maxUnit: 500,
  //     totalUnit: 200,
  //     rate: 9.49,
  //     maxCost: 1898,
  //   },
  //   {
  //     minUnit: 501,
  //     rate: 9.27,
  //   },
  // ],
  // mumbai: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 100,
  //     totalUnit: 100,
  //     rate: 3.45,
  //     maxCost: 345,
  //   },
  //   {
  //     minUnit: 101,
  //     maxUnit: 300,
  //     totalUnit: 200,
  //     rate: 6.17,
  //     maxCost: 1234,
  //   },
  //   {
  //     minUnit: 301,
  //     maxUnit: 500,
  //     totalUnit: 200,
  //     rate: 9.86,
  //     maxCost: 1972,
  //   },
  //   {
  //     minUnit: 501,
  //     rate: 10.61,
  //   },
  // ],
  // mumbaibest: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 100,
  //     totalUnit: 100,
  //     rate: 3.18,
  //     maxCost: 318,
  //   },
  //   {
  //     minUnit: 101,
  //     maxUnit: 300,
  //     totalUnit: 200,
  //     rate: 5.81,
  //     maxCost: 581,
  //   },
  //   {
  //     minUnit: 301,
  //     maxUnit: 500,
  //     totalUnit: 200,
  //     rate: 8.65,
  //     maxCost: 1730,
  //   },
  //   {
  //     minUnit: 501,
  //     maxUnit: Infinity,
  //     rate: 10.61,
  //   },
  // ],
  nagaland: [
    {
      minUnit: 1,
      maxUnit: 30,
      totalUnit: 30,
      rate: 4.7,
      maxCost: 141,
    },
    {
      minUnit: 31,
      maxUnit: 100,
      totalUnit: 70,
      rate: 5.45,
      maxCost: 381.5,
    },
    {
      minUnit: 101,
      maxUnit: 250,
      totalUnit: 150,
      rate: 6,
      maxCost: 900,
    },
    {
      minUnit: 251,
      rate: 7,
    },
  ],
  odisha: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 3,
      maxCost: 150,
    },
    {
      minUnit: 51,
      maxUnit: 200,
      totalUnit: 150,
      rate: 4.8,
      maxCost: 720,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 5.8,
      maxCost: 1160,
    },
    {
      minUnit: 401,
      rate: 6.2,
    },
  ],
  puducherry: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 1.9,
      maxCost: 190,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 2.9,
      maxCost: 290,
    },
    {
      minUnit: 201,
      maxUnit: 300,
      totalUnit: 100,
      rate: 5,
      maxCost: 500,
    },
    {
      minUnit: 301,
      rate: 6.4,
    },
  ],
  punjab: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 3.49,
      maxCost: 349,
    },
    {
      minUnit: 101,
      maxUnit: 300,
      totalUnit: 200,
      rate: 5.84,
      maxCost: 1168,
    },
    {
      minUnit: 301,
      rate: 7.3,
    },
  ],
  rajasthan: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 4.75,
      maxCost: 237.5,
    },
    {
      minUnit: 51,
      maxUnit: 150,
      totalUnit: 100,
      rate: 6.5,
      maxCost: 650,
    },
    {
      minUnit: 151,
      maxUnit: 300,
      totalUnit: 150,
      rate: 7.35,
      maxCost: 1102.5,
    },
    {
      minUnit: 301,
      maxUnit: 500,
      totalUnit: 200,
      rate: 7.67,
      maxCost: 1534,
    },
    {
      minUnit: 501,
      rate: 7.95,
    },
  ],
  sikkim: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 1,
      maxCost: 50,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 2,
      maxCost: 100,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 3,
      maxCost: 300,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 3.5,
      maxCost: 700,
    },
    {
      minUnit: 401,
      rate: 4,
    },
  ],
  tamil_nadu: [
    {
      minUnit: 1,
      maxUnit: 200,
      totalUnit: 200,
      rate: 4.5,
      maxCost: 900,
    },
    {
      minUnit: 201,
      maxUnit: 250,
      totalUnit: 50,
      rate: 6,
      maxCost: 300,
    },
    {
      minUnit: 251,
      maxUnit: 300,
      totalUnit: 50,
      rate: 8,
      maxCost: 400,
    },
    {
      minUnit: 301,
      maxUnit: 400,
      totalUnit: 100,
      rate: 9,
      maxCost: 900,
    },
    {
      minUnit: 401,
      maxUnit: 500,
      totalUnit: 100,
      rate: 10,
      maxCost: 1000,
    },
    {
      minUnit: 501,
      rate: 11,
    },
  ],
  telangana: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 1.95,
      maxCost: 97.5,
    },
    {
      minUnit: 51,
      maxUnit: 100,
      totalUnit: 50,
      rate: 3.1,
      maxCost: 155,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 4.8,
      maxCost: 480,
    },
    {
      minUnit: 201,
      maxUnit: 300,
      totalUnit: 100,
      rate: 7.7,
      maxCost: 770,
    },
    {
      minUnit: 301,
      maxUnit: 400,
      totalUnit: 100,
      rate: 9,
      maxCost: 900,
    },
    {
      minUnit: 401,
      maxUnit: 800,
      totalUnit: 400,
      rate: 9.5,
      maxCost: 3800,
    },
    {
      minUnit: 801,
      rate: 10,
    },
  ],
  tripura: [
    {
      minUnit: 1,
      maxUnit: 50,
      totalUnit: 50,
      rate: 4.84,
      maxCost: 242,
    },
    {
      minUnit: 51,
      maxUnit: 150,
      totalUnit: 100,
      rate: 5.98,
      maxCost: 598,
    },
    {
      minUnit: 151,
      maxUnit: 300,
      totalUnit: 150,
      rate: 6.16,
      maxCost: 924,
    },
    {
      minUnit: 301,
      rate: 7.2,
    },
  ],
  uttar_pradesh: [
    {
      minUnit: 1,
      maxUnit: 150,
      totalUnit: 150,
      rate: 5.5,
      maxCost: 825,
    },
    {
      minUnit: 151,
      maxUnit: 300,
      totalUnit: 150,
      rate: 6,
      maxCost: 900,
    },
    {
      minUnit: 301,
      maxUnit: 500,
      totalUnit: 200,
      rate: 6.5,
      maxCost: 1300,
    },
    {
      minUnit: 501,
      rate: 7,
    },
  ],
  uttarakhand: [
    {
      minUnit: 1,
      maxUnit: 100,
      totalUnit: 100,
      rate: 2.9,
      maxCost: 290,
    },
    {
      minUnit: 101,
      maxUnit: 200,
      totalUnit: 100,
      rate: 4.2,
      maxCost: 420,
    },
    {
      minUnit: 201,
      maxUnit: 400,
      totalUnit: 200,
      rate: 5.8,
      maxCost: 1160,
    },
    {
      minUnit: 401,
      rate: 6.55,
    },
  ],
  west_bengal: [
    {
      minUnit: 1,
      maxUnit: 102,
      totalUnit: 102,
      rate: 5.49,
      maxCost: 560.98,
    },
    {
      minUnit: 103,
      maxUnit: 180,
      totalUnit: 78,
      rate: 6.09,
      maxCost: 475.02,
    },
    {
      minUnit: 181,
      maxUnit: 300,
      totalUnit: 120,
      rate: 6.96,
      maxCost: 835.2,
    },
    {
      minUnit: 301,
      maxUnit: 600,
      totalUnit: 300,
      rate: 7.46,
      maxCost: 2238,
    },
    {
      minUnit: 601,
      maxUnit: 900,
      totalUnit: 300,
      rate: 7.55,
      maxCost: 2265,
    },
    {
      minUnit: 901,
      rate: 9.22,
    },
  ],

  // westBengalKolkataCESC: [
  //   {
  //     minUnit: 1,
  //     maxUnit: 25,
  //     totalUnit: 25,
  //     rate: 5.18,
  //     maxCost: 129.5,
  //   },
  //   {
  //     minUnit: 26,
  //     maxUnit: 60,
  //     totalUnit: 35,
  //     rate: 5.69,
  //     maxCost: 198.15,
  //   },
  //   {
  //     minUnit: 61,
  //     maxUnit: 100,
  //     totalUnit: 40,
  //     rate: 6.7,
  //     maxCost: 268,
  //   },
  //   {
  //     minUnit: 101,
  //     maxUnit: 150,
  //     totalUnit: 50,
  //     rate: 7.45,
  //     maxCost: 372.5,
  //   },
  //   {
  //     minUnit: 151,
  //     maxUnit: 200,
  //     totalUnit: 50,
  //     rate: 7.62,
  //     maxCost: 381,
  //   },
  //   {
  //     minUnit: 201,
  //     maxUnit: 300,
  //     totalUnit: 100,
  //     rate: 7.62,
  //     maxCost: 762,
  //   },
  //   {
  //     minUnit: 301,
  //     rate: 9.21,
  //   },
  // ],
};
