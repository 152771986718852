import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { forwardRef } from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiMenu-list": {
    fontSize: "0.8rem !important",
  },
  width: "100%",
  marginTop: ".2rem",
}));

const CustomTextField = (
  {
    style,
    sx,
    iconText,
    error,
    errorText,
    formValidation,
    onChange,
    step,
    value,
    defaultValue,
    size,
    disabled = false,
    ...props
  },
  ref
) => {
  return (
    <StyledTextField
      inputRef={ref}
      {...props}
      style={{ ...style }}
      sx={{ ...sx }}
      // InputProps={{
      //    endAdornment: iconText ? <InputAdornment position='start'>{iconText}</InputAdornment> : null,
      // }}
      InputProps={{
        ...(props.InputProps && { ...props.InputProps }), // Conditionally spread InputProps if provided
        endAdornment: iconText ? (
          <InputAdornment position="start">{iconText}</InputAdornment>
        ) : null,
      }}
      inputProps={{
        step: step,
      }}
      size={size ? size : "small"}
      onChange={onChange}
      // value={value}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
};

export default forwardRef(CustomTextField);
