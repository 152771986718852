export function calculateTotalPrice(cart) {
   let totalPrice = 0;

   cart.forEach((cartItem) => {
      const quantity = cartItem.quantity || 1; // If quantity is not specified, default to 1

      const averagePrice = cartItem.attributes.find(
         (item) => item.name === 'Average Price'
      );
      const price = parseFloat(averagePrice?.attribute_values[0]?.value) || 0;

      totalPrice += quantity * price;
   });

   return totalPrice.toFixed(2); // Round to 2 decimal places
}

export function calculateItemPrice(cartItem) {
   const quantity = cartItem.quantity || 1; // If quantity is not specified, default to 1

   const averagePrice = cartItem.attributes.find(
      (item) => item.name === 'Average Price'
   );
   const price = parseFloat(averagePrice?.attribute_values[0]?.value) || 0;

   return (quantity * price).toFixed(2); // Round to 2 decimal places
}

export function calculateOnlyPrice(cartItem) {
   const averagePrice = cartItem.attributes.find(
      (item) => item.name === 'Average Price'
   );
   const price = parseFloat(averagePrice?.attribute_values[0]?.value) || 0;

   return price.toFixed(2); // Round to 2 decimal places
}

export function getProductNames(products) {
   // Extract product names from each product object
   const productNames = products.map((product) => product.product_name);

   // Join product names into a single string separated by commas
   const allProductNames = productNames.join(', ');

   return allProductNames;
}

export function calculateTotalSumDimensionsAndWeight(products) {
   let totalHeight = 0;
   let totalLength = 0;
   let totalBreadth = 0;
   let totalWeight = 0;

   products.forEach(product => {
       const { height, length, breadth, weight, quantity } = product;
       totalHeight += parseInt(height) * quantity;
       totalLength += parseInt(length) * quantity;
       totalBreadth += parseInt(breadth) * quantity;
       totalWeight += parseInt(weight) * quantity;
   });

   return {
       totalHeight,
       totalLength,
       totalBreadth,
       totalWeight
   };
}
