import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Drawer,
   List,
   ListItem,
   ListItemText,
   Typography,
} from '@mui/material';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
import LaunchIcon from '@mui/icons-material/Launch';

const companies = [
   {
      name: 'Google',
      age: 23,
      details: 'Google details',
      subCompanies: [
         {
            name: 'Google Sub Company 1',
            details: 'Details of Google Sub Company 1',
         },
         {
            name: 'Google Sub Company 2',
            details: 'Details of Google Sub Company 2',
         },
         {
            name: 'Google Sub Company 3',
            details: 'Details of Google Sub Company 3',
         },
         {
            name: 'Google Sub Company 4',
            details: 'Details of Google Sub Company 4',
         },
         {
            name: 'Google Sub Company 5',
            details: 'Details of Google Sub Company 5',
         },
      ],
   },
   {
      name: 'Facebook',
      age: 18,
      details: 'Facebook details',
      subCompanies: [
         {
            name: 'Facebook Sub Company 1',
            details: 'Details of Facebook Sub Company 1',
         },
         {
            name: 'Facebook Sub Company 2',
            details: 'Details of Facebook Sub Company 2',
         },
         {
            name: 'Facebook Sub Company 3',
            details: 'Details of Facebook Sub Company 3',
         },
         {
            name: 'Facebook Sub Company 4',
            details: 'Details of Facebook Sub Company 4',
         },
         {
            name: 'Facebook Sub Company 5',
            details: 'Details of Facebook Sub Company 5',
         },
      ],
   },
   {
      name: 'Apple',
      age: 45,
      details: 'Apple details',
      subCompanies: [
         {
            name: 'Apple Sub Company 1',
            details: 'Details of Apple Sub Company 1',
         },
         {
            name: 'Apple Sub Company 2',
            details: 'Details of Apple Sub Company 2',
         },
         {
            name: 'Apple Sub Company 3',
            details: 'Details of Apple Sub Company 3',
         },
         {
            name: 'Apple Sub Company 4',
            details: 'Details of Apple Sub Company 4',
         },
         {
            name: 'Apple Sub Company 5',
            details: 'Details of Apple Sub Company 5',
         },
      ],
   },
];

const AccordionWrapper = styled(Accordion)(({ theme, index }) => ({
   '& .MuiAccordionSummary-root': {
      // backgroundColor:
      //    index % 2 === 0
      //       ? theme.palette.primary.light
      //       : theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
      borderBottom: '1px solid rgba(255, 255, 255, .2)',
   },
}));

const DrawerWrapper = styled(Drawer)(({ theme }) => ({
   width: 400,
   flexShrink: 0,
   '& .MuiDrawer-paper': {
      width: 400,
   },
}));



function SubCompanyList({ subCompanies }) {
   const [selectedSubCompany, setSelectedSubCompany] = useState(null);

   const handleSubCompanyClick = (subCompany) => {
      setSelectedSubCompany(subCompany);
   };

   const handleDrawerClose = () => {
      setSelectedSubCompany(null);
   };

   const onClickDetailsButton = (e) => {
      e.stopPropagation();
      // send to the portfolio
   };

   return (
      <>
         <List>
            {subCompanies.map((subCompany, i) => (
               <ListItem
                  key={subCompany.name}
                  button
                  sx={{ '&:hover': { background: 'transparent' } }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        bgcolor: i % 2 === 0 ? '#F3F3F3' : '#D0D7D9',
                        p: 1,
                        borderRadius: 2,
                        boxShadow: 25,
                        '&:hover': {
                           boxShadow: 2,
                        },
                     }}
                  >
                     <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <ListItemText primary={subCompany.name} />
                        <PrimaryButton
                           onClick={onClickDetailsButton}
                           IconEnd={LaunchIcon}
                        >
                           Portfolio
                        </PrimaryButton>
                     </Box>
                     {/* <ChevronRight /> */}

                     <PrimaryButton
                        onClick={() => handleSubCompanyClick(subCompany)}
                     >
                        Create Enquiry
                     </PrimaryButton>
                  </Box>
               </ListItem>
            ))}
         </List>
         <DrawerWrapper
            anchor='right'
            open={Boolean(selectedSubCompany)}
            onClose={handleDrawerClose}
            
         >
            <Box sx={{ p: 2 }}>
               <Typography variant='h6' textAlign='center'>
                  {selectedSubCompany?.name}
               </Typography>

               <PrimaryButton
                  onClick={onClickDetailsButton}
                  IconEnd={LaunchIcon}
                  sx={{mx: 'auto', display: 'flex', mt: 2}}
               >
                  Portfolio
               </PrimaryButton>
            </Box>

            <div style={{ padding: 16 }}>
               <Typography>{selectedSubCompany?.details}</Typography>
            </div>
         </DrawerWrapper>
      </>
   );
}

function Manufacturers() {
   const [selectedCompany, setSelectedCompany] = useState(null);

   const [expanded, setExpanded] = useState(false);
   const handleCompanyClick = (company) => {
      setSelectedCompany(company);
      setExpanded(!expanded);
   };

   return (
      <>
         <Box
            style={{ backgroundColor: '#ffffff', padding: 16 }}
            sx={{ mt: 3, width: '80%', mx: 'auto', borderRadius: 2 }}
         >
            <ProfileDetails />
            <Typography variant='h6' sx={{ my: 3 }}>
               List of Brands
            </Typography>
            {companies.map((company, i) => (
               <AccordionWrapper
                  index={i}
                  key={company.name}
                  expanded={selectedCompany?.name === company.name && expanded}
                  // expanded={expanded}
                  onChange={() => handleCompanyClick(company)}
               >
                  <AccordionSummary>
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           width: '100%',
                        }}
                     >
                        <Typography variant='h6'>{company.name}</Typography>
                        <ExpandMoreIcon />
                     </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                     <div style={{ width: '100%' }}>
                        <Typography>{company.details}</Typography>
                        <SubCompanyList subCompanies={company.subCompanies} />
                     </div>
                  </AccordionDetails>
               </AccordionWrapper>
            ))}
         </Box>
      </>
   );
}

export default Manufacturers;
