import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay } from 'swiper';

import { Box, Typography, Avatar } from '@mui/material';
import './review_slider.css';

const reviewsData = [
   {
      review: `I got a solar system from solruf. It's a 4.5 kW solar power system with 10 kW storage. The technician was great and helped me choose the best system for my house. Solruf was good at handling the contract and paperwork, and their team did a great job installing system. I'm really happy with it and I recommend solruf.`,
      author_name: 'Manish Choudhary',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/3RQPL9Z/Mask-group-5.png',
   },
   {
      review: `I was very satisfied with the work done by Solruf and especially with the assistance I received from Rahul this man has great technical Knowledge of solar.`,
      author_name: 'Rahul Joshi',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/zfCw62R/Mask-group-6.png',
   },
   {
      review: `Setting up a solar panel system. Solruf Team was professional and trustworthy from our first phone call. They finished the work on time. The technicians who installed the panels were skilled and knowledgeable. Solruf is excellent!!!! Thank you."`,
      author_name: 'Karan Malhotra',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/QXpRjS5/Mask-group-7.png',
   },
   {
      review: `I'm really pleased with the work. The installers and the Installer were always super helpful and made me feel comfortable. They gave me all the information I needed and were always willing to help. I'd definitely recommend this to everyone."`,
      author_name: 'Mukesh Sharma',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/9gL4Tqr/Mask-group-8.png',
   },
   {
      review: `I have to say, the sales representative went above and beyond. They were incredibly responsive and always available to answer any questions I had. Plus, the system itself is running smoothly – it's been a real success story. It's clear they did a great job from the ground up.`,
      author_name: 'Ashok Saxena',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/BZ1rm6w/Mask-group-9.png',
   },

   {
      review: `I want to thank the salesperson, Mr. Khalid, for his professionalism and always being ready to explain the solar system I bought. Thanks man."`,
      author_name: 'Rajesh Kumar',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/JRzG8YN/Mask-group-12.png',
   },
   {
      review: `"Solruf has been excellent throughout the entire process of installing my PV system. They've met all my expectations so far. However, the true test of any company comes after the installation and initial support phase. That's when we'll see their long-term commitment. Having said that, I can definitely recommend them based on my experience so far.`,
      author_name: 'Vikram Singh',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/P6NTF8H/Mask-group-13.png',
   },
   {
      review: `The installation took longer than expected (signed contract in November 2023 for a 4.5 kW system, installed in early September 2024), there were delays due to my roof leakage which was resolved in December end 2023. After two months of operation, the system is working well and I haven't encountered any problems. So far, I'm happy with my experience.`,
      author_name: 'Aditya Sharma',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/3CRNzjQ/Mask-group-14.png',
   },

   {
      review: `I can't say enough good things about solruf!  From the initial design phase all the way through to testing the completed PV system, their service was outstanding. They offered the most competitive prices. But the best part? Their amazing customer service! Even after the purchase, they're always available to answer questions. I found That gave me real peace of mind.`,
      author_name: 'Arjun Patel',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/zx33V3S/Mask-group-16.png',
   },
   {
      review: `The people in this company are very professional. They handled all the paperwork, which saved me a lot of time. The representative, Rahul Kanojiya, was very friendly, helpful, and knowledgeable. He was always there when needed. I highly recommend solruf for solar installation."`,
      author_name: 'Ravi Verma',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/0GZmdvd/Mask-group.png',
   },
   {
      review: `In summer 2023, Solruf set up my solar system, including a solar pumps and storage batteries. Kudos to the installers for their quick and precise work - everything was up and running in just two day! A special thanks to sales rep Shailendra ji. His expertise and constant availability were impressive. Great job!`,
      author_name: 'Amit Kumar',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/MGPQ87v/Mask-group-1.png',
   },
   {
      review: `Hello ... my experience with the Solruf has been very good and satisfying. They've always kept their promises. I highly recommend them. The only Change I would recommend the team is they can improve on reducing the timeline for installation.`,
      author_name: 'Prakash Mishra',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/kDShKFT/Mask-group-2.png',
   },
   {
      review: `While the wait for installation in October was longer than expected due to module unavailability, I'm incredibly happy with the final outcome. The installers were professional, courteous, and did a fantastic job. It was definitely worth the wait, and I've already been recommending this company to others!`,
      author_name: 'Sandeep Mehta',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/9hPFhNZ/Mask-group-3.png',
   },
   {
      review: `Six months in with my solruf solar panel system, and I couldn't be happier! They took care of everything – from the initial consultation to the paperwork and technical setup with discount on government subsidy. The installation itself was smooth and professional – their technicians were skilled, careful, and left the worksite spotless.`,
      author_name: 'Suresh Rana',
      user_type: 'Customer',
      image_url: 'https://i.ibb.co/ggJ6F5M/Mask-group-4.png',
   },
];

const ReviewSliderResponsive = ({ reviews }) => {

   return (
      <Swiper
         loop={true}
         slidesPerView={3}
         spaceBetween={20}
         pagination={{
            clickable: true,
         }}
         centeredSlides={false}
         slidesPerGroupSkip={1}
         grabCursor={true}
         keyboard={{
            enabled: true,
         }}
         autoplay={{
            delay: 3000,
            disableOnInteraction: false,
         }}
         breakpoints={{
            1024: {
               slidesPerView: 3,
               spaceBetween: 10,
            },
            960: {
               slidesPerView: 2,
               spaceBetween: 20,
            },
            640: {
               slidesPerView: 1,
               spaceBetween: 10,
            },
            0: {
               slidesPerView: 1,
               spaceBetween: 10,
            },
         }}
         modules={[Autoplay]}
         className='review_slider'
      >
         {reviewsData.map((review, i) => (
            <SwiperSlide>
               <Box
                  sx={{
                     display: 'flex',
                     maxWidth: 600,
                     justifyContent: 'center',
                     alignItems: 'center',
                     py: 4,
                     px: 1,
                  }}
               >
                  <Box
                     sx={{
                        maxWidth: '100%',
                        width: 600,
                        borderRadius: 2,
                        py: 4,
                        px: 5,
                        boxShadow:
                           'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                     }}
                  >
                     <Typography textAlign='center'>
                        <span
                           style={{
                              fontSize: '1.8em',
                              color: '#000000',
                              lineHeight: 0,
                           }}
                        >
                           “
                        </span>
                        {review?.review.length > 230
                           ? review?.review.slice(0, 230) + '...'
                           : review?.review}
                     </Typography>

                     <Box sx={{ width: 'fit-content', mx: 'auto', mt: 3 }}>
                        <Box
                           sx={{
                              width: 65,
                              height: 65,
                              mx: 'auto',
                              // border: '4px solid #FFD700',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Avatar
                              alt='Cindy Baker'
                              src={`${review.image_url}`}
                              sx={{
                                 width: 50,
                                 height: 50,
                                 mx: 'auto',
                              }}
                           />
                        </Box>

                        <Typography
                           variant='h6'
                           sx={{
                              margin: 0,
                              textAlign: 'center',
                           }}
                        >
                           {review.author_name}
                        </Typography>

                        <Typography
                           variant='body2'
                           sx={{
                              color: '#666f73',
                              mt: -0.5,
                              textAlign: 'center',
                           }}
                        >
                           {review.user_type}
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </SwiperSlide>
         ))}
      </Swiper>
   );
};

export default ReviewSliderResponsive;
