import React from "react";
import { Box, Typography, Container, Stack, styled } from "@mui/material";
import { useForm } from "react-hook-form";
import TextFieldWithRegister from "../../../components/Custom/TextFieldWithRegister/TextFieldWithRegister";

import PrimaryButton from "../../../components/Custom/PrimaryButton/PrimaryButton";
import saveFormDataToGoogleSheet from "../../../utils/saveFormDataToSheet";
import { toast } from "react-toastify";
import { getTimeStamp } from "../../../utils/utils";

const TextFieldWithRegisterWhite = styled(TextFieldWithRegister)(
  ({ theme }) => ({
    background: "#fff",
    borderRadius: "0.2rem",
    "& fieldset": { border: "none" },
  })
);

const boxStyle = {
  backgroundImage: `
   linear-gradient(
    rgba(0, 0, 0, 0.8), 
    rgba(0, 0, 0, 0.8)
  ),
   url(https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/Abous+us.png)`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  py: 15,
};

const AnalysisForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const submitHandler = async (data) => {
    console.log(data);
    try {
      const { status } = await saveFormDataToGoogleSheet(
        { ...data, service_type: "contact form", timestamp: getTimeStamp() },
        "https://script.google.com/macros/s/AKfycby138VD54C_jCF-Y_UZTpKgfbPje-WEbCGwuNJ2u0wp_3DYw4LW7oWNSdbGPBR1MShh/exec"
      );
      if (status === 200) {
        reset();
        toast.success("Your Message was sent!");
      }
    } catch (error) {
      toast.warn("Something went wrong");
    }
  };

  return (
    <Box sx={{ ...boxStyle }} id="contact-us">
      <Container>
        <Stack
          component="form"
          direction="column"
          spacing={3}
          sx={{ maxWidth: 500, width: "100%", mx: "auto" }}
          onSubmit={handleSubmit(submitHandler)}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            color={"#ffffff"}
            textAlign="center"
          >
            Contact Us
          </Typography>
          <Typography
            variant="body"
            fontWeight={300}
            color={"#ffffff"}
            textAlign="center"
          >
            Fill up the form and our Team will get back to you as soon as
            possible
          </Typography>

          <TextFieldWithRegisterWhite
            label="Full Name"
            name="name"
            register={register}
            errors={errors}
          />
          <TextFieldWithRegisterWhite
            label="Phone Number"
            name="phone"
            register={register}
            errors={errors}
            minLength={{
              value: 10,
              message: "Number must be at least 10 characters long",
            }}
            maxLength={{
              value: 10,
              message: "Number must be at most 10 characters long",
            }}
          />
          <TextFieldWithRegisterWhite
            label="Email ID"
            name="email"
            register={register}
            errors={errors}
            pattern={{
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            }}
          />
          <TextFieldWithRegisterWhite
            label="Your Comment"
            name="message"
            register={register}
            errors={errors}
          />

          <PrimaryButton type="submit" disabled={isSubmitting}>
            {!isSubmitting ? "Connect Now!" : "Submitting..."}
          </PrimaryButton>
        </Stack>
      </Container>
    </Box>
  );
};

export default AnalysisForm;
