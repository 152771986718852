export const productCategories = [
   {
      name: 'Solar Panels',
      image: 'https://i.ibb.co/Nr5WVRL/Solar-Panel-1.png',
      CategoryId: 1,
      SubCategoryId: 2,
   },

   {
      name: 'Solar Inverter',
      image: 'https://i.ibb.co/MGDbwT8/SOLRUF-Inverter.png',
      CategoryId: 5,
      SubCategoryId: 6,
   },

   {
      name: 'Solar Battery',
      image: 'https://i.ibb.co/6RCHMqZ/battery-2.png',
      CategoryId: 9,
      SubCategoryId: 10,
   },
   {
      name: 'Solar Charge Controller',
      image: 'https://i.ibb.co/3r9LccL/Charge-controller-1.png',
      CategoryId: 9,
      SubCategoryId: 11,
   },

   {
      name: 'Solar Kit',
      image: 'https://i.ibb.co/rxv7g2T/kIT1.png',
      CategoryId: 82,
      SubCategoryId: 83,
   },
  
];
