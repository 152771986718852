import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
// import PrimaryButton from '../../../../components/Custom/PrimaryButton/PrimaryButton';
// import { useNavigate } from 'react-router';
import { axiosInstance } from "../../../../utils/axiosInstance";
import HomeSectionTitle from "../../../../components/HomeSectionTitle/HomeSectionTitle";
import ProcurementProductCard from "../../../Procurement/ProcurementProductCard";
import Grid from "@mui/material/Unstable_Grid2";
import HomeProductCarousel from "./HomeProductCarousel";

const HomeProducts = () => {
  // const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const matchSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          length: 4,
          page: 1,
        };

        const response = await axiosInstance.post(
          "api/products",
          {
            category_id: 1,
            search: "",
            attributes: [],
          },
          {
            params: params,
          }
        );

        const response1 = await axiosInstance.post(
          "api/products",
          {
            category_id: 5,
            search: "",
            attributes: [],
          },
          {
            params: params,
          }
        );

        const response2 = await axiosInstance.post(
          "api/products",
          {
            category_id: 9,
            search: "",
            attributes: [],
          },
          {
            params: params,
          }
        );

        const response3 = await axiosInstance.post(
          "api/products",
          {
            category_id: 22,
            search: "",
            attributes: [],
          },
          {
            params: params,
          }
        );
        let items = [];

        //   items.push(
        //    response.data.products.length > 0 ??
        //      response.data.products.reverse()[0]
        //  );

        console.log(response.data.products);
        console.log(response1.data.products);
        console.log(response2.data.products);
        console.log(response3.data.products);

        if (response.data.products.length > 0) {
          items.push(response?.data?.products.reverse()[0]);
        }

        if (response1.data.products.length > 0) {
          items.push(response1?.data?.products.reverse()[0]);
        }

        if (response2.data.products.length > 0) {
          items.push(response2?.data?.products.reverse()[0]);
        }

        if (response3.data.products.length > 0) {
          items.push(response3?.data?.products.reverse()[0]);
        }

        console.log(items);
        setProducts(items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <HomeSectionTitle
        fixed={
          matchSm ? "Best Deal On Solar Products" : "Grab the best deal on"
        }
        sequence={[
          // Same substring at the start will only be typed out once, initially
          "Solar Panel",
          1000, // wait 1s before replacing "Mice" with "Hamsters"
          "Solar Inverter",
          1000,
          "Solar Battery",
          1000,
          "Solar Cables",
          1000,
        ]}
        route="/products"
      ></HomeSectionTitle>

      {matchSm ? (
        <HomeProductCarousel products={products} />
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {products.map((prod, i) => (
            <Grid xs={12} sm={6} md={4} lg={4} xl={3} key={i}>
              <ProcurementProductCard product={prod} actionType="enquiry" />
            </Grid>
          ))}
        </Grid>
      )}

      {/* <PrimaryButton
            variant='secondary'
            sx={{ mx: 'auto', mt: 5 }}
            onClick={() => navigate('/products')}
         >
            See more
         </PrimaryButton> */}
    </Box>
  );
};

export default HomeProducts;
