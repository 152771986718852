import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   newCart: [],
};

export const newCartSlice = createSlice({
   name: 'newCart',
   initialState,
   reducers: {
      addToCart: (state, action) => {
         console.log(action);
         const exist = state.newCart.find(
            (item) => item?.product_id === action.payload.product_id
         );
         if (!exist) {
            state.newCart.push(action.payload);
         }
      },
      removeFromCart: (state, action) => {
         state.newCart = state.newCart.filter(
            (item) => item.product_id !== action.payload // product id need to send with payload
         );
      },
      adjustQuantity: (state, action) => {
         state.newCart = state.newCart.map((item) => {
            if (item.product_id === action.payload.product_id) {
               item.quantity = action.payload.quantity;
            }
            return item;
         });
      },
      increaseQuantity: (state, action) => {
         state.newCart = state.newCart.map((item) => {
            if (item.product_id === action.payload) {
               item.quantity = item.quantity + 1;
            }
            return item;
         });
      },
      decreaseQuantity: (state, action) => {
         state.newCart = state.newCart.map((item) => {
            if (item.product_id === action.payload) {
               if (item.quantity > 1) {
                  item.quantity = item.quantity - 1;
               }
            }
            return item;
         });
      },
      syncCart: (state, action) => {
         state.newCart = action.payload;
      },
      removeCart: (state) => {
         state.newCart = [];
      },
   },
});

// Action creators are generated for each case reducer function
export const {
   addToCart,
   removeFromCart,
   adjustQuantity,
   removeCart,
   increaseQuantity,
   decreaseQuantity,
   syncCart,
} = newCartSlice.actions;

export default newCartSlice.reducer;
