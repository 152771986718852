import {
  Box,
  Button,
  Container,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import PrimaryButton from "../Custom/PrimaryButton/PrimaryButton";
import SolrufTextField from "../SolrufTextField/SolrufTextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import SolrufModal2 from "../Custom/SolrufModal/SolrufModal2";
import solarPanelImage from "../../assets/solar-panel.svg";
import costImage from "../../assets/cost.svg";
import electricityGenImage from "../../assets/electricity_gen.svg";
import tariffImage from "../../assets/tariff.svg";
import rooftopImage from "../../assets/rooftop.svg";
import roiImage from "../../assets/roi.svg";

import useSolarCalculator from "../../hooks/useSolarCalculator";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EMICalculator from "../EMICalculator/EMICalculator";
import {
  BoxWrapper,
  CalculatorTextField,
  InnerBoxWrapper,
  SmallTabBtn,
  SmallTabBtnsWrapper,
  TabBtn,
  TabBtnsWrapper,
  Wrapper,
} from "./SolarCalculator.style";
import { HashLink } from "react-router-hash-link";
import { BankButton, BanksWrapper } from "../EMICalculator/EMICalculator.style";
import axisImage from "../../assets/axis.jpg";
import iciciImage from "../../assets/icici.jpg";
import hsbcImage from "../../assets/hsbc.jpg";
import kotakImage from "../../assets/kotak.jpg";
import cbiImage from "../../assets/centralbankofindia.jpg";
import corpImage from "../../assets/corporation.jpg";
import indusindImage from "../../assets/indusind.jpg";
import rblImage from "../../assets/rbl.jpg";
import scImage from "../../assets/standardcharted.jpg";
import yesImage from "../../assets/yes.jpg";
import sbiImage from "../../assets/sbi.png";
import instacredImage from "../../assets/instacred.png";
import { banksData, statesData, valuetext } from "./utils";
import { unitRateInfo } from "../UnitCalculator/unitCalculator.constant";
import { calculateTotalUnits } from "../../utils/calculateUnit";
import { axiosInstance } from "../../utils/axiosInstance";
import calcIcon1 from "../../assets/calIcons/calcIcon1.png";
import calcIcon2 from "../../assets/calIcons/calcIcon2.png";
import calcIcon3 from "../../assets/calIcons/calcIcon3.png";
import calcIcon4 from "../../assets/calIcons/calcIcon4.png";
import calcIcon5 from "../../assets/calIcons/calcIcon5.png";
import calcIcon6 from "../../assets/calIcons/calcIcon6.png";
import calcIcon7 from "../../assets/calIcons/calcIcon7.png";
import {
  FormWrapper,
  LeadFormWrapper,
  RowWrapper,
} from "../HomeLeadForm/HomeLeadForm.style";
import { getTimeStamp } from "../../utils/utils";
import { toast } from "react-toastify";
import saveFormDataToGoogleSheet from "../../utils/saveFormDataToSheet";
import { useNavigate } from "react-router";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

function formatIndianNumber(number) {
  // Convert the number to a string and split into integer and fractional parts
  let [integerPart, fractionalPart] = number.toString().split(".");

  // Add commas to the integer part in Indian style
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits != "") {
    lastThreeDigits = "," + lastThreeDigits;
  }
  let formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  // Combine the integer and fractional parts
  if (fractionalPart) {
    return formattedIntegerPart + "." + fractionalPart;
  } else {
    return formattedIntegerPart;
  }
}

const SolarCalculator = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: emiRegister,
    handleSubmit: handleEMISubmit,
    formState: { errors: emiErrors },
    reset: resetEMI,
  } = useForm();
  const {
    register: enquiryRegister,
    handleSubmit: handleEnquirySubmit,
    formState: { errors: enquiryErrors },
    reset: resetEnquery,
  } = useForm();
  const [calcType, setCalcType] = useState("total_rooftop_area");
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [showCalculation, setShowCalculation] = useState(true);
  // const categoryData = [
  //   "Residential",
  //   "Industrial",
  //   "Institutional",
  //   "Commercial",
  //   "Government",
  //   "Social Sector",
  // ];
  const categoryData = [
    "Residential",
    "Industrial",
    "Commercial",
    "Government",
  ];
  const [calculationType, setCalculationType] = useState("capacity");

  const mnreCostData = {
    typeA: {
      upto1: 46923,
      from1to2: 43140,
      from2to3: 42020,
      from3to10: 40991,
      from10to100: 38236,
      from100to500: 35886,
    },
    typeB: {
      upto1: 51616,
      from1to2: 47447,
      from2to3: 46216,
      from3to10: 45087,
      from10to100: 42056,
      from100to500: 39467,
    },
  };

  const mnreCostData2 = {
    from0to5: 65000,
    from5to10: 65000,
    from11to20: 55000,
    from21to30: 53000,
    from31to40: 49000,
    from41to50: 46000,
    from51to100: 42000,
    from101to200: 40000,
    from201to300: 38000,
    from301to500: 34000,
  };

  const [calcOutputs, setCalcOutputs] = useState(null);
  const solarCalculator = useSolarCalculator();
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(13.0);

  const [bank, setBank] = useState("axis");
  const [monthlyCost, setMonthlyCost] = useState(null);
  const [totalUnits, setTotalUnits] = useState(null);
  const [totalCapacity, setTotalCapacity] = useState(null);
  const [totalBudget, setTotalBudget] = useState(null);
  const [system_size, setSystemSize] = useState(null);
  const [system_cost, setSystemCost] = useState(null);
  const [daily_consumption, setDailyConsumption] = useState(null);
  const [monthly_consumption, setMonthlyConsumption] = useState(null);
  const [annual_consumption, setAnnualConsumption] = useState(null);
  const [consumptionIn25, setConsumptionIn25] = useState(null);
  const [monthlySavings, setMonthlySavings] = useState(null);
  const [yearlySavings, setYearlySavings] = useState(null);
  const [reqShadowFreeArea, setReqShadowFreeArea] = useState(null);
  const [roicount, setRoi] = useState(null);
  const [calc2ModalOpen, setCalc2ModalOpen] = useState(false);
  const [duration, setDuration] = useState(12);
  const [durationType, setDurationType] = useState("Months");

  const navigate = useNavigate();

  const calculateEMI = ({ amount, months, rate }) => {
    // const durationTime = durationType === "Years" ? months * 12 : months;
    // const r = rate / (100 * 12);
    // const factor = Math.pow(1 + r, durationTime);
    // const emiValue =
    //   (parseFloat(Number(amount)).toFixed(2) * r * durationTime * factor) /
    //   ((factor - 1) * durationTime);
    // return parseFloat(emiValue).toFixed(2);
    // Adjust months if duration type is Years
    const totalMonths = durationType === "Years" ? months * 12 : months;

    // Monthly interest rate
    const r = rate / (100 * 12);

    // EMI calculation using the correct formula
    const factor = Math.pow(1 + r, totalMonths);
    const emiValue = (amount * r * factor) / (factor - 1);

    // Return the EMI value rounded to 2 decimal places
    return emiValue.toFixed(2);
  };

  const handleRegister = async (inputs) => {
    if (calcType === "total_rooftop_area" && calculationType === "unit") {
      console.log(inputs);
      // const units = calculateTotalUnits(
      //   inputs.state,
      //   parseFloat(inputs.average_electricity_cost),
      //   unitRateInfo
      // );

      const { data } = await axiosInstance.post(
        "https://api-dev-v2.solruf.com/api/v2/unit-calculation",
        {
          stateName: inputs.state,
          totalCost: parseFloat(inputs.average_electricity_cost),
        }
      );

      const units = data.units;

      const totalCost = inputs.average_electricity_cost; // monthly cost
      const systemSize = units / 120;

      // setMonthlyCost(totalCost);

      setSystemSize(systemSize);

      let mnreCost = 0;

      if (systemSize > 0 && systemSize <= 5) mnreCost = mnreCostData2.from0to5;
      else if (systemSize > 5 && systemSize <= 10)
        mnreCost = mnreCostData2.from5to10;
      else if (systemSize > 10 && systemSize <= 20)
        mnreCost = mnreCostData2.from11to20;
      else if (systemSize > 20 && systemSize <= 30)
        mnreCost = mnreCostData2.from21to30;
      else if (systemSize > 30 && systemSize <= 40)
        mnreCost = mnreCostData2.from31to40;
      else if (systemSize > 40 && systemSize <= 50)
        mnreCost = mnreCostData2.from41to50;
      else if (systemSize > 50 && systemSize <= 100)
        mnreCost = mnreCostData2.from51to100;
      else if (systemSize > 100 && systemSize <= 200)
        mnreCost = mnreCostData2.from101to200;
      else if (systemSize > 200 && systemSize <= 300)
        mnreCost = mnreCostData2.from201to300;
      else if (systemSize > 300 && systemSize <= 500)
        mnreCost = mnreCostData2.from301to500;

      let systemCost = 0;

      systemCost = Number(systemSize) * Number(mnreCost);

      if (systemSize < 1) {
        systemCost = mnreCostData2.from0to5;
      }

      setSystemCost(systemCost);

      const dailyConsumption = systemSize * 4.5;
      setDailyConsumption(dailyConsumption);
      const monthlyConsumption = dailyConsumption * 30;
      setMonthlyConsumption(monthlyConsumption);
      const annualConsumption = monthlyConsumption * 12;
      setAnnualConsumption(annualConsumption);
      const consumption_in_25 = annualConsumption * 25;
      setConsumptionIn25(consumption_in_25);

      const response_rate = await axiosInstance.post(
        "https://api-dev-v2.solruf.com/api/v2/rate-calculation",
        {
          stateName: inputs.state,
          totalUnits: monthlyConsumption,
        }
      );

      const monthly_savings = monthlyConsumption * response_rate.data.rate;
      setMonthlySavings(monthly_savings);
      setMonthlyCost(monthlyConsumption * response_rate.data.rate);
      const yearly_savings = monthly_savings * 12;
      setYearlySavings(yearly_savings);

      const shadowFreeArea = systemSize * 100;

      setReqShadowFreeArea(shadowFreeArea);
      const roi = systemCost / yearly_savings;
      setRoi(roi);
      setTotalUnits(units);
      setCalc2ModalOpen(true);
      return;
    }

    if (calcType === "total_rooftop_area" && calculationType === "capacity") {
      console.log(inputs);
      const spc = Number(inputs.total_rooftop_area) / 100;

      let mnreCost = 0;

      if (spc > 0 && spc <= 5) mnreCost = mnreCostData2.from0to5;
      else if (spc > 5 && spc <= 10) mnreCost = mnreCostData2.from5to10;
      else if (spc > 10 && spc <= 20) mnreCost = mnreCostData2.from11to20;
      else if (spc > 20 && spc <= 30) mnreCost = mnreCostData2.from21to30;
      else if (spc > 30 && spc <= 40) mnreCost = mnreCostData2.from31to40;
      else if (spc > 40 && spc <= 50) mnreCost = mnreCostData2.from41to50;
      else if (spc > 50 && spc <= 100) mnreCost = mnreCostData2.from51to100;
      else if (spc > 100 && spc <= 200) mnreCost = mnreCostData2.from101to200;
      else if (spc > 200 && spc <= 300) mnreCost = mnreCostData2.from201to300;
      else if (spc > 300 && spc <= 500) mnreCost = mnreCostData2.from301to500;

      console.log("MNRE Cost:", mnreCost);

      const budget = Number(spc) * Number(mnreCost);

      // calculation
      const units = spc * 120;

      // console.log(units);

      setTotalUnits(units);

      const { data } = await axiosInstance.post(
        "https://api-dev-v2.solruf.com/api/v2/cost-calculation",
        {
          stateName: inputs.state,
          totalUnits: units,
        }
      );

      const totalCost = Number(data?.cost); // monthly cost
      console.log("cost", totalCost);

      const systemSize = spc;

      setSystemSize(systemSize);

      let systemCost = 0;

      systemCost = Number(spc) * Number(mnreCost);

      if (systemSize < 1) {
        systemCost = mnreCostData2.from0to5;
      }

      setSystemCost(systemCost);

      const dailyConsumption = systemSize * 4.5;
      setDailyConsumption(dailyConsumption);
      const monthlyConsumption = dailyConsumption * 30;
      setMonthlyConsumption(monthlyConsumption);
      const annualConsumption = monthlyConsumption * 12;
      setAnnualConsumption(annualConsumption);
      const consumption_in_25 = annualConsumption * 25;
      setConsumptionIn25(consumption_in_25);

      const response_rate = await axiosInstance.post(
        "https://api-dev-v2.solruf.com/api/v2/rate-calculation",
        {
          stateName: inputs.state,
          totalUnits: monthlyConsumption,
        }
      );

      const monthly_savings = monthlyConsumption * response_rate.data.rate;
      setMonthlySavings(monthly_savings);
      setMonthlyCost(monthlyConsumption * response_rate.data.rate);
      const yearly_savings = monthly_savings * 12;
      setYearlySavings(yearly_savings);

      const shadowFreeArea = systemSize * 100;
      setReqShadowFreeArea(shadowFreeArea);
      const roi = systemCost / yearly_savings;
      setRoi(roi);

      console.log(budget);

      setTotalCapacity(spc);
      setTotalBudget(parseFloat(Number(budget)).toFixed(2));

      setCalc2ModalOpen(true);

      return;
    }
    setCalcOutputs(
      solarCalculator({
        inputs,
        calcType,
        statesData,
        mnreCostData,
        averageMnreCost: 45000,
      })
    );

    setTimeout(() => {
      setCalcModalOpen(true);
    }, 300);
  };

  const [service, setService] = useState("Book Visit");

  const handleSendEnquiry = async (formData) => {
    //  setPageIdx(3);
    //  setTimeout(() => {
    //    setPageIdx(1);
    //  }, 4000);

    const data = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
      pincode: formData.pincode,
      remarks: formData.remarks,
      enquiry_type: service,
      timestamp: getTimeStamp(),
    };

    console.log(data);

    try {
      const { status } = await saveFormDataToGoogleSheet(
        data,
        "https://script.google.com/macros/s/AKfycbyM6nO2kzZcKMwdfSVmK-zDJeo7vS9tg5_wJGu7mvCW56XcVujrAHE66rEegUgDJwHqsw/exec"
      );

      if (status === 200) {
        //   setPageIdx(3);
        // sendWhatsappNotification(
        //   data.name,
        //   formData.phone,

        //   "blog_enquiry_forms_regard",
        //   [
        //     data.name,
        //     `your enquiry on ${service}`,
        //     "into the solar installation process",
        //     "https://www.solruf.com/blogs/solarInstallationProcess",
        //     "+91-8600694140",
        //     "sumit@solruf.com",
        //   ],
        //   {
        //     url: "https://i.ibb.co/SccD90M/enquiry-forms-regard.jpg",
        //     filename: "test",
        //   }
        // );
        // setTimeout(() => {
        //    setPageIdx(1);

        // }, 4000);

        navigate("/thankyou");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    console.log("Units", totalUnits);
    console.log("monthly_cost: ", monthlyCost);
    console.log("system_size:", system_size);
    console.log("system_cost:", system_cost);
    console.log("daily_consumption:", daily_consumption);
    console.log("monthly_consumption:", monthly_consumption);
    console.log("annual_consumption:", annual_consumption);
    console.log("consumptionIn25:", consumptionIn25);
    console.log("monthlySavings:", monthlySavings);
    console.log("yearlySavings:", yearlySavings);
    console.log("reqShadowFreeArea:", reqShadowFreeArea);
    console.log("roicount:", roicount);
  }, [handleRegister]);

  const handleRadioChange = (event) => {
    setCalculationType(event.target.value);
  };

  // useEffect(() => {
  //   calcOutputs && calcOutputs.budget && setLoanAmount(calcOutputs.budget);
  // }, [calcOutputs]);

  useEffect(() => {
    system_cost && system_cost !== null && setLoanAmount(system_cost);
  }, [system_cost]);

  const handleReRegister = (inputs) => {
    setLoanAmount(inputs.amount);
    setInterestRate(parseFloat(inputs.interest).toFixed(0));
    setDuration(parseInt(inputs.duration));
    setDurationType(inputs.duration_type);
  };

  const [calcModalOpen, setCalcModalOpen] = useState(false);

  const calcOptions = [
    { name: "Total Rooftop Area", value: "total_rooftop_area" },
    {
      name: "Solar Panel Capacity you want to install",
      value: "solar_panel_capacity",
    },
    { name: "Your budget", value: "your_budget" },
  ];

  const [currentTab, setCurrentTab] = useState("solar");

  const onTabChange = () => {
    setCurrentTab(currentTab === "solar" ? "emi" : "solar");
  };

  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (e, newValue) => {
    setSliderValue(newValue);
    setValue("average_electricity_cost", newValue * 100);
  };

  return (
    <>
      <SolrufModal2
        open={calcModalOpen}
        onClose={() => {
          setCalcModalOpen(false);
          resetEMI();
        }}
        mobileTitle="Solar Rooftop Calculator"
        title="Solar Rooftop Calculator"
      >
        {calcOutputs ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.3rem",
              py: "2rem",
              px: "2rem",
              overflowY: "auto",
              maxHeight: "85vh",
            }}
          >
            <Typography variant="p" sx={{ fontSize: "1.1rem" }}>
              Average solar irradiation in{" "}
              {statesData[calcOutputs?.state].state} state is{" "}
              {statesData[calcOutputs?.state].ghi} W / sq.m
            </Typography>
            <Typography variant="p" sx={{ fontSize: "1.1rem" }}>
              1kWp solar rooftop plant will generate on an average over the year{" "}
              {statesData[calcOutputs?.state].teg} kWh of electricity per day
              (considering 5.5 sunshine hours)
            </Typography>
            <Typography variant="p" sx={{ fontSize: "1.1rem" }}>
              MNRE current Benchmark Cost{" "}
              <span style={{ fontSize: "0.8rem" }}>(without GST)</span> : Rs.{" "}
              {calcOutputs.mnre_cost} Rs. / kW
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: "1rem",
              }}
            >
              <PictureAsPdfIcon
                sx={{ fontSize: "1.5rem", color: "red", mr: "0.3rem" }}
              />
              <a
                href="https://solarrooftop.gov.in/notification/130_notification.pdf"
                target="_blank"
                rel="noreferrer"
              >
                View Benchmark Cost List
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "100%",
                gap: "1rem",
                py: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  SIZE OF KW SYSTEM
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.solar_panel_capacity).toFixed(1)} kW
                </Typography>
                <img
                  src={solarPanelImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  COST OF PLANT
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(
                    calcOutputs.budget * (1 - calcOutputs.mnre_subsidy / 100)
                  ).toFixed(0)}{" "}
                  Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>
                    (with {calcOutputs.mnre_subsidy}% subsidy)
                  </span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.budget).toFixed(0)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>(without subsidy)</span>
                </Typography>
                <img
                  src={costImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "100%",
                gap: "1rem",
                py: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  TOTAL ELECTRICITY GENERATION
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(
                    calcOutputs.total_electricity_generation_monthly
                  ).toFixed(1)}{" "}
                  kWh <span style={{ fontSize: "1.3rem" }}>monthly</span>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(
                    calcOutputs.total_electricity_generation_monthly * 10
                  ).toFixed(1)}{" "}
                  kWh <span style={{ fontSize: "1rem" }}>anually</span>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(
                    calcOutputs.total_electricity_generation_monthly * 10 * 25
                  ).toFixed(1)}{" "}
                  kWh{" "}
                  <span style={{ fontSize: "1rem" }}>Life-Time (25 years)</span>
                </Typography>
                <img
                  src={electricityGenImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  SAVINGS
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.tariff_monthly * 10 * 25).toFixed(0)}{" "}
                  Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>Life-Time (25 years)</span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.tariff_monthly).toFixed(0)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>monthly</span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.tariff_monthly * 10).toFixed(0)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>anually</span>
                </Typography>

                <img
                  src={tariffImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "100%",
                gap: "1rem",
                py: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  SHADOW FREE AREA REQUIRED
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(calcOutputs.shadow_free_area).toFixed(0)} Sq. m.{" "}
                </Typography>
                <img
                  src={rooftopImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "25rem",
                  height: "15rem",
                  border: "1.7px #ffd05b solid",
                  p: "0.7rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5rem",
                  position: "relative",
                  "@media (max-width: 600px)": {
                    "& img": {
                      width: "50px",
                    },
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  RETURN ON INVESTMENT
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(
                    (calcOutputs.tariff_monthly * 10 * 25 * 100) /
                      calcOutputs.budget
                  ).toFixed(0)}{" "}
                  %{" "}
                </Typography>
                <img
                  src={roiImage}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box
                component="form"
                onSubmit={handleEMISubmit(handleReRegister)}
                sx={{
                  display: "flex",
                  columnGap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CalculatorTextField
                  size="small"
                  label="Cost of Solar Plant"
                  type="number"
                  defaultValue={loanAmount}
                  sx={{
                    width: "20rem",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      width: "20rem",
                    },
                  }}
                  {...emiRegister("amount", {
                    required: {
                      value: true,
                      message: "Cost is required.",
                    },
                    min: {
                      value: 0,
                      message: "Cost cannot be 0.",
                    },
                  })}
                  error={emiErrors.amount}
                  helperText={emiErrors.amount && emiErrors.amount.message}
                />
                <Typography>Rs.</Typography>
                <PrimaryButton
                  type="submit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <CachedIcon /> Recalculate EMI
                </PrimaryButton>
              </Box>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                Select Bank
              </Typography>
              <BanksWrapper>
                <BankButton
                  src={axisImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "axis"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("axis")}
                />
                <BankButton
                  src={iciciImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "icici"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("icici")}
                />
                <BankButton
                  src={hsbcImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "hsbc"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("hsbc")}
                />
                <BankButton
                  src={kotakImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "kotak"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("kotak")}
                />
                <BankButton
                  src={cbiImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "cbi"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("cbi")}
                />
                <BankButton
                  src={corpImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "corp"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("corp")}
                />
                <BankButton
                  src={indusindImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "indusind"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("indusind")}
                />
                <BankButton
                  src={rblImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "rbl"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("rbl")}
                />
                <BankButton
                  src={scImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "sc"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("sc")}
                />
                <BankButton
                  src={yesImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "yes"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("yes")}
                />
                <BankButton
                  src={sbiImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "sbi"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("sbi")}
                />
                <BankButton
                  src={instacredImage}
                  component="img"
                  alt=""
                  sx={{
                    border:
                      bank === "instacred"
                        ? "4px solid #ffd05b"
                        : "4px solid transparent",
                  }}
                  onClick={() => setBank("instacred")}
                />
              </BanksWrapper>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: 650,
                    "@media (max-width: 600px)": {
                      minWidth: 0,
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="center">
                        Duration
                      </TableCell>
                      <TableCell sx={{ fontWeight: "700" }} align="center">
                        Interest&nbsp;(%)
                      </TableCell>
                      <TableCell sx={{ fontWeight: "700" }} align="center">
                        EMI Per Month
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {banksData[bank].map((ele) => (
                      <TableRow
                        key={ele.months}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="center">{ele.months}</TableCell>
                        <TableCell align="center">{ele.rate}</TableCell>
                        <TableCell align="center">
                          Rs.
                          {parseFloat(
                            calculateEMI({
                              amount: loanAmount,
                              months: ele.months,
                              rate: ele.rate,
                            })
                          ).toFixed(0)}
                          /-
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "100%",
                gap: "1rem",
                py: "1rem",
              }}
            >
              <PrimaryButton
                component={HashLink}
                to="#offer-form"
                sx={{
                  width: {
                    xs: "180px",
                    md: "200px",
                    lg: "230px",
                  },
                  border: "2px solid #ffd05b",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                    border: "2px solid #ffd05b",
                    color: "primary.main",
                  },

                  fontSize: {
                    xs: "0.8rem",
                    md: "0.9rem",
                    lg: "0.9rem",
                  },
                  fontWeight: "800",
                  padding: "10px",
                  color: "primary.main",
                  textAlign: "center",
                }}
              >
                Book a free site visit
              </PrimaryButton>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </SolrufModal2>
      {/* new modal */}
      <SolrufModal2
        open={calc2ModalOpen}
        onClose={() => {
          setCalc2ModalOpen(false);
          // resetEMI();
        }}
        mobileTitle="Solar Calculator"
        title="Solar Calculator"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "0.3rem",
            py: "2rem",
            // px: "2rem",
            overflowY: "auto",
            maxHeight: "85vh",
          }}
        >
          {showCalculation && (
            <>
              <Box sx={{ px: "2rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    "@media (max-width: 600px)": {
                      flexDirection: "column",
                    },
                    width: "100%",
                    gap: "2rem",
                    py: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      p: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      SYSTEM SIZE
                    </Typography>
                    <img src={calcIcon1} alt="" />
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        color: "rgba(0,0,0,0.7)",
                        textAlign: "center",
                      }}
                    >
                      {formatIndianNumber(parseFloat(system_size).toFixed(2))}{" "}
                      kW
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      p: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      TOTAL SYSTEM COST
                    </Typography>
                    <img src={calcIcon7} alt="" />
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "rgba(0,0,0,0.7)" }}
                    >
                      Rs.{" "}
                      {formatIndianNumber(parseFloat(system_cost).toFixed(2))}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    "@media (max-width: 600px)": {
                      flexDirection: "column",
                    },
                    width: "100%",
                    gap: "2rem",
                    py: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      paddingTop: "0.7rem",
                      paddingBottom: 0,
                      px: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      TOTAL ELECTRICITY GENERATION
                    </Typography>
                    <img src={calcIcon2} alt="" />
                    {/* <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(daily_consumption).toFixed(2)} Units{" "}
                  <span style={{ fontSize: "1rem" }}>Daily</span>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(monthly_consumption).toFixed(2)} Units{" "}
                  <span style={{ fontSize: "1rem" }}>Monthly</span>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(annual_consumption).toFixed(2)} Units{" "}
                  <span style={{ fontSize: "1rem" }}>Annually</span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(consumptionIn25).toFixed(2)} Units{" "}
                  <span style={{ fontSize: "1rem" }}>Life-Time (25 years)</span>
                </Typography> */}
                    <TableContainer
                      component={Paper}
                      sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    >
                      <Table sx={{}}>
                        <TableHead
                          sx={{
                            backgroundColor: "#4b97e0",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Daily
                            </TableCell>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Monthly
                            </TableCell>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Annually
                            </TableCell>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Lifetime (25 years)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {formatIndianNumber(
                                parseFloat(daily_consumption).toFixed(2)
                              )}{" "}
                              Units
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {formatIndianNumber(
                                parseFloat(monthly_consumption).toFixed(2)
                              )}{" "}
                              Units
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {formatIndianNumber(
                                parseFloat(annual_consumption).toFixed(2)
                              )}{" "}
                              Units
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              {formatIndianNumber(
                                parseFloat(consumptionIn25).toFixed(2)
                              )}{" "}
                              Units
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      // border: "2px #ffd05b solid",
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      paddingTop: "0.7rem",
                      paddingBottom: 0,
                      px: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      SAVINGS
                    </Typography>
                    <img src={calcIcon3} alt="" />
                    {/* <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(monthlySavings).toFixed(2)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>Monthly</span>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(yearlySavings).toFixed(2)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>Annually</span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "rgba(0,0,0,0.7)" }}
                >
                  {parseFloat(yearlySavings * 25).toFixed(2)} Rs.{" "}
                  <span style={{ fontSize: "1rem" }}>Life-Time (25 years)</span>
                </Typography> */}
                    <TableContainer
                      component={Paper}
                      sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    >
                      <Table sx={{}}>
                        <TableHead
                          sx={{
                            backgroundColor: "#4b97e0",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Monthly
                            </TableCell>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Annually
                            </TableCell>
                            <TableCell
                              sx={{ color: "#ffffff", fontWeight: "bold" }}
                              align="center"
                            >
                              Lifetime (25 years)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {formatIndianNumber(
                                parseFloat(monthlySavings).toFixed(2)
                              )}{" "}
                              ₹
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {formatIndianNumber(
                                parseFloat(yearlySavings).toFixed(2)
                              )}{" "}
                              ₹
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold" }}
                            >
                              {formatIndianNumber(
                                parseFloat(yearlySavings * 25).toFixed(2)
                              )}{" "}
                              ₹
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    "@media (max-width: 600px)": {
                      flexDirection: "column",
                    },
                    width: "100%",
                    gap: "2rem",
                    py: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      p: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      SHADOW FREE AREA REQUIRED
                    </Typography>
                    <img src={calcIcon4} alt="" />
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "rgba(0,0,0,0.7)" }}
                    >
                      {formatIndianNumber(
                        parseFloat(reqShadowFreeArea).toFixed(2)
                      )}{" "}
                      Sq. ft.{" "}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                      border: "2px #4b97e0 solid",
                      borderRadius: "5px",
                      p: "0.7rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "1.5rem",
                      position: "relative",
                      "@media (max-width: 600px)": {
                        "& img": {
                          width: "50px",
                        },
                        width: "100%",
                        height: "auto",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        "@media (max-width: 600px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                    >
                      ROI (PAYBACK PERIOD)
                    </Typography>
                    <img src={calcIcon5} alt="" />
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "rgba(0,0,0,0.7)" }}
                    >
                      {formatIndianNumber(parseFloat(roicount).toFixed(0))}{" "}
                      Years{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ p: "0.7rem" }}>
                  <Typography variant="p">
                    <span style={{ fontWeight: "bold" }}>Disclimer: </span>
                    Prices (Excluding GST) are Tentative and may change
                    depending on the project site.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  style={{
                    fontSize: "24px",
                    fontWight: "bold",
                    color: "#000000",
                    backgroundColor: "#ffd05b",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  EMI CALCULATOR
                </Box>
                <Box
                  component="form"
                  onSubmit={handleEMISubmit(handleReRegister)}
                  sx={{
                    textAlign: "center",
                    p: 5,
                    backgroundColor: "#4b97e0",
                    display: "flex",
                    columnGap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CalculatorTextField
                    variant="filled"
                    size="small"
                    label="Cost of Solar Plant"
                    type="text"
                    defaultValue={parseFloat(system_cost).toFixed(2)}
                    sx={{
                      width: "20rem",
                      background: "#fff",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "20px",
                        width: "20rem",
                        background: "#fff",
                      },
                    }}
                    {...emiRegister("amount", {
                      required: {
                        value: true,
                        message: "Cost is required.",
                      },
                      // min: {
                      //   value: 0,
                      //   message: "Cost cannot be 0.",
                      // },
                    })}
                    error={emiErrors.amount}
                    helperText={emiErrors.amount && emiErrors.amount.message}
                  />
                  <CalculatorTextField
                    size="small"
                    variant="filled"
                    label="Interest Rate (%)"
                    type="text"
                    defaultValue={parseFloat("13").toFixed(2)}
                    sx={{
                      width: "8rem",
                      background: "#fff",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "20px",
                        width: "10rem",
                        background: "#fff",
                      },
                    }}
                    {...emiRegister("interest", {
                      required: {
                        value: true,
                        message: "Interest is required.",
                      },
                      // min: {
                      //   value: 0,
                      //   message: "Cost cannot be 0.",
                      // },
                    })}
                    error={emiErrors.amount}
                    helperText={emiErrors.amount && emiErrors.amount.message}
                  />
                  <Box
                    sx={{
                      width: "12rem",
                      margin: "0 auto",
                      border: "none",
                      // border: "2px solid #FFD05B",
                      borderRadius: "2px",
                      outline: "none",
                      // fontFamily: theme.typography.fontFamily,
                      height: "50px",
                      // padding: '.2rem',
                      overflow: "hidden",
                      "& input": {
                        border: "none",
                        width: "70%",
                        height: "100%",
                        padding: "1rem",
                      },
                      "& select": {
                        border: "none",
                        outline: "outline",
                        width: "30%",
                        borderRight: "5px solid #FFD05B",
                        height: "100%",
                        textAlign: "center",
                        background: "#ffd05b",
                        "& option": {
                          background: "#ffd05b",
                          color: "#ffffff",
                        },
                      },
                    }}
                  >
                    <input
                      style={{ width: "50%" }}
                      type="number"
                      placeholder="Duration"
                      defaultValue={12}
                      {...emiRegister("duration", {
                        required: {
                          value: true,
                          message: "Duration is Required",
                        },
                      })}
                      name="duration"
                      onChange={(event) => +event.target.value}
                    />

                    <select
                      style={{ width: "50%" }}
                      name="durationType"
                      {...emiRegister("duration_type", {
                        required: {
                          value: true,
                          message: "Duration Type is Required",
                        },
                      })}
                    >
                      <option value="Months">Months</option>
                      <option value="Years">Years</option>
                    </select>
                  </Box>
                  <Typography>Rs.</Typography>
                  <PrimaryButton
                    type="submit"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      height: "50px",
                      fontSize: "1rem",
                    }}
                  >
                    {/* <CachedIcon />  */}
                    Calculate
                  </PrimaryButton>
                </Box>
                <Box sx={{ px: "2rem", mt: 3 }}>
                  {/* <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", textAlign: "center", mb: 3 }}
                  >
                    Select Bank
                  </Typography>
                  <BanksWrapper sx={{ mb: 5 }}>
                    <BankButton
                      src={axisImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "axis"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("axis")}
                    />
                    <BankButton
                      src={iciciImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "icici"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("icici")}
                    />
                    <BankButton
                      src={hsbcImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "hsbc"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("hsbc")}
                    />
                    <BankButton
                      src={kotakImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "kotak"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("kotak")}
                    />
                    <BankButton
                      src={cbiImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "cbi"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("cbi")}
                    />
                    <BankButton
                      src={corpImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "corp"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("corp")}
                    />
                    <BankButton
                      src={indusindImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "indusind"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("indusind")}
                    />
                    <BankButton
                      src={rblImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "rbl"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("rbl")}
                    />
                    <BankButton
                      src={scImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "sc"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("sc")}
                    />
                    <BankButton
                      src={yesImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "yes"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("yes")}
                    />
                    <BankButton
                      src={sbiImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "sbi"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("sbi")}
                    />
                    <BankButton
                      src={instacredImage}
                      component="img"
                      alt=""
                      sx={{
                        border:
                          bank === "instacred"
                            ? "4px solid #ffd05b"
                            : "4px solid transparent",
                      }}
                      onClick={() => setBank("instacred")}
                    />
                  </BanksWrapper> */}
                  <Box sx={{ px: 4 }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          // minWidth: 650,
                          "@media (max-width: 600px)": {
                            minWidth: 0,
                          },
                          border: "2px solid #4b97e0",
                        }}
                        aria-label="simple table"
                      >
                        <TableHead sx={{ backgroundColor: "#4b97e0" }}>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", color: "#ffffff" }}
                              align="center"
                            >
                              Duration
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", color: "#ffffff" }}
                              align="center"
                            >
                              Interest&nbsp;(%)
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", color: "#ffffff" }}
                              align="center"
                            >
                              EMI Per Month
                            </TableCell>
                            {durationType === "Years" && (
                              <TableCell
                                sx={{ fontWeight: "bold", color: "#ffffff" }}
                                align="center"
                              >
                                EMI Per Year
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {banksData[bank].map((ele) => (
                            <TableRow
                              key={ele.months}
                              // sx={{
                              //   "&:last-child td, &:last-child th": {
                              //     border: 0,
                              //   },
                              // }}
                            >
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #4b97e0",
                                  borderBottom: "2px solid #4b97e0",
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {ele.months}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #4b97e0",
                                  borderBottom: "2px solid #4b97e0",
                                  fontWeight: "bold",
                                }}
                                align="center"
                              >
                                {ele.rate}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  borderBottom: "2px solid #4b97e0",
                                  fontWeight: "bold",
                                }}
                              >
                                Rs.
                                {formatIndianNumber(
                                  parseFloat(
                                    calculateEMI({
                                      amount: loanAmount,
                                      months: ele.months,
                                      rate: ele.rate,
                                    })
                                  ).toFixed(0)
                                )}
                                /-
                              </TableCell>
                            </TableRow>
                          ))} */}
                          <TableRow
                          // sx={{
                          //   "&:last-child td, &:last-child th": {
                          //     border: 0,
                          //   },
                          // }}
                          >
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {duration} {durationType}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {interestRate}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                            >
                              Rs.
                              {formatIndianNumber(
                                parseFloat(
                                  calculateEMI({
                                    amount: loanAmount,
                                    months: parseInt(duration),
                                    rate: interestRate,
                                  })
                                ).toFixed(0)
                              )}
                              /-
                            </TableCell>
                            {durationType === "Years" && (
                              <TableCell
                                align="center"
                                sx={{
                                  borderLeft: "2px solid #4b97e0",
                                  borderBottom: "2px solid #4b97e0",
                                  fontWeight: "bold",
                                }}
                              >
                                Rs.
                                {formatIndianNumber(
                                  parseFloat(
                                    calculateEMI({
                                      amount: loanAmount,
                                      months: parseInt(duration),
                                      rate: interestRate,
                                    })
                                  ).toFixed(0) * 12
                                )}
                                /-
                              </TableCell>
                            )}
                          </TableRow>

                          {/* <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {6}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {interestRate}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                            >
                              Rs.
                              {formatIndianNumber(
                                parseFloat(
                                  calculateEMI({
                                    amount: loanAmount,
                                    months: 6,
                                    rate: interestRate,
                                  })
                                ).toFixed(0)
                              )}
                              /-
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {9}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {interestRate}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                            >
                              Rs.
                              {formatIndianNumber(
                                parseFloat(
                                  calculateEMI({
                                    amount: loanAmount,
                                    months: 9,
                                    rate: interestRate,
                                  })
                                ).toFixed(0)
                              )}
                              /-
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {12}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "2px solid #4b97e0",
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {interestRate}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: "2px solid #4b97e0",
                                fontWeight: "bold",
                              }}
                            >
                              Rs.
                              {formatIndianNumber(
                                parseFloat(
                                  calculateEMI({
                                    amount: loanAmount,
                                    months: 12,
                                    rate: interestRate,
                                  })
                                ).toFixed(0)
                              )}
                              /-
                            </TableCell>
                          </TableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  onClick={() => {
                    setShowCalculation(false);
                    setShowEnquiryForm(true);
                  }}
                  variant="h6"
                  sx={{
                    p: 1,
                    // border: "2px solid #ffd05b",
                    backgroundColor: "#4b97e0",
                    borderRadius: "5px",
                    color: "#ffffff",
                    fontWeight: "bold",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  BOOK A FREE SOLAR CONSULTATION
                </Typography>
              </Box>
            </>
          )}

          {showEnquiryForm && (
            <Box>
              <LeadFormWrapper
                component="form"
                onSubmit={handleEnquirySubmit(handleSendEnquiry)}
              >
                <RowWrapper sx={{ paddingBottom: "2rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      rowGap: "0.5rem",
                      padding: "1rem",
                      "@media (max-width: 600px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Button
                        sx={{ padding: "0", color: "#4d4d4d" }}
                        onClick={() => {
                          setShowEnquiryForm(false);
                          setShowCalculation(true);
                        }}
                      >
                        <ChevronLeft /> Go Back
                      </Button>
                    </Box>

                    <Typography variant="h4" fontWeight={600}>
                      Please tell us your enquiry.
                    </Typography>
                    <Typography variant="h5" fontWeight={400}>
                      We will be get back to you shortly.
                    </Typography>

                    {/* <PrimaryButton
                     sx={{
                        maxWidth: '150px',
                     }}
                     IconEnd={LaunchIcon}
                     onClick={() => {
                      navigate('/solar-pump-form')
                     }}
                  >
                     Lead Form
                  </PrimaryButton> */}
                  </Box>
                  <FormWrapper>
                    <SolrufTextField
                      size="large"
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Your Name"
                      {...enquiryRegister("name", {
                        required: {
                          value: true,
                          message: "Name is required.",
                        },
                      })}
                      error={enquiryErrors.name}
                      helperText={
                        enquiryErrors.name ? enquiryErrors.name.message : ""
                      }
                    />
                    <SolrufTextField
                      size="large"
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Your Phone"
                      {...enquiryRegister("phone", {
                        required: {
                          value: true,
                          message: "Phone Number  is required.",
                        },
                        minLength: {
                          value: 10,
                          message:
                            "Phone Number must be at least 10 characters long.",
                        },
                        maxLength: {
                          value: 13,
                          message: "Phone Number too long.",
                        },
                      })}
                      error={enquiryErrors.phone}
                      helperText={
                        enquiryErrors.phone ? enquiryErrors.phone.message : ""
                      }
                    />
                    <SolrufTextField
                      size="large"
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Your Email"
                      {...enquiryRegister("email", {
                        required: {
                          value: true,
                          message: "Email  is required.",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      error={enquiryErrors.email}
                      helperText={
                        enquiryErrors.email ? enquiryErrors.email.message : ""
                      }
                    />
                    <SolrufTextField
                      size="large"
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Your Address"
                      {...enquiryRegister("address", {
                        required: {
                          value: true,
                          message: "Address  is required.",
                        },
                      })}
                      error={enquiryErrors.address}
                      helperText={
                        enquiryErrors.address
                          ? enquiryErrors.address.message
                          : ""
                      }
                    />
                    <SolrufTextField
                      size="large"
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Your Pincode"
                      {...enquiryRegister("pincode", {
                        required: {
                          value: true,
                          message: "Pincode  is required.",
                        },
                      })}
                      error={enquiryErrors.pincode}
                      helperText={
                        enquiryErrors.pincode
                          ? enquiryErrors.pincode.message
                          : ""
                      }
                    />
                    <SolrufTextField
                      size="large"
                      multiline
                      rows={3}
                      sx={{
                        appearance: "none",
                        "& .MuiInputBase-root": {
                          borderRadius: "10px",
                          "-webkit-backdrop-filter": "blur(10px)",
                          "backdrop-filter": "blur(10px)",
                          background: "rgba(255, 255, 255,0.2)",
                        },
                      }}
                      label="Remarks (Optional)"
                      {...enquiryRegister("remarks")}
                      // error={enquiryErrors.query}
                      // helperText={enquiryErrors.query ? enquiryErrors.query.message : ""}
                    />

                    {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <StyledCheckbox
                  label="Label"
                  defaultChecked
                  checked={whatsappChecked}
                  onChange={handleWhatsappChecked}
                  sx={{}}
                />
                <Typography variant="subtitle1">
                  Opt in for Solar Products Whatsapp Updates
                </Typography>
              </Box> */}
                    <PrimaryButton
                      type="submit"
                      sx={{
                        width: "100%",
                        py: 1.5,
                        fontSize: "1rem",
                        background: "#4d4d4d",
                        color: "#FFD05B",
                        "&:hover": {
                          background: "#000000",
                        },

                        "&:disabled": {
                          background: "rgba(255, 208, 93, 0.5)",
                          color: "#4d4d4d",
                        },

                        fontWeight: "600",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        mt: "1.5rem",
                      }}

                      // disabled={isSubmitting}
                    >
                      Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
                    </PrimaryButton>
                  </FormWrapper>
                  {/* <SendWrapper
              sx={{
                alignItems: "space-between",
              }}
            >
              <Lottie
                animationData={solarHouse}
                loop={true}
                style={{ height: "14rem", transform: "scale(2.2)" }}
              />
              <PrimaryButton
                type="submit"
                sx={{
                  width: "100%",
                  py: 1.5,
                  fontSize: "1rem",
                  background: "#4d4d4d",
                  color: "#FFD05B",
                  "&:hover": {
                    background: "#000000",
                  },

                  "&:disabled": {
                    background: "rgba(255, 208, 93, 0.5)",
                    color: "#4d4d4d",
                  },

                  fontWeight: "600",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  mt: "1.5rem",
                }}

                // disabled={isSubmitting}
              >
                Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
              </PrimaryButton>
            </SendWrapper> */}
                </RowWrapper>
                <Box
                  sx={{
                    display: "none",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    columnGap: "1.5rem",
                    "@media (max-width: 600px)": {
                      display: "flex",
                    },
                  }}
                >
                  <PrimaryButton
                    type="submit"
                    sx={{
                      width: "100%",

                      py: 1.5,
                      fontSize: "1rem",
                      background: "#000000",
                      color: "#FFD05B",
                      "&:hover": {
                        background: "#000000",
                      },
                      fontWeight: "600",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
                  </PrimaryButton>
                </Box>
              </LeadFormWrapper>
            </Box>
          )}
        </Box>
      </SolrufModal2>
      {/* end new modal */}
      <Box id="solar-calculator" sx={{ transform: "translateY(-105px)" }} />
      <Container maxWidth="xl" id="solar-calc">
        <Wrapper>
          <BoxWrapper>
            <TabBtnsWrapper>
              <TabBtn
                sx={{
                  bgcolor:
                    currentTab === "solar"
                      ? "primary.dark"
                      : "rgba(255,208,91, 0.2)",
                }}
                // onClick={onTabChange}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: currentTab === "solar" ? "primary.main" : "",
                    fontWeight: "700",
                    textAlign: "center",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  SOLAR ROOFTOP CALCULATOR
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "center",
                    color: currentTab === "solar" ? "white" : "",
                    "@media (max-width: 600px)": {
                      display: "none",
                    },
                  }}
                >
                  Check your Solar installation requirements & get NIL
                  electricity bills.
                </Typography>
              </TabBtn>
              {/* <TabBtn
                sx={{
                  bgcolor:
                    currentTab === "emi"
                      ? "primary.dark"
                      : "rgba(255,208,91, 0.2)",
                }}
                onClick={onTabChange}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: currentTab === "emi" ? "primary.main" : "",
                    fontWeight: "700",
                    textAlign: "center",
                    "@media (max-width: 600px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  EMI CALCULATOR
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    textAlign: "center",
                    color: currentTab === "emi" ? "white" : "",
                    "@media (max-width: 600px)": {
                      display: "none",
                    },
                  }}
                >
                  Check Easy Financing options to build your business with
                  monthly payments
                </Typography>
              </TabBtn> */}
            </TabBtnsWrapper>

            <InnerBoxWrapper>
              {
                {
                  solar: (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        rowGap: "1rem",
                      }}
                      component="form"
                      onSubmit={handleSubmit(handleRegister)}
                    >
                      {/* <Typography varinat="h6" color="black">
                        Select a method
                      </Typography> */}
                      {/* <SmallTabBtnsWrapper>
                        <SmallTabBtn
                          sx={{
                            background:
                              calcType === "total_rooftop_area" ? "black" : "",
                          }}
                          onClick={() => {
                            setCalcType("total_rooftop_area");
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color:
                                calcType === "total_rooftop_area"
                                  ? "primary.main"
                                  : "",
                              fontWeight: "700",
                              textAlign: "center",
                              "@media (max-width: 600px)": {
                                fontSize: "0.8rem",
                              },
                            }}
                          >
                            Total Rooftop Area
                          </Typography>
                        </SmallTabBtn>
                        <SmallTabBtn
                          sx={{
                            background:
                              calcType === "solar_panel_capacity"
                                ? "black"
                                : "",
                          }}
                          onClick={() => {
                            setCalcType("solar_panel_capacity");
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color:
                                calcType === "solar_panel_capacity"
                                  ? "primary.main"
                                  : "",
                              fontWeight: "700",
                              textAlign: "center",
                              "@media (max-width: 600px)": {
                                fontSize: "0.8rem",
                              },
                            }}
                          >
                            Solar Panel Capacity
                          </Typography>
                        </SmallTabBtn>
                        <SmallTabBtn
                          sx={{
                            background:
                              calcType === "your_budget" ? "black" : "",
                          }}
                          onClick={() => {
                            setCalcType("your_budget");
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color:
                                calcType === "your_budget"
                                  ? "primary.main"
                                  : "",
                              fontWeight: "700",
                              textAlign: "center",
                              "@media (max-width: 600px)": {
                                fontSize: "0.8rem",
                              },
                            }}
                          >
                            Your Budget
                          </Typography>
                        </SmallTabBtn>
                      </SmallTabBtnsWrapper> */}
                      <SolrufTextField
                        size="small"
                        select
                        sx={{
                          border: "none",
                          outline: "none",
                          p: 0,

                          "& .MuiOutlinedInput-root": {
                            borderRadius: "20px",
                            p: 0,
                            border: "none",
                            outline: "none",
                            "@media (min-width: 600px)": {
                              display: "none",
                            },
                          },
                        }}
                        value={calcType}
                        onChange={(event) => {
                          setCalcType(event.target.value);
                        }}
                      >
                        {calcOptions.map(({ name, value }) => (
                          <MenuItem value={value} key={value}>
                            {name}
                          </MenuItem>
                        ))}
                      </SolrufTextField>
                      {calcType === "total_rooftop_area" ? (
                        <>
                          {/* Radio buttons for calculation type */}
                          <RadioGroup
                            row
                            value={calculationType}
                            onChange={handleRadioChange}
                            sx={{ marginBottom: "1rem" }}
                          >
                            <FormControlLabel
                              value="capacity"
                              control={<Radio />}
                              label="Total shadow free area"
                            />
                            <FormControlLabel
                              value="unit"
                              control={<Radio />}
                              label="Monthly electricity bill"
                            />
                          </RadioGroup>

                          {calculationType === "capacity" && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                columnGap: "1rem",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: [
                                    "flex-start",
                                    "flex-start",
                                    "center",
                                  ],
                                  justifyContent: "space-between",
                                  rowGap: "0.2rem",
                                  width: "100%",
                                  flexDirection: ["column", "column", "row"],
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: "left",
                                    fontWeight: "700",
                                    fontSize: "1.2rem",
                                    color: "#000000",
                                  }}
                                >
                                  Shadow Free Rooftop Area (In Sq feet)
                                </Typography>
                                <CalculatorTextField
                                  size="small"
                                  type="number"
                                  label="Shadow Free Rooftop Area"
                                  step="0.01"
                                  {...register("total_rooftop_area", {
                                    required: {
                                      value: true,
                                      message: "Total Rooftop Area is required",
                                    },
                                    min: {
                                      value: 1,
                                      message: "Total Rooftop Area cannot be 0",
                                    },
                                  })}
                                  error={errors.total_rooftop_area}
                                  helperText={
                                    errors.total_rooftop_area &&
                                    errors.total_rooftop_area.message
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Sq. m
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {calcType === "solar_panel_capacity" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: ["flex-start", "flex-start", "center"],
                            columnGap: "0.2rem",
                            width: "100%",
                            justifyContent: "space-between",
                            flexDirection: ["column", "column", "row"],
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "700",
                              fontSize: "1.2rem",
                              color: "#000000",
                            }}
                          >
                            Solar Panel Capacity
                          </Typography>
                          <CalculatorTextField
                            sx={{
                              width: ["100%", "50%"],
                            }}
                            size="small"
                            type="number"
                            step="0.01"
                            label="Panel Capacity want to.."
                            {...register("solar_panel_capacity", {
                              required: {
                                value: true,
                                message: "Solar Panel Capacity is required",
                              },
                              min: {
                                value: 1,
                                message: "Solar Panel Capacity cannot be 0",
                              },
                            })}
                            error={errors.solar_panel_capacity}
                            helperText={
                              errors.solar_panel_capacity &&
                              errors.solar_panel_capacity.message
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  KW
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}

                      {calcType === "your_budget" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: ["flex-start", "flex-start", "center"],
                            justifyContent: "space-between",
                            rowGap: "0.2rem",
                            width: "100%",
                            flexDirection: ["column", "column", "row"],
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontWeight: "700",
                              fontSize: "1.2rem",
                              color: "#000000",
                            }}
                          >
                            Solar Panel Capacity
                          </Typography>
                          <CalculatorTextField
                            size="small"
                            type="number"
                            label="Your Budget"
                            {...register("budget", {
                              required: {
                                value: true,
                                message: "Budget is required",
                              },
                              min: {
                                value: 50000,
                                message: "Budget cannot be less than 50,000",
                              },
                            })}
                            error={errors.budget}
                            helperText={errors.budget && errors.budget.message}
                            sx={{
                              width: "100%",
                              maxWidth: "400px",
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Rs
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: ["flex-start", "flex-start", "center"],
                          flexDirection: ["column", "column", "row"],
                          justifyContent: "space-between",
                          columnGap: "2rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "700",
                            fontSize: "1.2rem",
                            color: "#000000",
                          }}
                        >
                          Select State
                        </Typography>
                        <CalculatorTextField
                          size="small"
                          defaultValue=""
                          select
                          label="Select State"
                          {...register("state", {
                            required: {
                              value: true,
                              message: "State is required",
                            },
                          })}
                          error={errors.state}
                          helperText={errors.state && errors.state.message}
                          sx={{
                            maxWidth: "400px",
                          }}
                        >
                          {calculationType === "capacity"
                            ? // ? statesData.map(({ state }, idx) => (
                              //     <MenuItem value={idx} key={state}>
                              //       {state}
                              //     </MenuItem>
                              //   ))
                              Object.keys(unitRateInfo).map((state) => (
                                <MenuItem value={state} key={state}>
                                  {state
                                    ?.toUpperCase()
                                    .replace(/_/g, " ")
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1).toLowerCase()
                                    )
                                    .join(" ")}
                                </MenuItem>
                              ))
                            : Object.keys(unitRateInfo).map((state) => (
                                <MenuItem value={state} key={state}>
                                  {state
                                    ?.toUpperCase()
                                    .replace(/_/g, " ")
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1).toLowerCase()
                                    )
                                    .join(" ")}
                                </MenuItem>
                              ))}
                        </CalculatorTextField>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: ["flex-start", "flex-start", "center"],
                          flexDirection: ["column", "column", "row"],
                          justifyContent: "space-between",
                          columnGap: "2rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "700",
                            fontSize: "1.2rem",
                            color: "#000000",
                          }}
                        >
                          Select Category
                        </Typography>
                        <CalculatorTextField
                          size="small"
                          defaultValue=""
                          select
                          label="Customer Category"
                          {...register("category", {
                            required: {
                              value: true,
                              message: "Category of Customer is required",
                            },
                          })}
                          error={errors.category}
                          helperText={
                            errors.category && errors.category.message
                          }
                          sx={{
                            maxWidth: "400px",
                          }}
                        >
                          {categoryData.map((category) => (
                            <MenuItem value={category} key={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </CalculatorTextField>
                      </Box>
                      <Box
                        sx={{
                          display:
                            calcType === "total_rooftop_area" &&
                            calculationType === "capacity"
                              ? "none"
                              : "flex",
                          alignItems: ["flex-start", "flex-start", "center"],
                          flexDirection: ["column", "column", "row"],
                          justifyContent: "space-between",
                          rowGap: "0.2rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "700",
                            fontSize: "1.2rem",
                            color: "#000000",
                          }}
                        >
                          Average Electricity Cost
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            maxWidth: "400px",
                          }}
                        >
                          <CalculatorTextField
                            size="small"
                            type="number"
                            label="Enter in Rs"
                            defaultValue={sliderValue}
                            {...register("average_electricity_cost", {
                              required: {
                                value:
                                  calcType === "total_rooftop_area" &&
                                  calculationType === "capacity"
                                    ? false
                                    : true,
                                message: "Average Electricity Cost is required",
                              },
                              min: {
                                value:
                                  calcType === "total_rooftop_area" &&
                                  calculationType === "capacity"
                                    ? 0
                                    : 1,
                                message: "Average Electricity Cost cannot be 0",
                              },
                            })}
                            error={errors.average_electricity_cost}
                            helperText={
                              errors.average_electricity_cost &&
                              errors.average_electricity_cost.message
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="Rs. /kwh">
                                  Rs
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              width: "100%",
                              maxWidth: "400px",
                              mr: 2,
                            }}
                          />
                          <Slider
                            aria-label="Temperature"
                            defaultValue={0}
                            getAriaValueText={valuetext}
                            // valueLabelDisplay='auto'
                            step={5}
                            marks
                            min={0}
                            max={1000}
                            value={sliderValue}
                            onChange={handleSliderChange}
                          />
                        </Box>
                      </Box>
                      <PrimaryButton
                        size="large"
                        sx={{
                          fontSize: "1.2rem",
                          "@media (max-width: 600px)": {
                            fontSize: "0.8rem",
                            width: "100%",
                          },
                          borderRadius: "100px",
                          background: "black",
                          color: "primary.main",
                          display: "flex",
                          alignItems: "center",
                          mt: "1rem",
                          pr: "0.5rem",
                          "&:hover": {
                            backgroundColor: "black",
                            transform: "scale(1.05)",
                            transition: "transform 0.1s ease",
                          },
                        }}
                        type="submit"
                      >
                        Calculate Now
                        <ArrowForwardIcon
                          sx={{
                            fontSize: "3rem",
                            ml: "1rem",
                            color: "black",
                            p: "0.5rem",
                            borderRadius: "100px",
                            backgroundColor: "primary.main",
                          }}
                        />
                      </PrimaryButton>
                      {/* {calculationType === "capacity" &&
                        totalCapacity !== null && (
                          <Typography variant="h5">
                            Total Capacity: {totalCapacity} KW
                          </Typography>
                        )}

                      {calculationType === "capacity" &&
                        totalBudget !== null && (
                          <Typography variant="h5">
                            System Cost: Rs. {totalBudget}
                          </Typography>
                        )}

                      {calculationType === "unit" && totalUnits !== null && (
                        <Typography variant="h5">
                          Total Units Used: {totalUnits}
                        </Typography>
                      )} */}
                    </Box>
                  ),
                  emi: <EMICalculator />,
                }[currentTab]
              }
            </InnerBoxWrapper>
          </BoxWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default SolarCalculator;
