import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

export default function FAQ() {
  return (
    <Box sx={{ width: { xs: "100%", sm: "50%" }, mx: { xs: 4, sm: 2 } }}>
      <Typography variant="h4" sx={{ mb: 4, mt: 8, textAlign: "center" }}>
        Frequently Asked Questions (FAQs)
      </Typography>
      {/* <Typography sx={{ mx: 2, fontWeight: "700" }}>
        Solruf Products and Services
      </Typography> */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            What solar products and services do you offer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          All type of solar products are available such as Exide battery etc and
          in services We offer rooftop and ground-mounted solar systems for both
          residential and commercial properties.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            Do you offer financing options for solar installations?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Yes! We do provide solar financing options in terms of Resco Model. We
          provide 3yrs, 5yrs & 7yrs options with the ROI ranging from 10% to 18%
          based on your financial details.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            Can I get a solar system with a government subsidy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Yes! There is solar subsidy and other tax benefits for residential,
          commercial & industrial customers for more details you can connect
          with our team.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            How much Money can be saved based on my current electricity bill?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Based on your current electricity bill we can save 70% to 80% of your
          expenses by installing solar.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            How long do solar panels last?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          The life of solar panels is 25 yrs (manufacturer warranty) however
          there are new solar panels with 30yrs warranty.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <Typography sx={{ fontweight: "700" }}>
            I'm interested in solar lighting solutions for my home. What do you
            offer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          Solruf offers a variety of solar lighting options, including our
          popular 3-bulb solar kit. You can explore our full product catalog on
          our website.
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
