import React from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Autoplay } from 'swiper';
import ProcurementProductCard from '../../../Procurement/ProcurementProductCard';

// import banner2 from '../../../../assets/banners/Banner 2.svg';
// import banner4 from '../../../../assets/banners/Banner 4.svg';
// import banner6 from '../../../../assets/banners/Banner 6.svg';
// import banner3 from '../../../../assets/banners/Banner 3.svg';

const HomeProductCarousel = ({ products }) => {
   return (
      <Box>
         <Swiper
            pagination={{
               dynamicBullets: true,
               clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className='homeBannerSlider'
            autoplay={{
               delay: 3000,
               disableOnInteraction: false,
               pauseOnMouseEnter: true,
            }}
            loop={true}
         >
            {products.map((prod, i) => (
               <SwiperSlide>
                <Box sx={{pb: 4}}>
                <ProcurementProductCard product={prod} actionType='enquiry' />

                </Box>
               </SwiperSlide>
            ))}
         </Swiper>
      </Box>
   );
};

export default HomeProductCarousel;
