import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Avatar,
   Box,
   Typography,
} from '@mui/material';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { axiAuth } from '../../../utils/axiosInstance';
import VendorList from './VendorList';

const AccordionWrapper = styled(Accordion)(({ theme, index }) => ({
   '& .MuiAccordionSummary-root': {
      // backgroundColor:
      //    index % 2 === 0
      //       ? theme.palette.primary.light
      //       : theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
      borderBottom: '1px solid rgba(255, 255, 255, .2)',
   },
}));

function Nbfcs() {
   const [selectedCompany, setSelectedCompany] = useState(null);

   const [expanded, setExpanded] = useState(false);
   const handleCompanyClick = (company) => {
      setSelectedCompany(company);
      setExpanded(!expanded);
   };

   const [links, setLinks] = useState([]);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
    

   useEffect(() => {
      async function fetchLinks() {
         try {
            setLoading(true);
            const { data, status } = await axiAuth('/api/nbfcs/my-links');
            if (status === 200) {
               setLinks(data);
               setLoading(false);
            }
         } catch (error) {
            setError(error);
            setLoading(false);
         }
      }
      fetchLinks();
   }, []);

    

   const mappedData = {
      manufacturers: links.manufacturers?.map((manufacturer) => ({
         ...manufacturer,
         vendors: links.vendors.filter((vendor) =>
            vendor.manufacturers.find((m) => m.id === manufacturer.id)
         ),
      })),
   };

    if(error){
      return <div>{'Something went wrong while fetching links'}</div>
    }

   return (
      <>
         <Box
            style={{ backgroundColor: '#ffffff', padding: 16 }}
            sx={{ mt: 3, width: '80%', mx: 'auto', borderRadius: 2 }}
         >
            <ProfileDetails />
            <Typography variant='h6' sx={{ my: 3 }}>
               List of Manufacturers
            </Typography>
            {!loading &&
               mappedData?.manufacturers?.map((manufacturer, i) => (
                  <AccordionWrapper
                     index={i}
                     key={manufacturer?.id}
                     expanded={
                        selectedCompany?.name === manufacturer.name && expanded
                     }
                     // expanded={expanded}
                     onChange={() => handleCompanyClick(manufacturer)}
                  >
                     <AccordionSummary>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 gap: 2,
                                 alignItems: 'center',
                              }}
                           >
                              <Avatar
                                 alt='Remy Sharp'
                                 src={manufacturer?.logo}
                                 sx={{ width: 56, height: 56 }}
                              />
                              <Typography variant='h6'>
                                 {manufacturer?.name}
                              </Typography>
                           </Box>
                           <ExpandMoreIcon />
                        </Box>
                     </AccordionSummary>
                     <AccordionDetails>
                        <div style={{ width: '100%' }}>
                           <VendorList vendors={manufacturer.vendors} />
                        </div>
                     </AccordionDetails>
                  </AccordionWrapper>
               ))}
         </Box>
      </>
   );
}

export default Nbfcs;
