import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SectionHeader from "./SectionHeader";
import ShortReadsCard from "./ShortReadsCard";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";

const shortReadBlogs2 = [
  {
    title: "Solar Harmony:",
    description: "Tailored Power...",
    date: "25 January 2024 ",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/5adc6c2fef7d8e46b426fa936ff267a2-min.png",
    route: "/blogs/harmony",
  },
  {
    title: "Choosing Solar:",
    description: "Panels Wisely...",
    date: "25 January 2024",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/04d0ea3891e4522156f821b4920c6822-min.jpeg",
    route: "/blogs/choosingSolar",
  },
  {
    title: "Solar Panel on roof:",
    description: "Sustainable...",
    date: "27 January 2024",
    img: "https://i.ibb.co/wSLhpXd/picture-1-1.png",
    route: "/blogs/roadmap",
  },
  {
    title: "Everything You Need ",
    description: "to know about...",
    date: "27 January 2024",
    img: "https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/87dab28b3da5e9f30b60db33cc2602db.jpeg",
    route: "/blogs/inverters",
  },
  {
    title: "The Future of Solar ",
    description: "Energy: Predictions...",
    date: "27 January 2024",
    img: "https://i.ibb.co/Px4MjRX/pexels-pixabay-433308-1.png",
    route: "/blogs/futureSolar",
  },
];

const ShortReads = () => {
  const [blogsData, setBlogsData] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@socialmedia_90251"
      )
      .then((response) => {
        setBlogsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });
  }, []);

  if (!blogsData) {
    return <div>Loading...</div>; // You can return a loader or placeholder here
  }
  return (
    <Box sx={{ my: 12 }}>
      <SectionHeader title="Short Reads" />

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* {shortReadBlogs2.map((blog) => (
            <Grid md={6} xs={12} lg={4}>
              <ShortReadsCard blog={blog} />
            </Grid>
          ))} */}
          {blogsData.items.map((blog) => (
            <Grid md={6} xs={12} lg={4}>
              <ShortReadsCard blog={blog} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ShortReads;
