import { Box, styled } from '@mui/system';

export const ProductCardWrapper = styled(Box)(({ theme }) => ({
   background: '#ffffff',
   borderRadius: theme.spacing(1),
   boxShadow: theme.shadows[25],
   cursor: 'pointer',
   minWidth: '250px',
   width: '100%',
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'flex-start',
   padding: theme.spacing(2)
}));
export const ProductFeatureListWrapper = styled(Box)(({ theme }) => ({
   padding: `${theme.spacing(2)} 0`,
   alignSelf: 'flex-start', // Added align-self property
   flex: 1
}));
export const ImageFeatureListWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')] : {
      display: 'flex',
      '& .title': {
         display: 'none'
      },
      columnGap: '1rem',
      marginBottom: theme.spacing(2)
  }
}));
