import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  FormControl,
  Stack,
  TextareaAutosize,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AboutUsCarousel from "../../components/AboutUsComponents/AboutUsCarousel";
// import Advisors from '../../components/AboutUsComponents/Advisors';
import Specialize from "../../components/AboutUsComponents/Specialize";
import Story from "../../components/AboutUsComponents/Story";
import Team from "../../components/AboutUsComponents/Team";
import WhatWeDo from "../../components/AboutUsComponents/WhatWeDo";
import {
  BlurBackground,
  Sphere1,
  Sphere2,
  Sphere3,
  Sphere4,
  SpheresBackground,
  Wrapper,
} from "./PartnerStyle";
import {
  AccountBalanceRounded,
  AddCircleRounded,
  CameraEnhanceRounded,
  EngineeringRounded,
  FactCheckRounded,
  HowToRegRounded,
  InventoryRounded,
  MovingRounded,
  PaymentRounded,
  PersonAddAlt1Rounded,
  QrCodeScannerRounded,
  QueryStatsRounded,
  ShareRounded,
  SupportAgentRounded,
  ThumbUpAltRounded,
  TrackChangesRounded,
  TroubleshootRounded,
  WorkspacePremiumRounded,
} from "@mui/icons-material";
import axios from "axios";
import { axiosInstance } from "../../utils/axiosInstance";
import SolrufTextField from "../../components/SolrufTextField/SolrufTextField";
import SolrufTextArea from "../../components/Custom/SolrufTextArea/SolrufTextArea";
import { toast } from "react-toastify";
import { getTimeStamp } from "../../utils/utils";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";

const PointStyle = {
  color: "#545454",
  border: "1px solid #545454",
  borderRadius: "50%",
  padding: "5px",
  fontSize: "48px",
};

const Points = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        width: "30%",
        flexWrap: "no-wrap",
        justifyContent: "center",

        "@media (max-width: 600px)": {
          alignItems: "baseline",
          flexWrap: "wrap",
          width: "37%",
        },
      }}
    >
      {children}
    </Box>
  );
};

const SecondPoints = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        width: "100%",
        flexWrap: "no-wrap",
        "@media (max-width: 600px)": {
          alignItems: "start",
        },
      }}
    >
      {children}
    </Box>
  );
};

const Partner = () => {
  // scroll to top
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
    remarks: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error message when user starts typing
    setErrorMessages((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handlePincodeChange = async (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      pincode: value,
    }));
    // Clear error message when user starts typing
    setErrorMessages((prevState) => ({
      ...prevState,
      pincode: "",
    }));

    try {
      const response = await axiosInstance.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      if (
        response.data &&
        response.data.length > 0 &&
        response.data[0].PostOffice
      ) {
        const postOffice = response.data[0].PostOffice[0];
        setFormData((prevState) => ({
          ...prevState,
          city: postOffice.District,
          state: postOffice.State,
        }));
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    // Validate required fields
    const requiredFields = [
      "fullName",
      "phoneNumber",
      "email",
      "pincode",
      "city",
      "state",
      "address",
    ];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrorMessages[field] = `${capitalizeEachWord(field)} is required`;
        hasError = true;
      }

      // Validate email pattern
      if (
        formData.email &&
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)
      ) {
        newErrorMessages.email = "Invalid email address";
        hasError = true;
      }

      // Validate phone number length
      if (formData.phoneNumber && formData.phoneNumber.length !== 10) {
        newErrorMessages.phoneNumber =
          "Phone number must be 10 characters long";
        hasError = true;
      }
    });

    if (hasError) {
      setErrorMessages(newErrorMessages);
      return;
    }

    // You can do something with the form data here, like send it to a server
    try {
      // Prepare form data
      const formDataToSend = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });
      formDataToSend.append("timestamp", getTimeStamp());
      console.log(formDataToSend.getAll("timestamp"));

      // Send form data
      await axios.post(
        "https://script.google.com/macros/s/AKfycbxN8Fo6Ixv7x6jMiUjT40jgV2g5j1Vt2VC5CgkoC9IKyvni5EUBumzIvXMJ6KkusxOpMQ/exec",
        formDataToSend
      );

      // Reset the form after successful submission
      setFormData({
        fullName: "",
        phoneNumber: "",
        email: "",
        pincode: "",
        city: "",
        state: "",
        address: "",
        remarks: "",
      });

      toast.success("Successfully registered");
    } catch (error) {
      toast.error("Something went wrong");
    }
    // Reset the form after submission
    setFormData({
      fullName: "",
      phoneNumber: "",
      email: "",
      pincode: "",
      city: "",
      state: "",
      address: "",
      remarks: "",
    });
  };

  // Function to capitalize each word
  const capitalizeEachWord = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b\w/g, (c) => c.toUpperCase())
      .replace(/_/g, " ");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Wrapper>
      <Box
        sx={{
          background:
            'url("https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1500w,f_auto,q_auto:best/newscms/2018_01/2122431/170816-solar-panels-mn-1520.jpg") no-repeat center/cover',
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              background: "rgba(42, 42, 42, 0.49)",
              borderRadius: "2px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              width: "75%",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              SOLRUF Affiliate Program
            </Typography>
            <Typography
              sx={{ color: "#FFFFFF", fontWeight: "700", textAlign: "center" }}
            >
              Easy, Digital and Highly Rewarding
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            marginTop: "25px",
            marginBottom: "25px",
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{
              width: "350px",
              background: "rgba(42, 42, 42, 0.49)",
              borderRadius: "2px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
            }}
          >
            <CardContent sx={{ height: "100%" }}>
              <Stack
                direction="column"
                sx={{ height: "100%", justifyContent: "space-between" }}
              >
                <Box>
                  <Typography sx={{ marginBottom: "25px", color: "#FFFFFF" }}>
                    The Solar Revolution has just begun. Millions of homes and
                    small businesses are going to solarize over the next few
                    years. SOLRUF is revolutionising this energy transition.
                  </Typography>
                  <Typography sx={{ color: "#FFFFFF" }}>
                    If you are a Professional e.g. an architect, interior
                    designer, wealth manager, CA, etc. or simply someone who has
                    good net-working skills, Partner with SOLRUF and embark on a
                    highly rewarding journey.
                  </Typography>
                </Box>

                <Box>
                  <PrimaryButton
                    sx={{ width: "100%" }}
                    onClick={() =>
                      (window.location.href =
                        "https://links.solruf.com/portfolio/majestic-solar")
                    }
                  >
                    Sample Vendor Portfolio
                  </PrimaryButton>
                </Box>
              </Stack>
            </CardContent>
          </Card>
          {/* Calendly */}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                background: "#ffffff",
                width: "340px",
                px: 2,
              }}
            >
              <Typography variant="h6" sx={{ textAlign: "center", p: 1 }}>
                BOOK A MEETING WITH US
              </Typography>
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/solruf/solruf-installer-platform-onboarding?hide_event_type_details=1&hide_gdpr_banner=1&text_color=4d4d4d&primary_color=ffd05b"
                style={{ minWidth: "320px", height: "480px" }}
              ></div>
            </Box>
          </Box>
          <Card sx={{ width: "350px", borderRadius: "2px" }}>
            <CardContent>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#FFD05B",
                    marginBottom: "35px",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  Sign Up
                </Typography>
                <Stack
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    width: "100%",
                  }}
                  spacing={2}
                  noValidate
                  autoComplete="off"
                >
                  <SolrufTextField
                    label="Full Name"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    error={!!errorMessages.fullName}
                    helperText={errorMessages.fullName}
                    required
                  />
                  <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <SolrufTextField
                      label="Phone Number"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      error={!!errorMessages.phoneNumber}
                      helperText={errorMessages.phoneNumber}
                      required
                    />
                    <SolrufTextField
                      label="Email"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      size="small"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errorMessages.email}
                      helperText={errorMessages.email}
                      required
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <SolrufTextField
                      label="Pincode"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handlePincodeChange}
                      error={!!errorMessages.pincode}
                      helperText={errorMessages.pincode}
                      required
                    />
                    <SolrufTextField
                      label="City"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      error={!!errorMessages.city}
                      helperText={errorMessages.city}
                      required
                    />
                    <SolrufTextField
                      label="State"
                      variant="outlined"
                      sx={{}}
                      size="small"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      error={!!errorMessages.state}
                      helperText={errorMessages.state}
                      required
                    />
                  </Stack>

                  <SolrufTextField
                    label="Address"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    size="small"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    error={!!errorMessages.address}
                    helperText={errorMessages.address}
                    required
                  />
                  <SolrufTextArea
                    minRows={3}
                    maxRows={6}
                    label="Remarks (Optional)"
                    placeholder="Remarks (Optional)"
                    sx={{ width: "100%" }}
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                  />
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: "#2a2a2a",
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <Container>
          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                Let’s Talk Business
              </Typography>
              <Typography sx={{ color: "#2a2a2a", textAlign: "center" }}>
                Solar for Homes is a high value consumer product
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Typography>
                  Average Solar Home System costs between Rs 3-6 lacs and can
                  easily go higher with upgrades and higher sizes. Every time
                  someone from your reference make a purchase with SOLRUF, you
                  get a high % as an earnout.
                </Typography>
                <Typography>
                  But that’s not all. You have many more unique benefits.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "35px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Points>
                    <QueryStatsRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Unlimited Earnings Potential
                    </Typography>
                  </Points>
                  <Points>
                    <WorkspacePremiumRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      No Solar Experience Required
                    </Typography>
                  </Points>
                  <Points>
                    <TrackChangesRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Set your own sales targets based on how much you want to
                      earn
                    </Typography>
                  </Points>
                </Box>
              </Container>
            </Box>
          </Box>

          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                How does it work?
              </Typography>
              <Typography sx={{ color: "#2a2a2a", textAlign: "center" }}>
                Easy, Completely Digital and Transparent Process
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    gap: "35px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Points>
                    <ShareRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Affiliate shares unique QR code or link in their network
                    </Typography>
                  </Points>
                  <Points>
                    <PersonAddAlt1Rounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Customer scans unique QR code/ link and registers easily
                      through mobile phone
                    </Typography>
                  </Points>
                  <Points>
                    <SupportAgentRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      SOLRUF gets in touch with customer and takes care of
                      entire journey
                    </Typography>
                  </Points>
                  <Points>
                    <InventoryRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Buys and makes full payment to SOLRUF
                    </Typography>
                  </Points>
                  <Points>
                    <AccountBalanceRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Affiliate’s ‘earnout’ gets credited to their account
                    </Typography>
                  </Points>
                </Box>
              </Container>
            </Box>
          </Box>

          <Box sx={{ marginBottom: "25px" }}>
            <Box
              sx={{
                backgroundColor: "#FFD05B",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#2a2a2a", textAlign: "center" }}
              >
                How do you start?
              </Typography>
              <Typography sx={{ color: "#2a2a2a", textAlign: "center" }}>
                Easy, Completely Digital and Transparent Process
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    gap: "35px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Points>
                    <HowToRegRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Register with SOLRUF online
                    </Typography>
                  </Points>
                  <Points>
                    <FactCheckRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Complete the on-boarding process
                    </Typography>
                  </Points>
                  <Points>
                    <QrCodeScannerRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Share your unique QR code and SOLRUF collaterals in your
                      network
                    </Typography>
                  </Points>
                  <Points>
                    <MovingRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Begin a rewarding journey
                    </Typography>
                  </Points>
                  <Points>
                    <TroubleshootRounded sx={PointStyle} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        "@media (max-width: 600px)": {
                          textAlign: "center",
                        },
                      }}
                    >
                      Track your leads online through our partner portal
                    </Typography>
                  </Points>
                </Box>
                <Typography>
                  When you partner with SOLRUF, you also become a part of the
                  Smart Home and Sustainability trend that is fast catching up
                  with the Indian homes. If you are interested, we also offer
                  Training and Sales support to our Partners and/ or their
                  teams.
                </Typography>
              </Container>
            </Box>
          </Box>
          <Box sx={{ marginBottom: "25px" }}>
            <Box sx={{ marginBottom: "25px" }}>
              <Box
                sx={{
                  backgroundColor: "#FFD05B",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#2a2a2a", textAlign: "center" }}
                >
                  Why Partner with Solruf?
                </Typography>
                {/* <Typography sx={{ color: "#2a2a2a", textAlign: "center" }}>
                Solar for Homes is a high value consumer product
              </Typography> */}
              </Box>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                }}
              >
                <Container>
                  <Box style={{ marginBottom: "25px" }}>
                    <Typography>
                      At Solruf, we understand the traditional hurdles
                      associated with going solar. We've transformed the
                      procedure to make it simple, clear, and entirely digital
                      because of this.
                    </Typography>
                    <Typography>
                      Solruf is your one-stop shop for everything solar. Our
                      state-of-the-art platform effortlessly integrates:
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      <EngineeringRounded sx={PointStyle} />
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Skilled Engineering:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Whether you're a company owner, a homeowner, or
                          somewhere in between, our team of experts will create
                          a tailored solar system to suit your needs.
                        </span>
                      </Typography>
                    </SecondPoints>
                    <Typography></Typography>
                  </Box>

                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      <ThumbUpAltRounded sx={PointStyle} />
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Digital Efficiency:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Use our digital platform to have a hassle-free,
                          seamless experience. Everything, from the original
                          price to system monitoring, is clear and easily
                          available.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      <PaymentRounded sx={PointStyle} />
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Financing Solutions:{" "}
                        <span style={{ fontWeight: "400" }}>
                          We provide a range of financing choices to enable
                          everyone to afford solar energy.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      <CameraEnhanceRounded sx={PointStyle} />
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Unmatched Quality:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Solruf only employs the best products to guarantee
                          that your system will continue to provide dependable
                          power for many years to come.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <Typography style={{ fontWeight: "700" }}>
                      Solruf caters to a wide range of applications, bringing
                      solar power to:
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      {/* <TroubleshootRounded sx={PointStyle} /> */}
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Homes of all types:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Independent houses, villas, bungalows, farmhouses,
                          penthouses.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      {/* <TroubleshootRounded sx={PointStyle} /> */}
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Outdoor spaces:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Pools, patios, landscaped terraces, and gardens.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box style={{ marginBottom: "25px" }}>
                    <SecondPoints>
                      {/* <TroubleshootRounded sx={PointStyle} /> */}
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        Commercial properties:{" "}
                        <span style={{ fontWeight: "400" }}>
                          Hotels and offices.
                        </span>
                      </Typography>
                    </SecondPoints>
                  </Box>
                  <Box>
                    <Typography style={{ fontWeight: "700" }}>
                      Don't wait any longer. Join the Solruf solar revolution
                      and start saving today!
                    </Typography>
                  </Box>
                </Container>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Wrapper>
  );
};

export default Partner;
