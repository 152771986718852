import {
   Box,
   Chip,

   MenuItem,
   Select,
   Typography,
} from '@mui/material';
import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { axiAuth } from '../../../utils/axiosInstance';
import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
import { toast } from 'react-toastify';

const NbfcManagement = () => {
   const [nbfcUserList, setNbfcUserList] = useState([]);
   const [editMode, setEditMode] = useState(false);
   const [editUserId, setEditUserId] = useState(null);

   const [nbfcs, setNbfcs] = useState([]);

   useEffect(() => {
      if (editMode) return;

      axiAuth('api/admin/nbfc-user')
         .then(({ data, status }) => {
             
            if (status === 200) {
               setNbfcUserList(data?.data);
            }
         })
         .catch((err) => {});
   }, [editMode]);

   useEffect(() => {
      axiAuth('api/nbfcs')
         .then(({ data, status }) => {
             
            if (status === 200) {
               setNbfcs(data?.data);
            }
         })
         .catch((err) => {});
   }, []);

   const editHandler = (userId) => {
       
      setEditMode(true);
      setEditUserId(userId);
   };

   const saveHandler = () => {
      axiAuth
         .post('api/admin/nbfc-user', {
            user_id: editUserId,
            nbfc_id: selectedNbfc,
         })
         .then(({ status }) => {
            if (status === 200) {
               setEditMode(false);
               setEditUserId(null);
            }
         })
         .catch((err) => {
            toast.warn('Something went wrong when adding NBFC');
             
         });
   };

   const [selectedNbfc, setSelectedNbfc] = React.useState('');

   const handleChange = (event) => {
      setSelectedNbfc(event.target.value);
   };
    

   return (
      <Container>
         {nbfcUserList.map((item) => (
            <Box
               key={item.id}
               sx={{ mb: 4, p: 2, borderRadius: 2, boxShadow: 25 }}
            >
               <Box
                  sx={{
                     bgcolor: '#F3F3F3',
                     display: 'flex',
                     alignItems: 'center',
                     borderRadius: 2,
                     gap: 2,
                     p: 2,
                     '& img': {
                        width: '60px',
                        height: 'auto',
                     },
                  }}
               >
                  <img
                     src={
                        item.profile_image
                           ? item.profile_image
                           : 'https://static.vecteezy.com/system/resources/previews/004/991/321/original/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg'
                     }
                     alt={item.first_name}
                  />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <Typography variant='h5'>{item.first_name}</Typography>
                     <Typography variant='h5'>{item.last_name}</Typography>
                  </Box>
                  <Box>
                     <Chip label={`User Id: ${item.id}`} />
                  </Box>
               </Box>
               <Typography variant='h5' sx={{ mt: 2 }}>
                  Nbfcs
               </Typography>
               <Box>
                  {item.nbfc && (
                     <Box
                        sx={{
                           display: 'flex',
                           gap: 2,
                           alignItems: 'center',
                           borderBottom: '1px solid #f3f3f3',
                           '& img': {
                              width: '50px',
                              height: 'auto',
                           },
                           mt: 2,
                        }}
                     >
                        <img src={item.nbfc.logo} alt={item.nbfc.name} />
                        <Typography variant='h6'>{item.nbfc.name}</Typography>
                     </Box>
                  )}

                  {editMode && editUserId === item.id && (
                     <Box sx={{ mt: 2 }}>
                        <Typography id='demo-simple-select-label' variant='h6'>
                           Select NBFC
                        </Typography>
                        <Select
                           labelId='demo-simple-select-label'
                           id='demo-simple-select'
                           value={selectedNbfc}
                           label='Age'
                           onChange={handleChange}
                           sx={{
                              minWidth: '250px',
                           }}
                        >
                           {nbfcs.map(({ id, name }) => (
                              <MenuItem value={id}>{name}</MenuItem>
                           ))}
                           <MenuItem value={10}>Ten</MenuItem>
                           <MenuItem value={20}>Twenty</MenuItem>
                           <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                     </Box>
                  )}

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                     <PrimaryButton
                        variant='contained'
                        onClick={() =>
                           editMode ? saveHandler() : editHandler(item.id)
                        }
                        sx={{ mt: 2 }}
                     >
                        {editMode && editUserId === item.id ? 'Save' : 'Edit'}
                     </PrimaryButton>
                     {editMode && (
                        <PrimaryButton
                           variant='contained'
                           onClick={() => setEditMode(false)}
                           sx={{ mt: 2 }}
                        >
                           Cancel
                        </PrimaryButton>
                     )}
                  </Box>
               </Box>
            </Box>
         ))}
      </Container>
   );
};

export default NbfcManagement;
