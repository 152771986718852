import { Box, Button, Checkbox, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SolrufTextField from "../../components/SolrufTextField/SolrufTextField";
import SendIcon from "@mui/icons-material/Send";
// import PrimaryButton from '../Custom/PrimaryButton/PrimaryButton';
// import SolrufModal3 from '../Custom/SolrufModal/SolrufModal3';
// import sunSvg from '../../assets/sun.svg';
import siSvg from "../../assets/solar-installation.svg";
import smSvg from "../../assets/solar-maintenance.svg";
import spSvg from "../../assets/solar-products.svg";
import otherSvg from "../../assets/other.svg";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import Lottie from "lottie-react";
import sent from "../../assets/lottie/sent.json";
import solarHouse from "../../assets/lottie/solar-house.json";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { HashLink } from "react-router-hash-link";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import {
  EnquiryMSItem,
  Background,
  FormWrapper,
  LeadFormWrapper,
  RowWrapper,
  SendWrapper,
  ThanksWrapper,
  Wrapper,
} from "../../components/HomeLeadForm/HomeLeadForm.style";
import { FormFeaturesWrapper } from "../home/home.style";
import OfferForm from "../OfferForm/OfferForm";
import Features from "../../components/Features/Features";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { withStyles } from "@mui/styles";
import { sendWhatsappNotification } from "../../utils/sendWhatsappNotification";
import saveFormDataToGoogleSheet from "../../utils/saveFormDataToSheet";
import { getTimeStamp } from "../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import TagManager from "react-gtm-module";

// const tagManagerArgs = {
//   gtmId: "AW-351377092", // Replace with your GTM ID
// };

// TagManager.initialize(tagManagerArgs);

const StyledCheckbox = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      borderRadius: "50%",
    },
  },
})((props) => (
  <Checkbox
    disableRipple
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<RadioButtonCheckedIcon />}
    {...props}
  />
));

// import { ActionsWrapper } from "../CustomerDetailsDrawer/GetAQuoteStyle";

const SolarLeadForm = ({ ...rest }) => {
  const navigate = useNavigate();

  //
  const [pageIdx, setPageIdx] = useState(1);
  // const [siOn, setSiOn] = useState(false);
  // const [smOn, setSmOn] = useState(false);
  // const [spOn, setSpOn] = useState(false);

  const [service, setService] = useState("n/a");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [whatsappChecked, setWhatsappChecked] = useState(false);

  const handleWhatsappChecked = (event) => {
    setWhatsappChecked(event.target.checked);
  };

  function gtag_report_conversion(url) {
    const callback = () => {
      if (typeof url !== undefined) {
        window.location = url;
        // navigate(`/thankyou`);
      }
    };

    // Pushing the event to the dataLayer using react-gtm-module
    TagManager.dataLayer({
      dataLayer: {
        event: "conversion",
        send_to: "AW-16491038921/N_0gCNew67kZEMmRxbc9",
        sendTo: "AW-16491038921/N_0gCNew67kZEMmRxbc9",
        event_callback: callback,
        eventCallback: callback,
      },
    });

    return false;
  }

  const handleSendEnquiry = async (formData) => {
    //  setPageIdx(3);
    //  setTimeout(() => {
    //    setPageIdx(1);
    //  }, 4000);

    const data = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
      pincode: formData.pincode,
      remarks: formData.remarks,
      enquiry_type: service,
      timestamp: getTimeStamp(),
    };

    console.log(data);

    try {
      const { status } = await saveFormDataToGoogleSheet(
        data,
        "https://script.google.com/macros/s/AKfycbyM6nO2kzZcKMwdfSVmK-zDJeo7vS9tg5_wJGu7mvCW56XcVujrAHE66rEegUgDJwHqsw/exec"
      );

      if (status === 200) {
        //   setPageIdx(3);
        // sendWhatsappNotification(
        //   data.name,
        //   formData.phone,

        //   "blog_enquiry_forms_regard",
        //   [
        //     data.name,
        //     `your enquiry on ${service}`,
        //     "into the solar installation process",
        //     "https://www.solruf.com/blogs/solarInstallationProcess",
        //     "+91-8600694140",
        //     "sumit@solruf.com",
        //   ],
        //   {
        //     url: "https://i.ibb.co/SccD90M/enquiry-forms-regard.jpg",
        //     filename: "test",
        //   }
        // );
        // setTimeout(() => {
        //    setPageIdx(1);

        // }, 4000);

        const baseUrl =
          window.location.hostname === "localhost"
            ? "http://localhost:3000"
            : "https://www.solruf.com";

        gtag_report_conversion(`${baseUrl}/thankyou`);

        // navigate(`/thankyou`);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const service = searchParams.get("service");
    if (service === "solar_installation") {
      setService("solar installation");
      setPageIdx(2);
    }
  }, [location.search]);

  const pages = [
    <Wrapper>
      <Background>
        <Box
          sx={{
            width: "156px",
            height: "156px",
            background: "#FFF2D1",
            borderRadius: "156px",
            position: "absolute",
            top: "-3px",
            left: "-70px",
            zIndex: "-1",
          }}
        />

        <LeadFormWrapper>
          {/* aba */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              rowGap: "1.5rem",
            }}
          >
            <Lottie
              animationData={solarHouse}
              loop={true}
              style={{ height: "14rem", transform: "scale(2.0)" }}
            />
            <PrimaryButton
              sx={{
                width: "100%",
                maxWidth: "30rem",
                py: 1.5,
                fontSize: "1rem",
                color: "#4d4d4d",
                backgroundColor: "primary.main",
                "&:hover": {
                  color: "#4d4d4d",
                  backgroundColor: "primary.main",
                },
                fontWeight: "600",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setPageIdx(1);
              }}
            >
              Send us an Enquiry about our Services <ChevronRightIcon />
            </PrimaryButton>
            <Typography
              variant="h5"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "1.2rem",
                },
              }}
              fontWeight={400}
            >
              or
            </Typography>
            <PrimaryButton
              component={HashLink}
              to="/#offer-form"
              sx={{
                width: "100%",
                maxWidth: "30rem",
                py: 1.5,
                fontSize: "1rem",
                background: "#4d4d4d",
                color: "primary.main",
                "&:hover": {
                  background: "#4d4d4d",
                  color: "primary.main",
                },
                fontWeight: "600",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
              // onClick={() => {
              //    rest.onClose();
              // }}
            >
              Book a Solar Site Visit
              <ChevronRightIcon />
            </PrimaryButton>
          </Box>
        </LeadFormWrapper>
      </Background>
    </Wrapper>,
    <Wrapper>
      <Background>
        <Box
          sx={{
            width: "156px",
            height: "156px",
            background: "#FFF2D1",
            borderRadius: "156px",
            position: "absolute",
            top: "-3px",
            left: "-70px",
            zIndex: "-1",
          }}
        />

        <LeadFormWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              rowGap: "1.5rem",
              "@media (max-width: 600px)": {
                rowGap: "0.4rem",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "1.2rem",
                },
                mb: 2,
              }}
              fontWeight={500}
              textAlign="center"
            >
              What services are you looking for?
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                rowGap: "1.5rem",
                columnGap: "1.5rem",
                "@media (max-width: 600px)": {
                  rowGap: "1.5rem",
                  columnGap: "2.5rem",
                },

                flexWrap: "wrap",
              }}
            >
              <EnquiryMSItem
                component={motion.div}
                whileHover={{
                  scale: 0.9,
                }}
                sx={{
                  border: "1px solid #D8D8D8",
                  "-webkit-backdrop-filter": "blur(10px)",
                  "backdrop-filter": "blur(10px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    zIndex: "10",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setService("solar installation");
                    setPageIdx(2);
                  }}
                />
                <img
                  src={siSvg}
                  alt=""
                  style={{
                    height: "5rem",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                  }}
                >
                  SOLAR INSTALLATION
                </Typography>
              </EnquiryMSItem>
              <EnquiryMSItem
                component={motion.div}
                whileHover={{
                  scale: 0.9,
                }}
                sx={{
                  border: "1px solid #D8D8D8",
                  background: "transparent",
                  "-webkit-backdrop-filter": "blur(10px)",
                  "backdrop-filter": "blur(10px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    zIndex: "10",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setService("solar maintenance");
                    setPageIdx(2);
                  }}
                />
                <img
                  src={smSvg}
                  alt=""
                  style={{
                    height: "5rem",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                  }}
                >
                  SOLAR MAINTENANCE
                </Typography>
              </EnquiryMSItem>
              <EnquiryMSItem
                component={motion.div}
                whileHover={{
                  scale: 0.9,
                }}
                sx={{
                  border: "1px solid #D8D8D8",
                  background: "transparent",
                  "-webkit-backdrop-filter": "blur(10px)",
                  "backdrop-filter": "blur(10px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    zIndex: "10",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setService("solar products");
                    setPageIdx(2);
                  }}
                />
                <img
                  src={spSvg}
                  alt=""
                  style={{
                    height: "5rem",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                  }}
                >
                  SOLAR PRODUCTS
                </Typography>
              </EnquiryMSItem>
              <EnquiryMSItem
                component={motion.div}
                whileHover={{
                  scale: 0.9,
                }}
                sx={{
                  border: "1px solid #D8D8D8",
                  background: "transparent",
                  "-webkit-backdrop-filter": "blur(10px)",
                  "backdrop-filter": "blur(10px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    zIndex: "10",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setService("solar financing");
                    setPageIdx(2);
                  }}
                />
                <img
                  src={spSvg}
                  alt=""
                  style={{
                    height: "5rem",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                  }}
                >
                  SOLAR FINANCING
                </Typography>
              </EnquiryMSItem>
              <EnquiryMSItem
                component={motion.div}
                whileHover={{
                  scale: 0.9,
                }}
                sx={{
                  border: "1px solid #D8D8D8",
                  background: "transparent",
                  "-webkit-backdrop-filter": "blur(10px)",
                  "backdrop-filter": "blur(10px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                  component={Link}
                  to="/partner-epc"
                  // onClick={() => {
                  //    rest.onClose();
                  // }}
                />
                <img
                  src={otherSvg}
                  alt=""
                  style={{
                    height: "5rem",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "0.9rem",
                  }}
                  component="h2"
                >
                  Become a Channel Partner with us
                </Typography>
              </EnquiryMSItem>
            </Box>
          </Box>
        </LeadFormWrapper>
      </Background>
    </Wrapper>,
    <Wrapper>
      <Background>
        <LeadFormWrapper
          component="form"
          onSubmit={handleSubmit(handleSendEnquiry)}
        >
          <RowWrapper sx={{ paddingBottom: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                rowGap: "0.5rem",
                padding: "1rem",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Button
                  sx={{ padding: "0", color: "#4d4d4d" }}
                  onClick={() => {
                    setPageIdx(1);
                  }}
                >
                  <ChevronLeftIcon /> Go Back
                </Button>
              </Box>

              <Typography variant="h4" fontWeight={600}>
                Please tell us your enquiry.
              </Typography>
              <Typography variant="h5" fontWeight={400}>
                We will be get back to you shortly.
              </Typography>

              {/* <PrimaryButton
                     sx={{
                        maxWidth: '150px',
                     }}
                     IconEnd={LaunchIcon}
                     onClick={() => {
                      navigate('/solar-pump-form')
                     }}
                  >
                     Lead Form
                  </PrimaryButton> */}
            </Box>
            <FormWrapper>
              <SolrufTextField
                size="large"
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Your Name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Name is required.",
                  },
                })}
                error={errors.name}
                helperText={errors.name ? errors.name.message : ""}
              />
              <SolrufTextField
                size="large"
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Your Phone"
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Phone Number  is required.",
                  },
                  minLength: {
                    value: 10,
                    message:
                      "Phone Number must be at least 10 characters long.",
                  },
                  maxLength: {
                    value: 13,
                    message: "Phone Number too long.",
                  },
                })}
                error={errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
              />
              <SolrufTextField
                size="large"
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Your Email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email  is required.",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
              <SolrufTextField
                size="large"
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Your Address"
                {...register("address", {
                  required: {
                    value: true,
                    message: "Address  is required.",
                  },
                })}
                error={errors.address}
                helperText={errors.address ? errors.address.message : ""}
              />
              <SolrufTextField
                size="large"
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Your Pincode"
                {...register("pincode", {
                  required: {
                    value: true,
                    message: "Pincode  is required.",
                  },
                })}
                error={errors.pincode}
                helperText={errors.pincode ? errors.pincode.message : ""}
              />
              <SolrufTextField
                size="large"
                multiline
                rows={3}
                sx={{
                  appearance: "none",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    "-webkit-backdrop-filter": "blur(10px)",
                    "backdrop-filter": "blur(10px)",
                    background: "rgba(255, 255, 255,0.2)",
                  },
                }}
                label="Remarks (Optional)"
                {...register("remarks")}
                // error={errors.query}
                // helperText={errors.query ? errors.query.message : ""}
              />

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <StyledCheckbox
                  label="Label"
                  defaultChecked
                  checked={whatsappChecked}
                  onChange={handleWhatsappChecked}
                  sx={{}}
                />
                <Typography variant="subtitle1">
                  Opt in for Solar Products Whatsapp Updates
                </Typography>
              </Box> */}
              <PrimaryButton
                type="submit"
                sx={{
                  width: "100%",
                  py: 1.5,
                  fontSize: "1rem",
                  background: "#4d4d4d",
                  color: "#FFD05B",
                  "&:hover": {
                    background: "#000000",
                  },

                  "&:disabled": {
                    background: "rgba(255, 208, 93, 0.5)",
                    color: "#4d4d4d",
                  },

                  fontWeight: "600",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  mt: "1.5rem",
                }}

                // disabled={isSubmitting}
              >
                Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
              </PrimaryButton>
            </FormWrapper>
            {/* <SendWrapper
              sx={{
                alignItems: "space-between",
              }}
            >
              <Lottie
                animationData={solarHouse}
                loop={true}
                style={{ height: "14rem", transform: "scale(2.2)" }}
              />
              <PrimaryButton
                type="submit"
                sx={{
                  width: "100%",
                  py: 1.5,
                  fontSize: "1rem",
                  background: "#4d4d4d",
                  color: "#FFD05B",
                  "&:hover": {
                    background: "#000000",
                  },

                  "&:disabled": {
                    background: "rgba(255, 208, 93, 0.5)",
                    color: "#4d4d4d",
                  },

                  fontWeight: "600",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  mt: "1.5rem",
                }}

                // disabled={isSubmitting}
              >
                Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
              </PrimaryButton>
            </SendWrapper> */}
          </RowWrapper>
          <Box
            sx={{
              display: "none",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              columnGap: "1.5rem",
              "@media (max-width: 600px)": {
                display: "flex",
              },
            }}
          >
            <PrimaryButton
              type="submit"
              sx={{
                width: "100%",

                py: 1.5,
                fontSize: "1rem",
                background: "#000000",
                color: "#FFD05B",
                "&:hover": {
                  background: "#000000",
                },
                fontWeight: "600",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Send Enquiry <SendIcon sx={{ marginLeft: "0.5rem" }} />
            </PrimaryButton>
          </Box>
        </LeadFormWrapper>
      </Background>
    </Wrapper>,
    <Wrapper>
      <Background>
        <ThanksWrapper>
          <Lottie
            animationData={sent}
            loop={true}
            style={{ height: "20rem", marginBottom: "4rem" }}
          />
          <Typography
            variant="h3"
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "1.3rem",
              },
            }}
            fontWeight={600}
          >
            Thanks for submitting
          </Typography>
          <Typography
            variant="h5"
            fontWeight={400}
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "1rem",
              },
            }}
          >
            You will be hearing from us soon!
          </Typography>
        </ThanksWrapper>
      </Background>
    </Wrapper>,
  ];

  return (
    <Container>
      <Box
        sx={{
          mb: 10,
          mt: 5,
        }}
      >
        {pages[pageIdx]}
        <FormFeaturesWrapper sx={{ mt: 2 }}>
          {/* <OfferForm /> */}
          <Features />
        </FormFeaturesWrapper>
      </Box>
    </Container>
  );
};

export default SolarLeadForm;
