import { Box, Checkbox, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
   ContactDetails,
   ContactUsWrapper,
   Title,
   SocialIcons,
   LinkListWithIcon,
   ContactList,
   ContactForm,
   FormLabel,
   FormField,
} from './ContactUsStyle';
import SolrufTextField from '../SolrufTextField/SolrufTextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../Custom/PrimaryButton/PrimaryButton';
import YouTubeIcon from '@mui/icons-material/YouTube';

import FacebookIcon from '@mui/icons-material/Facebook';
import axios from 'axios';
import { toast } from 'react-toastify';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { withStyles } from '@mui/styles';
import { sendWhatsappNotification } from '../../utils/sendWhatsappNotification';
import saveFormDataToGoogleSheet from '../../utils/saveFormDataToSheet';
import { getTimeStamp } from '../../utils/utils';

const StyledCheckbox = withStyles({
   root: {
      '& .MuiSvgIcon-root': {
         borderRadius: '50%',
      },
   },
})((props) => (
   <Checkbox
      disableRipple
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<RadioButtonCheckedIcon />}
      {...props}
   />
));

const ContactUs = () => {
   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm();

   const [subscribe, setSubscribe] = useState(true);

   const submitHandler = async (formData, e) => {
      try {
         const { status } = await saveFormDataToGoogleSheet(
            {
               ...formData,
               whatsapp_subscribe: subscribe ? 'yes' : 'no',
               timestamp: getTimeStamp(),
            },
            'https://script.google.com/macros/s/AKfycbye7PW_pvhal5qiXCERs8ZV1JnQKYO7D6rcHJfKeerPCbhvAeMJaEW6nu116iEgcEZPnA/exec'
         );

         if (status === 200) {
             sendWhatsappNotification(
               formData.name,
               formData.phone,
               'contact_us_regard',
               [formData.name, '+91-8600694140', 'sumit@solruf.com'],
               {
                  url: 'https://i.ibb.co/KG80Vjg/contact-us-regard.jpg',
                  filename: 'test',
               }
            );
         }

         await axios.post('https://api-dev.solruf.com/api/contact-us', {
            ...formData,
            subscribe: subscribe ? 'yes' : 'no',
         });

         toast.success('Your Message was sent!');
      } catch (err) {}

      reset();
      setSubscribe(true);
   };

   return (
      <Box
         sx={{
            backgroundImage: `url(${'https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/home/contactUsBg.svg'})`,
            backgroundSize: 'cover',
            py: '3rem',
         }}
      >
         <Box
            sx={{
               position: 'absolute',
               transform: 'translateY(-8rem)',
               '@media (max-width: 600px)': {
                  transform: 'translateY(-10rem)',
               },
            }}
            id='contact-us'
         />
         <Container maxWidth='xl'>
            <ContactUsWrapper>
               <ContactDetails>
                  <Title>
                     <Typography
                        variant='h3'
                        sx={{ fontWeight: '600', color: 'white' }}
                     >
                        Let's Start a Conversation <br />
                        <span
                           style={{
                              color: '#FFC000',
                              fontWeight: '600',
                              fontSize: '1.5rem',
                           }}
                        >
                           Ask us anything!
                        </span>
                     </Typography>
                     <Typography
                        variant='p'
                        sx={{
                           color: 'white',
                           fontSize: '1.2rem',
                           '@media (max-width: 600px)': {
                              fontSize: '1rem',
                           },
                           // maxWidth: '300px'
                        }}
                     >
                        Fill up the form and our Team will get back to you in 24
                        hours
                     </Typography>
                  </Title>

                  <ContactList>
                     <LinkListWithIcon
                        component='a'
                        href='tel:+918600694140'
                        // dataAction="share/whatsapp/share"
                        sx={{ my: 1.5 }}
                     >
                        <PhoneEnabledIcon
                           sx={{
                              color: '#ffd05b',
                              mr: 1,
                              transform: 'rotate(90deg)',
                              fontSize: '2rem',
                           }}
                        />
                        <span>+91-8600694140</span>
                     </LinkListWithIcon>
                     <LinkListWithIcon
                        component='a'
                        href='mailto:sumit@solruf.com'
                     >
                        <EmailIcon
                           sx={{ color: '#ffd05b', mr: 1, fontSize: '2rem' }}
                        />
                        <span>sumit@solruf.com</span>
                     </LinkListWithIcon>
                     <LinkListWithIcon component={Link} to='/'>
                        <LocationOnIcon
                           sx={{ color: '#ffd05b', mr: 1, fontSize: '2rem' }}
                        />
                        <span style={{ maxWidth: '550px' }}>
                           Agarwal Bunglaw, Behind Atul Mangal Karyalaya,
                           Rukmini Nagar, Amravati, Maharashtra, India, 444606
                        </span>
                     </LinkListWithIcon>
                  </ContactList>
                  <SocialIcons>
                     <a
                        href='https://www.facebook.com/SolrufIndia'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <FacebookIcon
                           sx={{
                              fontSize: '3rem',
                              '@media (max-width: 600px)': {
                                 fontSize: '2rem',
                              },
                           }}
                        />
                     </a>
                     <a
                        href='https://www.linkedin.com/company/solruf/'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <LinkedInIcon
                           sx={{
                              fontSize: '3rem',
                              '@media (max-width: 600px)': {
                                 fontSize: '2rem',
                              },
                           }}
                        />
                     </a>
                     <a
                        href='https://www.instagram.com/solrufco/'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <InstagramIcon
                           sx={{
                              fontSize: '3rem',
                              '@media (max-width: 600px)': {
                                 fontSize: '2rem',
                              },
                           }}
                        />
                     </a>
                     <a
                        href='https://www.youtube.com/channel/UCwQlCuStfqD7eP_rxAsYs6w'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <YouTubeIcon
                           sx={{
                              fontSize: '3rem',
                              '@media (max-width: 600px)': {
                                 fontSize: '2rem',
                              },
                           }}
                        />
                     </a>
                  </SocialIcons>
               </ContactDetails>
               <ContactForm
                  component='form'
                  onSubmit={handleSubmit(submitHandler)}
               >
                  <FormField>
                     <FormLabel>Name</FormLabel>
                     <SolrufTextField
                        size='small'
                        label='Name'
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              background: '#ffffff',
                           },
                        }}
                        {...register('name', {
                           required: {
                              value: true,
                              message: 'Name is required',
                           },
                        })}
                        error={errors?.name}
                        helperText={errors.name && errors.name.message}
                     />
                  </FormField>
                  <FormField>
                     <FormLabel>Phone Number</FormLabel>
                     <SolrufTextField
                        size='small'
                        label='Phone Number'
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              background: '#ffffff',
                           },
                        }}
                        {...register('phone', {
                           required: {
                              value: true,
                              message: 'Phone Number is required',
                           },
                        })}
                        error={errors?.name}
                        helperText={errors.name && errors.name.message}
                     />
                  </FormField>
                  <FormField>
                     <FormLabel>Email (Optional)</FormLabel>
                     <SolrufTextField
                        size='small'
                        label='Email'
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              background: '#ffffff',
                           },
                        }}
                        {...register('email')}
                        error={errors?.name}
                        helperText={errors.name && errors.name.message}
                     />
                  </FormField>
                  <FormField>
                     <FormLabel>Message</FormLabel>
                     <SolrufTextField
                        size='small'
                        label='Message'
                        multiline
                        rows={4}
                        sx={{
                           '& .MuiOutlinedInput-root': {
                              background: '#ffffff',
                           },
                        }}
                        {...register('message', {
                           required: {
                              value: true,
                              message: 'Message is required',
                           },
                        })}
                        error={errors?.name}
                        helperText={errors.name && errors.name.message}
                     />
                  </FormField>
                  {/* <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSubscribe(!subscribe);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={subscribe} />}
                label="Would you like to hear more about our platform from us"
                onClick={() => {
                  setSubscribe(!subscribe);
                }}
              />
            </Box> */}

                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 1,
                     }}
                  >
                     <StyledCheckbox
                        label='Label'
                        defaultChecked
                        checked={subscribe}
                        onChange={() => {
                           setSubscribe(!subscribe);
                        }}
                        sx={{}}
                     />
                     <Typography variant='subtitle1'>
                        Opt in for Solar Products Whatsapp Updates
                     </Typography>
                  </Box>
                  <PrimaryButton
                     sx={{
                        py: 1.5,
                        width: '100%',
                     }}
                     variant='primary'
                     type='submit'
                  >
                     Submit
                  </PrimaryButton>
               </ContactForm>
            </ContactUsWrapper>
         </Container>
      </Box>
   );
};

export default ContactUs;
