import React from 'react';
import {
   Box,
   Button,
   Typography,
   Stack,
   Container,
   styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import EastIcon from '@mui/icons-material/East';

/*
https://i.ibb.co/T0MqgKk/Group-5.png
https://i.ibb.co/BrGWRNh/Group-4.png
https://i.ibb.co/jRg9r0X/Group-3.png
*/

const FeatureBox = styled(Box)(({ theme }) => ({
   flex: 1,
   maxWidth: '250px',
   '& img': {
      display: 'block',
      mx: 'auto',
      maxWidth: '100%',
      height: '100%'
   },
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',

   [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
      '& h2': {
         fontSize: '12px',
      },
   },
}));

const KnowMoreButton = styled(Button)(({ theme }) => ({
   border: 'none',
   color: '#7e7c7c',
   '&:hover': {
      border: 'none',
      color: theme.palette.primary.main,
   },
   [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
   },
}));

const HowWorksSection = () => {
   return (
      <Container>
         <Stack
            direction='row'
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{
               justifyContent: 'space-around',
               alignItems: 'center',
               background: '#ffffff',
               boxShadow: 25,
               py: 3,
               px: [2,5],
               borderRadius: [5, 30],
               mt: -12,
               mb: 10,
               mx: [0, 0, 15],
            }}
         >
            <FeatureBox component={Link} to='/solar-enquiry-form'>
               <Box sx={{ mb: 1, height: [70, 'auto'] }}>
                  <img
                     src='https://i.ibb.co/xjZqwwW/Group-2.png'
                     alt='another logo'
                  />
               </Box>
               <Typography
                  component='h2'
                  variant='h6'
                  fontWeight={600}
                  textAlign='center'
               >
                  Solar Financing
               </Typography>
               <KnowMoreButton
                  variant='outlined'
                  endIcon={<EastIcon sx={{ width: '15px' }} />}
               >
                  Know More
               </KnowMoreButton>
            </FeatureBox>
            <Divider orientation='vertical' flexItem />
            <FeatureBox component={Link} to='/solar-enquiry-form'>
               <Box sx={{ mb: 1, height: [70, 'auto'] }}>
                  <img
                     src='https://i.ibb.co/QdNB0dp/Group.png'
                     alt='another logo'
                  />
               </Box>
               <Typography
                  component='h2'
                  variant='h6'
                  fontWeight={600}
                  textAlign='center'
               >
                  Solar Consultations
               </Typography>
               <KnowMoreButton
                  variant='outlined'
                  endIcon={<EastIcon sx={{ width: '15px' }} />}
               >
                  Know More
               </KnowMoreButton>
            </FeatureBox>
            <Divider orientation='vertical' flexItem />
            <FeatureBox component={Link} to='/products'>
               <Box sx={{ mb: 1, height: [70, 'auto'] }}>
                  <img
                     src='https://i.ibb.co/SQwDP8q/Group-1.png'
                     alt='another logo'
                  />
               </Box>
               <Typography
                  component='h2'
                  variant='h6'
                  fontWeight={600}
                  textAlign='center'
               >
                  Procurement
               </Typography>
               <KnowMoreButton
                  variant='outlined'
                  endIcon={<EastIcon sx={{ width: '15px' }} />}
               >
                  Know More
               </KnowMoreButton>
            </FeatureBox>
         </Stack>
      </Container>
   );
};

// const HowWorksSection = () => {
//    return (
//       <Container>
//          <Stack
//             direction={{ xs: 'column', md: 'row' }}
//             spacing={{ xs: 1, sm: 2, md: 4 }}
//             sx={{
//                justifyContent: 'space-between',
//                alignItems: 'center',
//                background: '#ffffff',
//                boxShadow: 25,
//                py: 12,
//                px: [3,12],
//                borderRadius: 5,
//                mt: -10,
//                mb: 10,
//             }}
//          >
//             <Box
//                sx={{
//                   maxWidth: '300px',
//                   '& img': {
//                      display: 'block',
//                      mx: 'auto',
//                   },
//                }}
//             >
//                <Box sx={{ mb: 3 }}>
//                   <img
//                      src='https://i.ibb.co/TvjwVTd/Group.png'
//                      alt='another logo'
//                   />
//                </Box>
//                <Typography variant='h6' fontWeight={600} textAlign='center'>
//                   National solar subsidy
//                </Typography>
//                <Typography variant='body' textAlign='justify' fontWeight={300}>
//                   Explore the benefits of the National Solar Subsidy, a
//                   groundbreaking initiative supporting sustainable energy
//                   adoption across India by providing financial incentives for
//                   solar installations."
//                </Typography>
//             </Box>
//             <Box
//                sx={{
//                   maxWidth: '300px',
//                   '& img': {
//                      display: 'block',
//                      mx: 'auto',
//                   },
//                }}
//             >
//                <Box sx={{ mb: 3 }}>
//                   <img
//                      src='https://i.ibb.co/SJGxr1p/Page-1.png'
//                      alt='another logo'
//                   />
//                </Box>
//                <Typography variant='h6' fontWeight={600} textAlign='center'>
//                   EMI approved 4-10yrs
//                </Typography>
//                <Typography variant='body' textAlign='justify' fontWeight={300}>
//                   "Discover flexible EMI options spanning 4 to 10 years,
//                   approved for hassle-free financing, making solar power
//                   accessible to all and empowering individuals and businesses to
//                   embrace renewable energy solutions nationwide."
//                </Typography>
//             </Box>
//             <Box
//                sx={{
//                   maxWidth: '300px',
//                   '& img': {
//                      display: 'block',
//                      mx: 'auto',
//                   },
//                }}
//             >
//                <Box sx={{ mb: 3 }}>
//                   <img
//                      src='https://i.ibb.co/mXddvz8/Page-1-1.png'
//                      alt='another logo'
//                   />
//                </Box>
//                <Typography variant='h6' fontWeight={600} textAlign='center'>
//                PAN india Solar installation
//                </Typography>
//                <Typography variant='body' textAlign='justify' fontWeight={300}>
//                "Experience seamless Pan-India Solar Installation services, ensuring widespread access to clean energy solutions and promoting sustainable development across diverse regions of the country."
//                </Typography>
//             </Box>
//          </Stack>
//       </Container>
//    );
// };

export default HowWorksSection;
