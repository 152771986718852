import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Typography, Button, Box } from '@mui/material';
const CouponAppliedCard = ({ couponInfo, couponRemoveHandler, sx }) => {
   return (
      <Box
         sx={{
            padding: '0rem 3rem 1rem 3rem',
            ...sx
         }}
      >
         <Stack
            direction='row'
            gap={2}
            justifyContent='space-between'
            sx={{
               bgcolor: 'secondary.lightYellow',
               p: 1,
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: [1, 2],
                  '& svg': {
                     color: 'green',
                  },
               }}
            >
               <CheckCircleIcon />
               <Typography>Coupon Applied</Typography>
               <Typography
                  sx={{
                     border: '2px dashed #3fb500',
                     px: 1,
                  }}
               >
                  {couponInfo?.title}
               </Typography>
            </Box>
            <Button
               sx={{
                  color: 'primary.error',
               }}
               onClick={couponRemoveHandler}
            >
               Remove
            </Button>
         </Stack>
      </Box>
   );
};

export default CouponAppliedCard;
