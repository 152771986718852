import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Pagination,
  Radio,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SolrufTextField from "../SolrufTextField/SolrufTextField";
import QuoteAccordion from "../CustomerDetailsDrawer/QuoteAccordion";
import {
  ActionsWrapper,
  ContentWrapper,
  PriceRowWrapper,
  ProductsWrapper,
  RowWrapper,
  TotalContentWrapper,
  Wrapper,
} from "./QuotationServiceStyle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { WrapWrapper } from "./QuotationServiceStyle";
import { ColumnWrapper } from "./QuotationServiceStyle";
import AddIcon from "@mui/icons-material/Add";
import { ProductsContentWrapper } from "./QuotationServiceStyle";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Template1 from "../../pages/Templates/Template1";
import Template4 from "../../pages/Templates/Template4";
import Template3 from "../../pages/Templates/Template3";
import Template2 from "../../pages/Templates/Template2";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LightTextField from "../Custom/LightTextField/LightTextField";
import TotalAccordion from "../CustomerDetailsDrawer/TotalAccordion";
import DeleteIcon from "@mui/icons-material/Delete";
import { useReactToPrint } from "react-to-print";
import PrimaryButton from "../Custom/PrimaryButton/PrimaryButton";
import { axiAuth } from "../../utils/axiosInstance";
import {
  Cards,
  DashboardCard,
} from "../../pages/MyDashboard/myDashboard.style";
import Loader from "../Loader/Loader";
import ProductDetailList from "../ProductDetailList/ProductDetailList";
import SearchBox from "../SearchBox/SearchBox";
import useCategories from "../../hooks/useCategories";
import {
  ProductWrapper,
  SearchDataWrapper,
} from "../PopupSearchField/PopupSearchField.style";
import { useTheme } from "@emotion/react";

export const Table = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  border: "1px solid #e0e0e0",
  width: "100%",
  "& thead": {
    backgroundColor: "#f5f5f5",
    "& tr": {
      height: 56,
    },
  },
  "& tbody": {
    "& tr": {
      textAlign: "center",
      height: 48,

      "&:nth-of-type(odd)": {
        backgroundColor: "#ffffff",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#f5f5f5",
      },
      "& td": {
        padding: "0.5rem",
        border: "1px solid #e0e0e0",
      },
    },
  },
}));

export const ProductListWrapper = styled(Box)(({ theme }) => ({}));

export const Flex = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const SolrufTextFieldGray = styled(SolrufTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    // background: '#f3f3f3',
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: "3px",
    },
    "&:hover fieldset": {
      borderWidth: "3px",
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderWidth: "3px",
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const InfoBar = styled(Box)(({ theme }) => ({
  // borderBottom: `3px solid ${theme.palette.primary.main}`,
  padding: ".7rem",
  background: theme.palette.secondary.lightYellow,
  borderRadius: "5px",
}));

export const WareeQuotation = ({
  customerId,
  quotationToBeEdited,
  agreementDownloadHandlerWithQuotation,
  portfolioData,
}) => {
  console.log(portfolioData);
  const [productPriceError, setProductPriceError] = useState(null);
  const [invoiceType, setInvoiceType] = useState("TAX_INVOICE");
  const [template, setTemplate] = useState("temp1");
  const template1Ref = useRef(null);
  const template2Ref = useRef(null);
  const template3Ref = useRef(null);
  const template4Ref = useRef(null);

  // start product search
  const isFirstRenderSearchTerm = useRef(true);
  const [searchTerm, setSearchTerm] = useState("");
  const searchTermChangeHandler = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    console.log(term);

    // await searchHandler(e);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Don't run on the initial render
    if (isFirstRenderSearchTerm.current) {
      isFirstRenderSearchTerm.current = false; // Set it to false after the first render
      return;
    }

    // console.log(selectedCategory, selectedSubCategory);
    searchHandler(searchTerm);
  }, [searchTerm]);

  const { categories } = useCategories("product", null);
  const [subCategories, setSubCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  useEffect(() => {
    if (selectedCategory) {
      axiAuth
        .get(`api/categories?parent=${selectedCategory}`)
        .then((res) => {
          if (res.status === 200) {
            setSubCategories(res.data?.categories);
          }
        })
        .catch((err) => {});
    }
  }, [selectedCategory]);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductLoading] = useState(true);
  const [productsError, setProductsError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductLoading, setSelectedProductLoading] = useState(false);
  const [page, setPage] = useState(1);
  const formRef = useRef(null);

  const [activePage, setActivePage] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => refToPrint().current,
    documentTitle: "Quotation",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, setErrors },
  } = useForm({
    defaultValues: {
      ...quotationToBeEdited,
    },
  });
  const [emiChecked, setEmiChecked] = React.useState(false);

  const [emiData, setEmiData] = useState({
    rate: "",
    tenure: "",
    downPayment: "",
  });

  const [emiType, setEmiType] = useState(null);

  const termsAndConditions =
    quotationToBeEdited && quotationToBeEdited.terms_conditions
      ? quotationToBeEdited.terms_conditions.map((tc, i) => {
          return {
            id: i + 1,
            tc,
          };
        })
      : [
          {
            id: 1,
            tc: "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.".toString(),
          },
          {
            id: 2,
            tc: "Any additional or deleted services or supplies shall be stated separately in the invoice.".toString(),
          },
          {
            id: 3,
            tc: "Company is not responsible for any transit damages.".toString(),
          },
        ];

  const termsAndConditionsPI =
    quotationToBeEdited && quotationToBeEdited.terms_conditions
      ? quotationToBeEdited.terms_conditions.map((tc, i) => {
          return {
            id: i + 1,
            tc,
          };
        })
      : [
          {
            id: 1,
            tc: "We declare that this Invoice shows the actual price of the goods described and that all particulars are true and correct.".toString(),
          },
          {
            id: 2,
            tc: "Any additional or deleted services or supplies shall be stated separetely in the Invoice.".toString(),
          },
          {
            id: 3,
            tc: "Company is not responsilbe for any transit damages.".toString(),
          },
          {
            id: 4,
            tc: "Freight / Transportation : Included in the offer.".toString(),
          },
          {
            id: 5,
            tc: "⁠Insurance: Not included in the offer and to be included additionally.".toString(),
          },
          {
            id: 6,
            tc: "⁠Delivery of Material will be given within 7 days from date of confirmation of order along with advance.".toString(),
          },
          {
            id: 7,
            tc: "Payment terms : 100% Advance before dispatch.".toString(),
          },
          {
            id: 8,
            tc: "⁠Operation and maintenence services are not included in the offer, however same can be provided against the seperate annual agreement.".toString(),
          },
          {
            id: 9,
            tc: "⁠Warranty: For PV Modules: 25 years of performance warranty from the manufacturer and 10 years Panel warranty. For Inverters: 5 years’ manufacturer’s warranty.".toString(),
          },
          {
            id: 10,
            tc: "⁠Warranty claim: As per Manufacturer's policy.".toString(),
          },
        ];

  const items =
    quotationToBeEdited && quotationToBeEdited.items
      ? quotationToBeEdited.items.map((quotation, i) => {
          return {
            id: i + 1,
            properties: {
              product_name: quotation.product_name,
              product_price: quotation.product_price,
              hsn_sac_code: quotation.hsn_sac_code,
              units_per_quantity: quotation.units_per_quantity,
              unit_type: quotation.unit_type,
              igst: quotation.igst,
              cgst: quotation.cgst,
              sgst: quotation.sgst,
            },
          };
        })
      : [
          {
            id: 1,
            properties: {
              product_name: "",
              product_price: 100,
              hsn_sac_code: 0,
              units_per_quantity: 1,
              unit_type: "",
              igst: 0,
              cgst: 0,
              sgst: 0,
            },
          },
        ];

  const [tnc, setTnc] = useState(termsAndConditions);

  const [invoiceData, setInvoiceData] = useState({
    invoice_no: "",
    date: new Date().toISOString().slice(0, 10),
    dueDate: new Date().toISOString().slice(0, 10),
  });

  const [productsData, setProductsData] = useState(items);

  console.log({ productsData });

  const [quoteData, setQuoteData] = useState(quotationToBeEdited);
  // const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setProductLoading(true);
    setProductsError(false);
    axiAuth
      .get(`api/search/active-products?page=${page}`)
      .then((res) => {
        setProducts(res.data);
        setProductLoading(false);
        setProductsError(false);

        // const pageNumber = Math.ceil(res.data.current_count / 15);
        // setTotalPages(pageNumber);
      })
      .catch((err) => {
        setProductLoading(false);
        setProductsError("Error Fetching Products");
      });
  }, [page]);

  const searchHandler = async (e) => {
    // e.preventDefault();
    await searchProducts();
  };

  const searchOnSubCategorySet = (e) => {
    e.preventDefault();
    const subCategory = e.target.value;
    console.log(subCategory);
    setSelectedSubCategory(subCategory);
  };

  const searchProducts = async () => {
    try {
      // if (!selectedCategory || !selectedSubCategory) {
      //   toast.warn("Please select category and sub category");
      //   return;
      // }
      setProductLoading(true);

      const { data } = await axiAuth.get(
        `api/search/active-products?page=${page}&sub_category_id=${selectedSubCategory}&search=${searchTerm}`
      );

      setProducts(data);
      setProductLoading(false);
    } catch (error) {
      setProductLoading(false);
      setProductsError("Error Fetching Products");
    }
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    // Don't run on the initial render
    if (isFirstRender.current) {
      isFirstRender.current = false; // Set it to false after the first render
      return;
    }

    console.log(selectedCategory, selectedSubCategory);
    searchProducts();
  }, [selectedSubCategory]);

  useEffect(() => {
    setPage(1);
  }, [selectedCategory, selectedSubCategory]);

  useEffect(() => {
    if (portfolioData?.beneficiary_name) {
      setValue("bank_beneficiary_name", portfolioData.beneficiary_name);
    }

    if (portfolioData?.account_number) {
      setValue("bank_account_no", portfolioData.account_number);
    }

    if (portfolioData?.bank_name) {
      setValue("bank_name", portfolioData.bank_name);
    }

    if (portfolioData?.ifsc_code) {
      setValue("bank_ifsc", portfolioData.ifsc_code);
    }

    if (portfolioData?.upi_address) {
      setValue("bank_upi_address", portfolioData.upi_address);
    }
  }, [portfolioData, setValue]);

  if (productsError) {
    return <p>'Error Fetching Product'</p>;
  }

  // End Product search

  // const [downloadProgress, setDownloadProgress] = useState(0);

  const refToPrint = () => {
    switch (template) {
      case "temp1":
        return template1Ref;
      case "temp2":
        return template2Ref;
      case "temp3":
        return template3Ref;
      case "temp4":
        return template4Ref;
      default:
        return template1Ref;
    }
  };

  console.log(refToPrint());

  const clickHandler = async (product) => {
    try {
      setProductsData((prev) => {
        return [
          ...prev,
          {
            id: prev.length + 1,
            properties: {
              product_name: product?.product_name || "",
              product_price: 0,
              hsn_sac_code: product?.hsn_sac_code || "",
              units_per_quantity: 1,
              unit_type: product?.unit_type || "",
              igst: product?.tax_igst || 0,
              cgst: product?.tax_cgst || 0,
              sgst: product?.tax_sgst || 0,
            },
          },
        ];
      });

      toast.success("Product Added for Quotation");
    } catch (error) {
      toast.error("Something went wrong");
    }

    // if (setSelectedProduct) {

    //   try {
    //     setSelectedProductLoading(true);
    //     const { data } = await axiAuth.get(
    //       `api/public/products/${product.product_id}`
    //     );

    //     setSelectedProduct(data.product);
    //     setSelectedProductLoading(false);
    //   } catch (error) {
    //     setSelectedProductLoading(false);
    //   }
    //   setSelectedProduct(product);
    // }
  };

  const handleEmiCheck = (event) => {
    setEmiChecked(event.target.checked);
  };

  const handleEmiDataChange = (event) => {
    const { name, value } = event.target;
    setEmiData({
      ...emiData,
      [name]: value,
    });
  };

  const emiTypeHandler = (e) => {
    setEmiType(e.target.value);
  };

  console.log({ emiType });

  const addProduct = () => {
    setProductsData((prev) => {
      return [
        ...prev,
        {
          id: prev.length + 1,
          properties: {
            product_name: "",
            product_price: 100,
            hsn_sac_code: "",
            units_per_quantity: 1,
            unit_type: "",
            igst: 0,
            cgst: 0,
            sgst: 0,
          },
        },
      ];
    });
  };

  const addTNC = () => {
    setTnc((prev) => {
      return [
        ...prev,
        {
          id: prev.length + 1,
          tc: "",
        },
      ];
    });
  };

  const onInvoiceTypeChange = (event) => {
    setInvoiceType(event.target.value);
    if (event.target.value === "TAX_INVOICE") {
      setTnc(termsAndConditions);
    } else {
      setTnc(termsAndConditionsPI);
    }
  };

  const onTemplateChange = (event) => {
    console.log(event.target.value);
    setTemplate(event.target.value);
  };

  const quotationSubmitHandler = async (data) => {
    // if nothing in the terms and conditions filed return error
    if (tnc.some((tc) => tc.tc.trim() === "")) {
      toast.error("Please fill all the terms and conditions");
      return;
    }
    // if (activePage === 0) {
    //   setActivePage(1);
    //   return;
    // }

    // if (activePage === 1) {
    if (emiChecked) {
      if (
        emiData.downPayment.trim().length === 0 ||
        emiData.tenure.trim().length === 0 ||
        emiData.rate.trim().length === 0
      ) {
        toast.error("Enter All Emi Data");
        return;
      }
    }
    // }

    const quotationData = {
      ...data,
      set_customer: "waree",
      items: productsData.map((prod) => prod.properties),
      type: invoiceType,
      customer_id: customerId,
      due_in: 0,
      invoiceData,
      terms_conditions: tnc.map((tc) => tc.tc),
      emiData: {
        ...emiData,
        emiType,
      },
      Declaration: "We declare that this invoice shows actual price of the goods described inclusive of taxes and that all particulars are true and correct. In case you find Selling Price on this invoice to be more than MRP mentioned on product please inform\nsupport@solruf.com.",
    Declaration2: "Solruf India Pvt. Ltd Mumbai is only supplier of solar products. The warranty/guarantee of all supplied products will be provided by the respective manufacturers only.",
    Solruf_Customer_Care: "+91-8779245406"
    };

    if (quotationData.items.length === 0) {
      toast.error("Please add at-least one product");
      return;
    }

    setQuoteData(quotationData);

    if (agreementDownloadHandlerWithQuotation) {
      if (activePage === 1) {
        agreementDownloadHandlerWithQuotation({
          ...quotationData,
          emiData,
          emiType,
        });
        return;
      }
    }

    try {
      console.log({ quotationData, template });
      // handlePrint();

      // Make a POST request to the API using Axios
      const response = await axiAuth.post(
        "https://api-dev-v2.solruf.com/api/v2/quotation-public",
        { quotationData },
        {
          responseType: "blob", // Set the response type to Blob
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header to application/json
          },
          onDownloadProgress: (progressEvent) => {
            // Calculate and update the download progress
            // const progress = Math.round(
            //   (progressEvent.loaded / progressEvent.total) * 100
            // );
            // setDownloadProgress(progress);
          },
        }
      );

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.style.display = "none"; // Hide the anchor
      document.body.appendChild(a); // Append the anchor to the document

      // Function to initiate the download
      const startDownload = () => {
        a.href = window.URL.createObjectURL(response.data);
        a.download = quotationData.name + "_quotation.pdf";
        a.click(); // Trigger the click event to start the download
      };

      // Start the download immediately
      startDownload();

      // Release the URL object and remove the anchor after download
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      // setActivePage(2);
    } catch (error) {
      // toast.error(error.response.data.message || "Something went wrong");
      console.error("API request error:", error);
    }
  };

  

  const pages = [
    <Wrapper
      style={{
        // "@media (min-width: 1601px)": { width: "100%" },
        // "@media (max-width: 1600px)": { width: "100%" },
        "@media (min-width: 1920px)": { width: "100%" },
        "@media (max-width: 1920px)": { width: "100%" },
      }}
    >
      <ContentWrapper>
        <Stack
          direction="row"
          columnGap={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" columnGap={3} alignItems="center">
            {/* <ChevronLeftIcon
                onClick={() => {
                    setActivePage(0);
                }}
                sx={{ color: "", fontSize: "2rem", cursor: "pointer" }}
                /> */}
            <Typography
              variant="h5"
              sx={{
                fontSize: "1.5rem",
                my: 1,
              }}
            >
              Quotation Details
            </Typography>
          </Stack>
        </Stack>
        {/* Quotation Name */}
        <SolrufTextField
          size="small"
          type="text"
          label="Quotation Name"
          {...register("name", {
            required: {
              value: true,
              message: "Name is required",
            },
          })}
          helperText={errors.name?.message}
          error={true}
        />

        {/* ====== INVOICE DETAILS ====== */}

        <QuoteAccordion
          title="Invoice Details"
          titleStyle={{ fontSize: "1rem" }}
        >
          <ColumnWrapper>
            <Box
              onChange={onInvoiceTypeChange}
              sx={{ display: "flex", columnGap: "1rem" }}
            >
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background: invoiceType === "TAX_INVOICE" ? "#ffd05b" : "",
                }}
              >
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background: invoiceType === "TAX_INVOICE" ? "#4D4D4D" : "",
                  }}
                />
                <input
                  type="radio"
                  value="TAX_INVOICE"
                  name="TAX_INVOICE"
                  checked={invoiceType === "TAX_INVOICE"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Tax Invoice
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background:
                    invoiceType === "PROFORMA_INVOICE" ? "#ffd05b" : "",
                }}
              >
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background:
                      invoiceType === "PROFORMA_INVOICE" ? "#4d4d4d" : "",
                  }}
                />
                <input
                  type="radio"
                  value="PROFORMA_INVOICE"
                  name="PROFORMA_INVOICE"
                  checked={invoiceType === "PROFORMA_INVOICE"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Proforma Invoice
                </Typography>
              </Box>
            </Box>
            <WrapWrapper>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ mb: 1, width: "100%" }}>
                  <SolrufTextField
                    size="large"
                    type="text"
                    label="Invoice Number"
                    {...register("invoice_no", {
                      required: {
                        value: true,
                        message: "Invoice no. is required",
                      },
                    })}
                    helperText={errors.invoice_no?.message}
                    error={!!errors.invoice_no}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Box
                    sx={{
                      border: "2px solid #ffd05b",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      width:
                        invoiceType === "PROFORMA_INVOICE" ? "50%" : "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      Date
                    </Typography>
                    <TextField
                      variant="standard"
                      type="date"
                      value={invoiceData.date}
                      onChange={(e) => {
                        setInvoiceData({
                          ...invoiceData,
                          date: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  {invoiceType === "PROFORMA_INVOICE" && (
                    <Box
                      sx={{
                        border: "2px solid #ffd05b",
                        borderRadius: "5px",
                        padding: "0.5rem",
                        width: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Due Date
                      </Typography>
                      <TextField
                        variant="standard"
                        type="date"
                        value={invoiceData.dueDate}
                        onChange={(e) => {
                          setInvoiceData({
                            ...invoiceData,
                            dueDate: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </WrapWrapper>
          </ColumnWrapper>
        </QuoteAccordion>

        {/* ====== BUYER DETAILS ====== */}

        <QuoteAccordion title="Buyer Details" titleStyle={{ fontSize: "1rem" }}>
          <ColumnWrapper>
            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Company Name"
                {...register("buyer_name", {
                  required: {
                    value: true,
                    message: "Buyer name is required",
                  },
                })}
                helperText={errors.buyer_name?.message}
                error={!!errors.buyer_name}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Company Address"
                {...register("buyer_address", {
                  required: {
                    value: true,
                    message: "Buyer Address is required",
                  },
                })}
                helperText={errors.buyer_address?.message}
                error={!!errors.buyer_address}
              />
            </WrapWrapper>

            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="number"
                label="Phone Number"
                {...register("buyer_phone", {
                  required: {
                    value: true,
                    message: "Buyer phone is required",
                  },
                })}
                helperText={errors.buyer_phone?.message}
                error={!!errors.buyer_phone}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Email (Optional)"
                {...register("buyer_email")}
              />
            </WrapWrapper>

            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Company Website (Optional)"
                {...register("website")}
                helperText={errors.website?.message}
                error={!!errors.website}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="GST Number (Optional)"
                {...register("buyer_gst")}
                // helperText={errors.buyer_gst?.message}
                // error={!!errors.buyer_gst}
              />
            </WrapWrapper>
          </ColumnWrapper>
        </QuoteAccordion>

        {/* Shipping */}

        <QuoteAccordion
          title="Shipping Details"
          titleStyle={{ fontSize: "1rem" }}
        >
          <ColumnWrapper>
            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Name"
                {...register("shipping_name", {
                  required: {
                    value: true,
                    message: "Shipping Name is required",
                  },
                })}
                helperText={errors.shipping_name?.message}
                error={!!errors.shipping_name}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Address"
                {...register("shipping_address", {
                  required: {
                    value: true,
                    message: "Shipping Address is required",
                  },
                })}
                helperText={errors.shipping_address?.message}
                error={!!errors.shipping_address}
              />
            </WrapWrapper>

            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="number"
                label="Phone"
                {...register("shipping_phone", {
                  required: {
                    value: true,
                    message: "Shipping phone is required",
                  },
                })}
                helperText={errors.shipping_phone?.message}
                error={!!errors.shipping_phone}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Email"
                {...register("shipping_email", {
                  required: {
                    value: true,
                    message: "Shipping email is required",
                  },
                })}
                helperText={errors.shipping_email?.message}
                error={!!errors.shipping_email}
              />
            </WrapWrapper>
          </ColumnWrapper>
        </QuoteAccordion>

        {/* ====== SELLER DETAILS ====== */}

        <QuoteAccordion title="Seller Details" titleStyle={{ fontSize: "1rem" }}>
          <ColumnWrapper>
            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Company Name"
                {...register("seller_name", {
                  required: {
                    value: true,
                    message: "Seller name is required",
                  },
                })}
                helperText={errors.seller_name?.message}
                error={!!errors.seller_name}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Company Address"
                {...register("seller_location", {
                  required: {
                    value: true,
                    message: "Seller Address is required",
                  },
                })}
                helperText={errors.seller_location?.message}
                error={!!errors.seller_location}
              />
            </WrapWrapper>

            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="number"
                label="Phone Number"
                {...register("seller_mobile", {
                  required: {
                    value: true,
                    message: "Seller phone is required",
                  },
                })}
                helperText={errors.seller_mobile?.message}
                error={!!errors.seller_mobile}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Email (Optional)"
                {...register("seller_email")}
              />
            </WrapWrapper>

            <WrapWrapper>
              {/* <SolrufTextField
                size="small"
                type="text"
                label="Company Website (Optional)"
                {...register("website")}
                helperText={errors.website?.message}
                error={!!errors.website}
              /> */}
              <SolrufTextField
                size="small"
                type="text"
                label="GST Number (Optional)"
                {...register("seller_gst")}
                // helperText={errors.buyer_gst?.message}
                // error={!!errors.buyer_gst}
              />
            </WrapWrapper>
          </ColumnWrapper>
        </QuoteAccordion>

        {/* Bank Pay Data */}

        <QuoteAccordion
          title="Bank Payment Details"
          titleStyle={{ fontSize: "1rem" }}
        >
          <ColumnWrapper>
            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Beneficiary Name"
                {...register("bank_beneficiary_name", {
                  required: {
                    value: true,
                    message: "Bank beneficiary name is required",
                  },
                })}
                // defaultValue={portfolioData?.beneficiary_name}
                helperText={errors.bank_beneficiary_name?.message}
                error={!!errors.bank_beneficiary_name}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="Account Number"
                {...register("bank_account_no", {
                  required: {
                    value: true,
                    message: "Account Number is required",
                  },
                })}
                // defaultValue={portfolioData?.account_number}
                helperText={errors.bank_account_no?.message}
                error={!!errors.bank_account_no}
              />
            </WrapWrapper>
            <WrapWrapper>
              <SolrufTextField
                size="small"
                type="text"
                label="Bank Name"
                {...register("bank_name", {
                  required: {
                    value: true,
                    message: "Bank Name is required",
                  },
                })}
                // defaultValue={portfolioData?.bank_name}
                helperText={errors.bank_name?.message}
                error={!!errors.bank_name}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="IFSC Code"
                {...register("bank_ifsc", {
                  required: {
                    value: true,
                    message: "Bank Ifsc is required",
                  },
                })}
                // defaultValue={portfolioData?.ifsc_code}
                helperText={errors.bank_ifsc?.message}
                error={!!errors.bank_ifsc}
              />
              <SolrufTextField
                size="small"
                type="text"
                label="UPI Address"
                {...register("bank_upi_address", {
                  required: {
                    value: true,
                    message: "Bank UPI Address is required",
                  },
                })}
                // defaultValue={portfolioData?.upi_address}
                helperText={errors.bank_upi_address?.message}
                error={!!errors.bank_upi_address}
              />
            </WrapWrapper>
          </ColumnWrapper>
        </QuoteAccordion>

        {/* Terms & Conditions */}

        <QuoteAccordion
          title="Terms & Conditions"
          titleStyle={{ fontSize: "1rem" }}
        >
          <ColumnWrapper>
            {tnc.map((ele, idx) => {
              return (
                <RowWrapper key={"tnc" + idx}>
                  <SolrufTextField
                    size="small"
                    type="text"
                    label={`Terms and Conditions ${idx + 1}`}
                    defaultValue={tnc[idx].tc}
                    onChange={(e) => {
                      setTnc((prev) => {
                        prev[idx].tc = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                  <Button
                    onClick={() => {
                      setTnc((prev) =>
                        prev.filter((ele, k) => {
                          return k !== idx;
                        })
                      );
                    }}
                  >
                    <CloseIcon sx={{ color: "red" }} />
                  </Button>
                </RowWrapper>
              );
            })}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  px: 3,
                  color: "rgba(0,0,0,0.67)",
                  border: "1px solid black",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  addTNC(tnc.length + 1);
                }}
              >
                <AddIcon sx={{ fontSize: "1.2rem" }} /> Add T&C
              </Button>
            </Box>
          </ColumnWrapper>
        </QuoteAccordion>
      </ContentWrapper>
      {/* <ActionsWrapper>
        <PrimaryButton
          sx={{
            borderTopRightRadius: "0",
            borderTopLeftRadius: "0",
            bgcolor: "secondary.light",
            py: 1.5,
            "&:hover": {
              bgcolor: "primary.main",
            },
          }}
          type="submit"
          size="large"
          IconEnd={KeyboardArrowRightIcon}
          fullWidth
        >
          Next
        </PrimaryButton>
      </ActionsWrapper> */}
    </Wrapper>,

    // product details table
    <Wrapper
      style={{
        // "@media (min-width: 1601px)": { width: "100%" },
        // "@media (max-width: 1600px)": { width: "100%" },
        "@media (min-width: 1920px)": { width: "100%" },
        "@media (max-width: 1920px)": { width: "100%" },
      }}
    >
      <ProductsContentWrapper>
        <ColumnWrapper>
          <Stack
            direction="row"
            columnGap={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" columnGap={3} alignItems="center">
              {/* <ChevronLeftIcon
                onClick={() => {
                  setActivePage(0);
                }}
                sx={{ color: "", fontSize: "2rem", cursor: "pointer" }}
              /> */}
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1.5rem",
                  my: 1,
                }}
              >
                Product Details
              </Typography>
            </Stack>
          </Stack>
          {/* <Stack>
            <SolrufTextField size="small" type="text" label="Search Product" />
          </Stack> */}
          {/* LIst */}
          <ProductListWrapper>
            <Container maxWidth="xl">
              {/* ============ filter section ============ */}
              <Flex sx={{ my: 2 }}>
                <SolrufTextFieldGray
                  select
                  size="small"
                  label="Select Category"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  sx={{ mr: 2 }}
                >
                  {categories.map(({ category_id, name }) => (
                    <MenuItem key={category_id} value={category_id}>
                      {name}
                    </MenuItem>
                  ))}
                </SolrufTextFieldGray>

                <SolrufTextFieldGray
                  select
                  size="small"
                  label="Select Category"
                  defaultValue={selectedSubCategory}
                  onChange={searchOnSubCategorySet}
                >
                  {subCategories.map(({ category_id, name }) => (
                    <MenuItem key={category_id} value={category_id}>
                      {name}
                    </MenuItem>
                  ))}
                </SolrufTextFieldGray>
              </Flex>

              <Box sx={{ my: 2 }}>
                <SearchBox
                  onChange={searchTermChangeHandler}
                  placeholder="Search Product"
                  searchTerm={searchTerm}
                  onSearch={searchHandler}
                />
              </Box>

              <InfoBar>
                <Flex sx={{ alignItems: "center" }}>
                  <Typography>
                    <strong>Total Products: {products?.current_count}</strong>
                  </Typography>
                </Flex>
              </InfoBar>

              {/*  product list */}
              <SearchDataWrapper sx={{ rowGap: "1.5rem" }}>
                {productsLoading ? (
                  <Loader />
                ) : (
                  products?.products?.map((item, index) => (
                    <ProductWrapper onClick={() => clickHandler(item)}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "90px",
                          overflow: "hidden",
                          mb: "0.5rem",
                        }}
                      >
                        <img
                          src={item?.product_thumbnail}
                          alt="default"
                          style={{
                            width: "100%",
                            // make the image cover the whole box
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "0.7rem",
                          fontWeight: "500",
                          color: "#000000",
                          px: 1,
                          pb: 1,
                        }}
                      >
                        {item?.product_name.slice(0, 25)}
                      </Typography>
                    </ProductWrapper>
                  ))
                )}
              </SearchDataWrapper>

              {/* <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  maxHeight: "250px !important",
                  overflowY: "auto",
                }}
              >
                {productsLoading ? (
                  <Loader />
                ) : (
                  products?.products?.map((product, index) => (
                    <>
                      <ListItem
                        sx={{
                          cursor: "pointer",
                          "&:hover": { bgcolor: " rgb(255 253 247)" },
                          alignItems: { xs: "center", sm: "flex-start" },
                          justifyContent: "space-between",
                          borderRadius: "8px",
                          bgcolor:
                            selectedProduct?.product_id === product?.product_id
                              ? "secondary.lightYellow"
                              : "",
                        }}
                        onClick={() => clickHandler(product)}
                      >
                        <ListItemAvatar
                          sx={{ display: { xs: "none", sm: "block" } }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={product.product_thumbnail}
                            sx={{
                              borderRadius: "5px",
                              width: "3rem",
                              height: "3rem",
                              mr: 2,
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                sx={{ color: { xs: "#2448FC", sm: "inherit" } }}
                              >
                                {product.product_name}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: { xs: "none", sm: "inline" } }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Solruf Product
                              </Typography>
                            </>
                          }
                        />
                        <ListItemButton
                          sx={{
                            display: {
                              xs: "block",
                              sm: "none",
                              textAlign: "end",
                            },
                          }}
                        >
                          <Radio
                            style={{ "&$checked": { color: "#4B8DF8" } }}
                            sx={{ color: "#2448FC" }}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider
                        component="li"
                        sx={{
                          borderBottom: {
                            xs: "2px solid black",
                            sm: "inherit",
                          },
                          width: "100%",
                        }}
                      />
                    </>
                  ))
                )}
              </List> */}

              {/* ============ pagination section ============ */}
              <Pagination
                count={products.last_page}
                page={page}
                onChange={(e, page) => setPage(page)}
                color="primary"
                shape="rounded"
                sx={{ "& ul": { justifyContent: "center" }, mt: 2 }}
              />
            </Container>
          </ProductListWrapper>
          {/* End List */}
          <Stack direction="row" alignItems="center" columnGap={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={emiChecked}
                    onChange={handleEmiCheck}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="EMI"
              />
            </FormGroup>
            {emiChecked && (
              <>
                <TextField
                  size="small"
                  type="number"
                  label="Rate of Interest (%)"
                  name="rate"
                  value={emiData.rate}
                  onChange={handleEmiDataChange}
                  error={errors.rate}
                  helperText={errors.rate && errors.rate.message}
                />
                <TextField
                  size="small"
                  type="number"
                  label="Loan Tenure (Month)"
                  name="tenure"
                  value={emiData.tenure}
                  onChange={handleEmiDataChange}
                  error={errors.tenure}
                  helperText={errors.tenure && errors.tenure.message}
                />
                <TextField
                  size="small"
                  type="number"
                  label="Down Payment (%)"
                  name="downPayment"
                  value={emiData.downPayment}
                  onChange={handleEmiDataChange}
                  error={errors.downPayment}
                  helperText={errors.downPayment && errors.downPayment.message}
                />

                <SolrufTextField
                  value={emiType}
                  size="small"
                  select
                  label="Emi Type"
                  sx={{
                    width: "250px",
                  }}
                  onChange={(e) => emiTypeHandler(e)}
                >
                  <MenuItem value={0}>Flat Rate</MenuItem>
                  <MenuItem value={1}>Reducing Flat Rate</MenuItem>
                </SolrufTextField>
              </>
            )}
          </Stack>
          {/* <Table
            sx={{
              "@media (max-width: 900px)": {
                display: "none",
              },
              width: "100%",
            }}
          > */}
          {/* <thead>
              <tr>
                <th>Product Name</th>
                <th>Price (Rs.)</th>
                <th>HSN/SAC</th>
                <th>QTY</th>
                <th>Units</th>
                <th>IGST (%)</th>
                <th>CGST (%)</th>
                <th>SGST (%)</th>
                <th>Amount (Rs.)</th>
                <th style={{ width: "1rem" }}></th>
              </tr>
            </thead> */}
          {/* <tbody>
              {productsData.map((product, i) => (
                <tr key={"product" + i}>
                  {Object.entries(product.properties).map((product, j) => {
                    // console.log(product);
                    return (
                      <td width={j === 0 ? "20%" : "auto"} key={j}>
                        <LightTextField
                          sx={{ minWidth: "4rem" }}
                          placeholder={product[0]}
                          type="text"
                          size="small"
                          name={product[0]}
                          value={product[1]}
                          onChange={(e) => {
                            setProductsData((prev) => {
                              prev[i].properties[product[0]] = e.target.value;
                              return [...prev];
                            });
                          }}
                        />
                      </td>
                    );
                  })}
                  <td>
                    {(
                      (parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.cgst) / 100) *
                          parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.igst) / 100) *
                          parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.sgst) / 100) *
                          parseFloat(product.properties.product_price)) *
                      parseInt(product.properties.units_per_quantity)
                    ).toFixed(2)}
                  </td>
                  <td style={{ width: "1rem" }}>
                    <Button
                      onClick={() => {
                        setProductsData((prev) =>
                          prev.filter((ele, k) => {
                            return k !== i;
                          })
                        );
                      }}
                    >
                      <CloseIcon sx={{ color: "red" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table > */}

          <Table
            sx={{
              "@media (max-width: 900px)": {
                display: "none",
              },
              border: "none",
              width: "100%",
              "& tbody tr:nth-of-type(odd)": {
                backgroundColor: "inherit", // Remove any odd row background color
              },
              "& tbody tr:nth-of-type(even)": {
                backgroundColor: "inherit", // Remove any even row background color
              },
            }}
          >
            <tbody style={{ display: "block" }}>
              {productsData.map((product, i) => {
                const entries = Object.entries(product.properties);
                const firstRowEntries = entries.slice(0, 3);
                const secondRowEntries = entries.slice(3);

                const totalValue = (
                  (parseFloat(product.properties.product_price) +
                    (parseFloat(product.properties.cgst) / 100) *
                      parseFloat(product.properties.product_price) +
                    (parseFloat(product.properties.igst) / 100) *
                      parseFloat(product.properties.product_price) +
                    (parseFloat(product.properties.sgst) / 100) *
                      parseFloat(product.properties.product_price)) *
                  parseInt(product.properties.units_per_quantity)
                ).toFixed(2);

                // Function to format label with first letter of each word uppercase
                const formatLabel = (label) => {
                  return label
                    .replace(/_/g, " ") // Replace underscores with spaces
                    .replace(
                      /\w\S*/g,
                      (txt) =>
                        txt.charAt(0).toUpperCase() +
                        txt.substr(1).toLowerCase()
                    ); // Capitalize first letter of each word
                };

                return (
                  <React.Fragment key={"product" + i}>
                    <tr style={{ border: "none" }}>
                      <td
                        rowSpan={2}
                        style={{
                          width: "1rem",
                          border: "2px solid #eee",
                          borderBottom: "2px solid #eee",
                        }}
                      >
                        {i + 1}
                      </td>
                      {firstRowEntries.length >= 3 && (
                        <td
                          colSpan={3}
                          width="20%"
                          style={{
                            border: "none",
                            borderTop: "2px solid #eee",
                          }}
                        >
                          <LightTextField
                            sx={{
                              minWidth: "100%",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#ffd05b",
                                  borderWidth: "2px",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ffd05b",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#ffd05b",
                                },
                              },
                            }}
                            placeholder={formatLabel(firstRowEntries[0][0])}
                            label={formatLabel(firstRowEntries[0][0])}
                            type="text"
                            size="small"
                            name={firstRowEntries[0][0]}
                            value={firstRowEntries[0][1]}
                            onChange={(e) => {
                              setProductsData((prev) => {
                                prev[i].properties[firstRowEntries[0][0]] =
                                  e.target.value;
                                return [...prev];
                              });
                            }}
                          />
                        </td>
                      )}
                      {firstRowEntries.length >= 2 &&
                        firstRowEntries.slice(1, 3).map((row, idx) => (
                          <td
                            colSpan={1}
                            width="auto"
                            style={{
                              border: "none",
                              borderTop: "2px solid #eee",
                            }}
                            key={idx}
                          >
                            <LightTextField
                              sx={{
                                minWidth: "100%",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#ffd05b",
                                    borderWidth: "2px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#ffd05b",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#ffd05b",
                                  },
                                },
                              }}
                              placeholder={formatLabel(row[0])}
                              label={formatLabel(row[0])}
                              type="text"
                              size="small"
                              name={row[0]}
                              value={row[1]}
                              onChange={(e) => {
                                setProductsData((prev) => {
                                  prev[i].properties[row[0]] = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </td>
                        ))}
                      <td
                        rowSpan={2}
                        style={{
                          width: "1rem",
                          border: "2px solid #eee",
                          borderBottom: "2px solid #eee",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setProductsData((prev) =>
                              prev.filter((ele, k) => k !== i)
                            );
                          }}
                        >
                          <CloseIcon sx={{ color: "red" }} />
                        </Button>
                      </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                      {secondRowEntries.map(([key, value], j) => (
                        <td
                          width={j === 0 ? "20%" : "auto"}
                          key={j}
                          style={{
                            border: "none",
                            borderBottom: "2px solid #eee",
                          }}
                        >
                          <LightTextField
                            // sx={{ minWidth: "4rem" }}
                            sx={{
                              minWidth: "100%",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#ffd05b",
                                  borderWidth: "2px",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ffd05b",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#ffd05b",
                                },
                              },
                            }}
                            placeholder={key}
                            label={formatLabel(key)}
                            type="text"
                            size="small"
                            name={key}
                            value={value}
                            onChange={(e) => {
                              setProductsData((prev) => {
                                prev[i].properties[key] = e.target.value;
                                return [...prev];
                              });
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                    <tr style={{ border: "2px solid #eee", borderTop: 0 }}>
                      <td
                        colSpan={entries.length}
                        style={{ fontWeight: "bold" }}
                      >
                        Total Price: {totalValue}
                      </td>
                    </tr>
                    <tr style={{ border: "none" }}></tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>

          {productsData?.length === 0 && (
            <Typography
              sx={{
                textAlign: "center",
                color: "rgba(0,0,0,0.5)",
                fontSize: "1.2rem",
              }}
            >
              Please add at least one product*
            </Typography>
          )}

          <ProductsWrapper>
            {productsData.map((product, i) => (
              <ColumnWrapper
                key={"product2" + i}
                sx={{
                  borderBottom: "0.2px solid #d3d3d3",
                  paddingBottom: "1rem",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography color="black">Product {i + 1}</Typography>
                  <Button
                    onClick={() => {
                      setProductsData((prev) =>
                        prev.filter((ele, k) => {
                          return k !== i;
                        })
                      );
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </Button>
                </Box>

                <RowWrapper>
                  <SolrufTextField
                    placeholder="Product Name"
                    type="text"
                    size="small"
                    name="product_name"
                    label="Product Name"
                    value={product.properties.product_name}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.product_name = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                  <SolrufTextField
                    placeholder="Price"
                    type="number"
                    step="0.1"
                    size="small"
                    name="product_price"
                    label="Product Price"
                    value={product.properties.product_price}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Validation logic for product_price
                      if (!/^(\d+(\.\d*)?|\.\d+)$/.test(value)) {
                        setProductPriceError("Please enter a valid number");
                      } else {
                        setProductPriceError(null);
                      }
                      setProductsData((prev) => {
                        prev[i].properties.product_price = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                </RowWrapper>
                <RowWrapper>
                  <SolrufTextField
                    placeholder="HSN/SAC Code"
                    type="text"
                    size="small"
                    name="hsn_sac_code"
                    label="HSN/SAC Code"
                    value={product.properties.hsn_sac_code}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.hsn_sac_code = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                  <SolrufTextField
                    placeholder="Units"
                    type="number"
                    size="small"
                    name="units_per_quantity"
                    label="Units"
                    value={product.properties.units_per_quantity}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.units_per_quantity = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                </RowWrapper>
                <RowWrapper>
                  <SolrufTextField
                    placeholder="Unit Type"
                    type="text"
                    size="small"
                    name="unit_type"
                    label="Unit Type"
                    value={product.properties.unit_type}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.unit_type = e.target.value;
                        return [...prev];
                      });
                    }}
                  />

                  <SolrufTextField
                    placeholder="IGST"
                    type="number"
                    step="0.1"
                    size="small"
                    name="igst"
                    label="IGST"
                    value={product.properties.igst}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.igst = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                </RowWrapper>
                <RowWrapper>
                  <SolrufTextField
                    placeholder="CGST"
                    type="number"
                    step="0.1"
                    size="small"
                    name="cgst"
                    label="CGST"
                    value={product.properties.cgst}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.cgst = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                  <SolrufTextField
                    placeholder="SGST"
                    type="number"
                    step="0.1"
                    size="small"
                    name="sgst"
                    label="SGST"
                    value={product.properties.sgst}
                    onChange={(e) => {
                      setProductsData((prev) => {
                        prev[i].properties.sgst = e.target.value;
                        return [...prev];
                      });
                    }}
                  />
                </RowWrapper>
                <ActionsWrapper>
                  <Typography color="black">
                    Amount: ₹
                    {(
                      (parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.cgst) / 100) *
                          parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.igst) / 100) *
                          parseFloat(product.properties.product_price) +
                        (parseFloat(product.properties.sgst) / 100) *
                          parseFloat(product.properties.product_price)) *
                      parseInt(product.properties.units_per_quantity)
                    ).toFixed(2)}
                  </Typography>
                </ActionsWrapper>
              </ColumnWrapper>
            ))}
          </ProductsWrapper>
        </ColumnWrapper>
      </ProductsContentWrapper>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "end",
          padding: "0.5rem 1rem",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            px: 3,
            display: "flex",
            alignItems: "center",
            // color: "#ffd05b",
            fontWeight: "bold",
            border: "1px solid #4d4d4d",
            color: "primary.dark",
          }}
          onClick={addProduct}
        >
          <AddIcon sx={{ fontSize: "1.2rem", color: "primary.dark" }} /> Add a
          product
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "end",
          flexDirection: "column",
          padding: "1rem",
          "@media (max-width: 600px)": { display: "none" },
          rowGap: "0.8rem",
        }}
      >
        <Typography
          sx={{
            color: "black",
          }}
        >
          Sub Total Price:{" "}
          <span style={{ fontWeight: "bold" }}>
            ₹
            {productsData.reduce((accumulator, product) => {
              return (
                accumulator +
                product.properties.product_price *
                  product.properties.units_per_quantity
              );
            }, 0)}
          </span>
        </Typography>
        <Typography
          sx={{
            color: "black",
          }}
        >
          Tax:
          <span style={{ fontWeight: "bold" }}>
            ₹
            {productsData
              .reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.product_price *
                    product.properties.units_per_quantity *
                    (product.properties.cgst / 100 +
                      product.properties.igst / 100 +
                      product.properties.sgst / 100)
                );
              }, 0)
              .toFixed(2)}
          </span>
        </Typography>
        <Typography
          sx={{
            color: "black",
          }}
        >
          Total Price:{" "}
          <span style={{ fontWeight: "bold" }}>
            ₹
            {productsData
              .reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.product_price *
                    product.properties.units_per_quantity *
                    (product.properties.cgst / 100 +
                      product.properties.igst / 100 +
                      product.properties.sgst / 100 +
                      1)
                );
              }, 0)
              .toFixed(2)}
          </span>
        </Typography>
      </Box>

      {/* <ActionsWrapper>
        {agreementDownloadHandlerWithQuotation ? (
          <PrimaryButton
            sx={{
              borderTopRightRadius: "0",
              borderTopLeftRadius: "0",
              bgcolor: "secondary.light",
              py: 1.5,
              "&:hover": {
                bgcolor: "primary.main",
              },
            }}
            size="large"
            IconEnd={KeyboardArrowRightIcon}
            fullWidth
            type="submit"
          >
            Download Agreement with Quotation
          </PrimaryButton>
        ) : (
          <PrimaryButton
            sx={{
              borderTopRightRadius: "0",
              borderTopLeftRadius: "0",
              bgcolor: "secondary.light",
              py: 1.5,
              "&:hover": {
                bgcolor: "primary.main",
              },
            }}
            type="submit"
            size="large"
            IconEnd={KeyboardArrowRightIcon}
            fullWidth
          >
            Save
          </PrimaryButton>
        )}
      </ActionsWrapper> */}
    </Wrapper>,

    // === templates
    <Wrapper>
      <ProductsContentWrapper>
        <ColumnWrapper>
          <Box
            sx={{
              display: "flex",
              columnGap: "1rem",
              alignItems: "center",
            }}
          >
            <ChevronLeftIcon
              onClick={() => {
                setActivePage(1);
              }}
              sx={{ color: "", fontSize: "2rem", cursor: "pointer" }}
            />
            <Typography
              variant="h5"
              sx={{
                fontSize: "1.5rem",
                my: 1,
              }}
            >
              Invoice Templates
            </Typography>
          </Box>

          <Box
            onChange={onTemplateChange}
            sx={{
              display: "flex",
              columnGap: "1rem",
              "@media (max-width: 1000px)": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "0.5rem",
                cursor: "pointer",
                background: template === "temp1" ? "#ffd05b" : "",
              }}
            >
              <img
                style={{
                  width: "12rem",
                  border: "1px solid rgba(0,0,0,0.8)",
                }}
                src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template1.png"
                alt=""
              />
              <RowWrapper>
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background: template === "temp1" ? "black" : "#4d4d4d",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Template 1
                </Typography>
              </RowWrapper>
              <input
                type="radio"
                value="temp1"
                name="temp1"
                checked={template === "temp1"}
                onChange={() => {}}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  zIndex: 20,
                }}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "0.5rem",
                cursor: "pointer",
                background: template === "temp2" ? "#ffd05b" : "",
              }}
            >
              <img
                style={{
                  width: "12rem",
                  border: "1px solid rgba(0,0,0,0.8)",
                }}
                src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template2.png"
                alt=""
              />

              <RowWrapper>
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background: template === "temp2" ? "black" : "#4d4d4d",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Template 2
                </Typography>
              </RowWrapper>

              <input
                type="radio"
                value="temp2"
                name="temp2"
                checked={template === "temp2"}
                onChange={() => {}}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  zIndex: 20,
                }}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "0.5rem",
                cursor: "pointer",
                background: template === "temp3" ? "#ffd05b" : "",
              }}
            >
              <img
                style={{
                  width: "12rem",
                  border: "1px solid rgba(0,0,0,0.8)",
                }}
                src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template3.png"
                alt=""
              />

              <RowWrapper>
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background: template === "temp3" ? "black" : "#4d4d4d",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Template 3
                </Typography>
              </RowWrapper>

              <input
                type="radio"
                value="temp3"
                name="temp3"
                checked={template === "temp3"}
                onChange={() => {}}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  zIndex: 20,
                }}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "0.5rem",
                cursor: "pointer",
                background: template === "temp4" ? "#ffd05b" : "",
              }}
            >
              <img
                style={{
                  width: "12rem",
                  border: "1px solid rgba(0,0,0,0.8)",
                }}
                src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template4.png"
                alt=""
              />

              <RowWrapper>
                <Box
                  sx={{
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "1rem",
                    border: "1px solid black",
                    background: template === "temp4" ? "black" : "#4d4d4d",
                  }}
                />
                <Typography variant="p" sx={{ fontWeight: "600" }}>
                  Template 4
                </Typography>
              </RowWrapper>

              <input
                type="radio"
                value="temp4"
                name="temp4"
                checked={template === "temp4"}
                onChange={() => {}}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  opacity: "0",
                  zIndex: 20,
                }}
              />
            </Box>
          </Box>

          <Box
            onChange={onTemplateChange}
            sx={{
              display: "none",
              flexDirection: "column",
              rowGap: "1rem",
              "@media (max-width: 1000px)": {
                display: "flex",
              },
            }}
          >
            <ColumnWrapper
              sx={{
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background: template === "temp1" ? "#ffd05b" : "",
                }}
              >
                <img
                  style={{
                    width: "10rem",
                    height: "13rem",
                    border: "1px solid rgba(0,0,0,0.8)",
                  }}
                  src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template1.png"
                  alt=""
                />
                <RowWrapper>
                  <Box
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "1rem",
                      border: "1px solid black",
                      background: template === "temp1" ? "black" : "#4d4d4d",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    Template 1
                  </Typography>
                </RowWrapper>
                <input
                  type="radio"
                  value="temp1"
                  name="temp1"
                  checked={template === "temp1"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background: template === "temp2" ? "#ffd05b" : "",
                }}
              >
                <img
                  style={{
                    width: "10rem",
                    height: "13rem",
                    border: "1px solid rgba(0,0,0,0.8)",
                  }}
                  src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template2.png"
                  alt=""
                />

                <RowWrapper>
                  <Box
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "1rem",
                      border: "1px solid black",
                      background: template === "temp2" ? "black" : "#4d4d4d",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    Template 2
                  </Typography>
                </RowWrapper>

                <input
                  type="radio"
                  value="temp2"
                  name="temp2"
                  checked={template === "temp2"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background: template === "temp3" ? "#ffd05b" : "",
                }}
              >
                <img
                  style={{
                    width: "10rem",
                    height: "13rem",
                    border: "1px solid rgba(0,0,0,0.8)",
                  }}
                  src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template3.png"
                  alt=""
                />

                <RowWrapper>
                  <Box
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "1rem",
                      border: "1px solid black",
                      background: template === "temp3" ? "black" : "#4d4d4d",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    Template 3
                  </Typography>
                </RowWrapper>

                <input
                  type="radio"
                  value="temp3"
                  name="temp3"
                  checked={template === "temp3"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid black",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  cursor: "pointer",
                  background: template === "temp4" ? "#ffd05b" : "",
                }}
              >
                <img
                  style={{
                    width: "10rem",
                    height: "13rem",
                    border: "1px solid rgba(0,0,0,0.8)",
                  }}
                  src="https://solruf.s3.ap-south-1.amazonaws.com/Image+Assets/Templates/template4.png"
                  alt=""
                />

                <RowWrapper>
                  <Box
                    sx={{
                      width: "0.7rem",
                      height: "0.7rem",
                      borderRadius: "1rem",
                      border: "1px solid black",
                      background: template === "temp4" ? "black" : "#4d4d4d",
                    }}
                  />
                  <Typography variant="p" sx={{ fontWeight: "600" }}>
                    Template 4
                  </Typography>
                </RowWrapper>

                <input
                  type="radio"
                  value="temp4"
                  name="temp4"
                  checked={template === "temp4"}
                  onChange={() => {}}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    opacity: "0",
                    zIndex: 20,
                  }}
                />
              </Box>
            </ColumnWrapper>
          </Box>
        </ColumnWrapper>
      </ProductsContentWrapper>
      <Box
        sx={{
          display: "flex",
          widht: "100%",
          justifyContent: "end",
          padding: "1rem",
          "@media (max-width: 600px)": { display: "none" },
        }}
      >
        <Typography
          sx={{
            color: "black",
          }}
        >
          Total Price:{" "}
          <span style={{ fontWeight: "bold" }}>
            ₹
            {productsData.reduce((accumulator, product) => {
              return (
                accumulator +
                product.properties.product_price *
                  product.properties.units_per_quantity
              );
            }, 0)}
          </span>
        </Typography>
      </Box>

      <Template1
        quoteData={quoteData}
        ref={template1Ref}
        emiData={emiData}
        emiChecked={emiChecked}
        emiType={emiType}
      />
      <Template2
        quoteData={quoteData}
        templateRef={template2Ref}
        emiData={emiData}
        emiChecked={emiChecked}
        emiType={emiType}
      />
      <Template3
        quoteData={quoteData}
        templateRef={template3Ref}
        emiData={emiData}
        emiChecked={emiChecked}
        emiType={emiType}
      />
      <Template4
        quoteData={quoteData}
        templateRef={template4Ref}
        emiData={emiData}
        emiChecked={emiChecked}
        emiType={emiType}
      />

      <ActionsWrapper>
        <PrimaryButton
          sx={{
            borderTopRightRadius: "0",
            borderTopLeftRadius: "0",
            bgcolor: "secondary.light",
            py: 1.5,
            "&:hover": {
              bgcolor: "primary.main",
            },
          }}
          size="large"
          IconEnd={KeyboardArrowRightIcon}
          fullWidth
          type={agreementDownloadHandlerWithQuotation ? "submit" : "button"}
          onClick={() => {
            if (agreementDownloadHandlerWithQuotation) {
              return;
            }
            handlePrint();
          }}
        >
          Download
        </PrimaryButton>
      </ActionsWrapper>
    </Wrapper>,
  ];

  

  return (
    <Box
      sx={{
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        height: "100%",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        mt: 5,
        mb: 5,
        px: isSmallScreen ? 1 : 4,
        // "@media (max-width: 1600px)": { flexDirection: "column-reverse" },
        "@media (max-width: 1920px)": { flexDirection: "column-reverse" },
      }}
      component="form"
      onSubmit={handleSubmit(quotationSubmitHandler)}
      noValidate
      ref={formRef}
    >
      <ActionsWrapper>
        {agreementDownloadHandlerWithQuotation ? (
          <PrimaryButton
            sx={{
              borderTopRightRadius: "0",
              borderTopLeftRadius: "0",
              bgcolor: "secondary.light",
              py: 1.5,
              "&:hover": {
                bgcolor: "primary.main",
              },
            }}
            size="large"
            IconEnd={KeyboardArrowRightIcon}
            fullWidth
            type="submit"
          >
            Download Agreement with Quotation
          </PrimaryButton>
        ) : (
          <PrimaryButton
            sx={{
              borderTopRightRadius: "0",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
              bgcolor: "secondary.light",
              py: 1.5,
              "&:hover": {
                bgcolor: "primary.main",
              },
            }}
            type="submit"
            size="large"
            // IconEnd={KeyboardArrowRightIcon}
            fullWidth
          >
            Save
          </PrimaryButton>
        )}
      </ActionsWrapper>
      <TotalAccordion
        title={`Total Price: ₹${productsData.reduce((accumulator, product) => {
          return (
            accumulator +
            product.properties.product_price *
              product.properties.units_per_quantity
          );
        }, 0)}`}
      >
        <TotalContentWrapper>
          <hr
            style={{
              borderStyle: "solid",
              borderWidth: "0.2px",
              opacity: "0.5",
              marginBottom: "0.8rem",
            }}
          />
          <PriceRowWrapper>
            <Typography>Price:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              ₹
              {productsData.reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.product_price *
                    product.properties.units_per_quantity
                );
              }, 0)}
            </Typography>
          </PriceRowWrapper>
          <PriceRowWrapper>
            <Typography>CGST:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              ₹
              {productsData.reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.cgst *
                    product.properties.units_per_quantity
                );
              }, 0)}
            </Typography>
          </PriceRowWrapper>
          <PriceRowWrapper>
            <Typography>IGST:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              ₹
              {productsData.reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.igst *
                    product.properties.units_per_quantity
                );
              }, 0)}
            </Typography>
          </PriceRowWrapper>
          <PriceRowWrapper>
            <Typography>SGST:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              ₹
              {productsData.reduce((accumulator, product) => {
                return (
                  accumulator +
                  product.properties.sgst *
                    product.properties.units_per_quantity
                );
              }, 0)}
            </Typography>
          </PriceRowWrapper>
          <hr
            style={{
              borderStyle: "solid",
              borderWidth: "0.2px",
              opacity: "0.5",
              marginTop: "0.8rem",
            }}
          />
        </TotalContentWrapper>
      </TotalAccordion>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 1,
          padding: 2,
          // "@media (max-width: 1600px)": { flexDirection: "column" },
          "@media (max-width: 1920px)": { flexDirection: "column" },
        }}
      >
        <Box
          sx={
            {
              // "@media (min-width: 1600px)": { width: "35%" }
            }
          }
        >
          {pages[0]}
        </Box>
        <Box
          sx={
            {
              // "@media (min-width: 1600px)": { width: "65%" }
            }
          }
        >
          {pages[1]}
        </Box>
      </Box>
      {/* {pages[activePage]} */}
    </Box>
  );
};
