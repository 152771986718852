import { Box, Chip, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AutoCompleteSelect from '../../../components/AutoCompleteSelect/AutoCompleteSelect';
import '../AddAttribute/AddAttribute.css';
import {
   getAttributes,
   getBrands,
   getCategories,
   getSubCategories,
} from '../helper';
import { toast } from 'react-toastify';
import { axiAuth } from '../../../utils/axiosInstance';
import PrimaryButton from '../../../components/Custom/PrimaryButton/PrimaryButton';
import TextFieldWithSearch from '../../../components/Custom/TextFieldWithSearch/TextFieldWithSearch';
import { FlexBox } from '../../../components/styled/styled';
// import SelectCheckBox from '../../../components/SelectCheckBox/SelectCheckBox';

const ManageAttribute = () => {
   const [categories, setCategories] = useState([]);
   const [subCategories, setSubCategories] = useState([]);
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [selectedSubCategory, setSelectedSubCategory] = useState(null);
   const [selectedAttribute, setSelectedAttribute] = useState(null);
   const [attributeList, setAttributeList] = useState([]);
   const [brands, setBrands] = useState([]);
   const [selectedBrand, setSelectedBrand] = useState();

   const [loading, setLoading] = useState(false);
   const [brandLoading, setBrandLoading] = useState(false);

   console.log({
      selectedCategory,
      selectedSubCategory,
      selectedAttribute,
      selectedBrand,
   });

   const attributeHandler = async (type) => {
      try {
         setLoading(true);
         const { status } = await axiAuth.put(
            `api/admin/attributes/${selectedAttribute?.id}/status`,
            {
               active: type === 'active',
            }
         );
         if (status === 200) {
            setLoading(false);
            toast.success(
               `Attribute ${
                  type === 'active' ? 'activated' : 'inactivated'
               } successfully`
            );
            setSelectedAttribute((prev) => ({
               ...prev,
               active: !prev.active,
            }));
         }
      } catch (error) {
         setLoading(false);
         toast.warning(
            `Something went wrong while ${
               type === 'active' ? 'activating' : 'inactivating'
            } attribute`
         );
         console.log(error);
      }
   };
   const brandHandler = async (type) => {
      try {
         setBrandLoading(true);
         const { status } = await axiAuth.put(
            `api/admin/brands/${selectedBrand.id}`,
            {
               name: selectedBrand?.name,
               active: type === 'active',
            }
         );
         if (status === 200) {
            setBrandLoading(false);
            toast.success(
               `Brand ${
                  type === 'active' ? 'activated' : 'inactivated'
               } successfully`
            );
            setSelectedBrand((prev) => ({
               ...prev,
               active: !prev.active,
            }));
         }
      } catch (error) {
         setBrandLoading(false);
         toast.warning(
            `Something went wrong while ${
               type === 'active' ? 'activating' : 'inactivating'
            } Brand`
         );
         console.log(error);
      }
   };

   useEffect(() => {
      getCategories().then((response) => {
         setCategories(response);
      });
   }, []);

   useEffect(() => {
      if (selectedCategory) {
         getSubCategories(selectedCategory.category_id).then((response) => {
            setSubCategories(response);
         });
      }
   }, [selectedCategory]);

   useEffect(() => {
      if (selectedSubCategory) {
         getAttributes(selectedSubCategory.category_id).then((response) => {
            setAttributeList(response);
         });
      }
   }, [selectedSubCategory]);

   useEffect(() => {
      if (selectedCategory) {
         getBrands(selectedCategory?.category_id).then((response) => {
            setBrands(response);
         });
      }
   }, [selectedCategory]);

   return (
      <Container>
         <Box
            sx={{
               // minHeight: '55vh',
               py: 2,
            }}
         >
            <Box>
               <Box
                  sx={{
                     display: 'flex',
                     my: 2,
                     flexDirection: ['column', 'column', 'row'],
                  }}
               >
                  <AutoCompleteSelect
                     sx={{
                        marginRight: '1rem',
                        marginBottom: ['1rem', '1rem', 0],
                     }}
                     options={categories}
                     value={selectedCategory}
                     setValue={setSelectedCategory}
                     label='Select Category'
                  />

                  <AutoCompleteSelect
                     options={subCategories}
                     value={selectedSubCategory}
                     setValue={setSelectedSubCategory}
                     label='Select Sub Category'
                  />
               </Box>

               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     columnGap: '1rem',
                     mb: 4,
                     mt: 6,
                  }}
               >
                  <Box sx={{ width: '400px', maxWidth: '100%' }}>
                     <Typography variant='h4' sx={{ my: 2 }}>
                        Update Attribute Status
                     </Typography>
                     <TextFieldWithSearch
                        data={attributeList.map((attr) => ({
                           name: attr?.name,
                           id: attr.id,
                           active: attr.active,
                        }))}
                        label={
                           attributeList.length === 0
                              ? 'Select category and sub category'
                              : 'Existing Attribute'
                        }
                        setValue={setSelectedAttribute}
                     />
                  </Box>
                  {selectedAttribute && (
                     <FlexBox sx={{ columnGap: 2 }}>
                        <Typography>Is active</Typography>
                        <Chip
                           label={
                              selectedAttribute?.active ? 'Active' : 'Inactive'
                           }
                           color={
                              selectedAttribute?.active ? 'success' : 'warning'
                           }
                        />
                     </FlexBox>
                  )}
               </Box>

               <FlexBox
                  sx={{
                     justifyContent: 'center',
                     columnGap: 2,
                  }}
               >
                  <PrimaryButton onClick={() => attributeHandler('active')}>
                     {loading ? 'activating' : 'active'}
                  </PrimaryButton>
                  <PrimaryButton onClick={() => attributeHandler('inactive')}>
                     {loading ? 'inactivating' : 'Inactive'}
                  </PrimaryButton>
               </FlexBox>
            </Box>
         </Box>
         <hr />

         <FlexBox
            sx={{
               flexDirection: 'column',
               justifyContent: 'center',
               mt: 6,
            }}
         >
            <Typography variant='h4' sx={{ my: 2 }}>
               Update Brands Status
            </Typography>

            <FlexBox sx={{ columnGap: 2 }}>
               <TextFieldWithSearch
                  data={brands}
                  label={
                     brands.length === 0
                        ? 'Select category and sub category'
                        : 'Existing Brands'
                  }
                  sx={{
                     width: '400px',
                     maxWidth: '100%',
                  }}
                  setValue={setSelectedBrand}
                  value={selectedBrand}
               />
               {selectedBrand && (
                  <FlexBox sx={{ columnGap: 2 }}>
                     <Typography>Is active</Typography>
                     <Chip
                        label={selectedBrand?.active ? 'Active' : 'Inactive'}
                        color={selectedBrand?.active ? 'success' : 'warning'}
                     />
                  </FlexBox>
               )}
            </FlexBox>
         </FlexBox>
         <FlexBox
            sx={{
               justifyContent: 'center',
               columnGap: 2,
               mt: 2,
            }}
         >
            <PrimaryButton onClick={() => brandHandler('active')} disabled={brandLoading}>
               active
            </PrimaryButton>
            <PrimaryButton onClick={() => brandHandler('inactive')} disabled={brandLoading}>
               Inactive
            </PrimaryButton>
         </FlexBox>
      </Container>
   );
};

export default ManageAttribute;
