import React from 'react';
import { Container, Box, styled, Typography } from '@mui/material';

const TitleHeaderBox = styled(Box)(() => ({
   padding: '2rem',
   display: 'flex',
   alignItems: 'center',
   backgroundImage: `
      linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),
      url("https://solruf-node-static-files.s3.ap-south-1.amazonaws.com/2149352235+1.png")`,
   backgroundRepeat: 'no-repeat',
   backgroundPosition: 'center',
   backgroundSize: 'cover',
   minHeight: 350,
}));

const NumberedTypography = styled(Typography)(() => ({
   display: 'flex',
   alignItems: 'flex-start',
   '& span': {
      color: '#000000',
      marginRight: '0.5rem',
   },
}));

const ReturnPolicy = () => {
   return (
      <Box>
         <TitleHeaderBox>
            <Typography
               component='h4'
               fontWeight={600}
               sx={{ color: '#ffffff', fontSize: '3.5rem' }}
            >
               Return & Refund Policy
            </Typography>
         </TitleHeaderBox>
         <Container sx={{ my: 5 }} maxWidth='lg'>
            <Typography variant='body1' component='div' gutterBottom>
               <p
                  style={{
                     color: '#000000',
                     fontWeight: 600,
                     fontSize: '1.2rem',
                  }}
               >
                  INTRODUCTION
               </p>
               <p>
                  This Return and Refund Policy (“Policy”) governs the return
                  and refund procedure of products purchased through Solruf
                  India Private Limited (“Company” or “we” or “us” or “our” or
                  “Solruf”). We believe in following a transparent return and
                  refund mechanism and this Policy sets forth the process
                  followed by the Company. This Policy shall be applicable for
                  the products and services purchased through the Company’s
                  website (https://www.solruf.com/) (“Website”)(“Products and
                  Services”). The RETURN AND REFUND POLICY has been updated on
                  November 16th, 2022.
               </p>
            </Typography>

            <Box>
               <Typography variant='h6' gutterBottom>
                  1. RETURN/ REPLACEMENT POLICY
               </Typography>
               <Box sx={{ ml: 5 }}>
                  <NumberedTypography>
                     <span>1.1</span>{' '}
                     <p>
                        All communications by the Company or with the Company
                        shall be done strictly as per the modes of contact given
                        in this Policy and the contact details shared by the
                        customers at the time of purchase.
                     </p>
                  </NumberedTypography>
                  <NumberedTypography>
                     <span>1.2</span>{' '}
                     <p>
                        All Products and Services available on the Website are
                        validated and reviewed as specified in the description
                        of the Product on the Platform.
                     </p>
                  </NumberedTypography>
                  <NumberedTypography>
                     <span>1.3</span>
                     <p>
                        The Products shall be returned only if they meet one of
                        the below mentioned criteria:
                        <Box sx={{ ml: 5 }}>
                           <NumberedTypography>
                              <span>1.3.1</span>{' '}
                              <p>
                                 If the Products received are used, altered,
                                 poor in quality, or defective in nature;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.3.2</span>{' '}
                              <p>
                                 If the Products received are damaged in the
                                 course of delivery;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.3.3</span>{' '}
                              <p>
                                 If the Products have been received with open
                                 packaging seal;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.3.4</span>{' '}
                              <p>
                                 If the accessories of the Products are missing;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.3.5</span>{' '}
                              <p>
                                 If the Products received are wrong or not
                                 similar to the one that the customer has
                                 ordered; or
                              </p>
                           </NumberedTypography>
                           <NumberedTypography>
                              <span>1.3.6</span>{' '}
                              <p>
                                 Any other criteria that may be decided by the
                                 Company from time to time.
                              </p>
                           </NumberedTypography>
                        </Box>
                     </p>
                  </NumberedTypography>
                  <NumberedTypography>
                     <span>1.4</span>{' '}
                     <p>
                        A return shall not be accepted if any of the following
                        criteria are met:
                        <Box sx={{ ml: 5 }}>
                           <NumberedTypography>
                              <span>1.4.1</span>{' '}
                              <p>
                                 If the customer has used and/or damaged the
                                 ordered Product(s);
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.4.2</span>{' '}
                              <p>
                                 If the customer has altered the Product in any
                                 shape or form;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.4.3</span>{' '}
                              <p>
                                 If the Product was customized as per the
                                 customer’s requirements and the customer does
                                 not require the Product;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.4.4</span>{' '}
                              <p>
                                 If the request for return has been generated
                                 after the permissible time period as mentioned
                                 in Clause 1.5 of this Policy;
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.4.5</span>{' '}
                              <p>
                                 If the photo(s)/video(s)/ documents shared by
                                 the customer as a verification proof have been
                                 tampered with or inconsistent with the
                                 Product(s) ordered; or
                              </p>
                           </NumberedTypography>

                           <NumberedTypography>
                              <span>1.4.6</span>{' '}
                              <p>
                                 Any other criteria that may be decided by the
                                 Company from time to time.
                              </p>
                           </NumberedTypography>
                        </Box>
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.5</span>{' '}
                     <p>
                        To process the return of a Product a customer must
                        communicate such request on any of the modes as
                        prescribed below: -
                        <Box sx={{ ml: 5, mt: 2 }}>
                           <NumberedTypography>
                              <span>1.5.1</span>{' '}
                              <p>
                                 WhatsApp – (+91) 8600694140
                                 <br />
                                 Email – sumit@solruf.com
                                 <br />
                                 Call - (+91) 8600694140
                                 <br />
                                 Mail/Courier - Agarwal Bunglaw, Behind Atul
                                 Mangal Karyalaya, Rukmini Nagar, Amravati, MH,
                                 444606, India
                              </p>
                           </NumberedTypography>
                        </Box>
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.6</span>{' '}
                     <p>
                        Any customer can initiate a return request by contacting
                        on the above-mentioned WhatsApp/email within 48
                        (forty-eight) hours of the delivery of the Product(s).
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.7</span>{' '}
                     <p>
                        The customer is required to send the photographs/videos/
                        documents of the Product (exterior image of the box from
                        all sides, interior image with packaging, product code,
                        damaged product image, the image of the shipping label)
                        to be returned along with the Product’s receipt
                        generated at the time of order.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.8</span>{' '}
                     <p>
                        All Products to be returned must be in unused condition
                        with all the original packing, tags, inbox literature,
                        and warranty/ guarantee card intact.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.9</span>{' '}
                     <p>
                        Upon initiating a request to process return, the
                        customer will be contacted by a Solruf’s executive for
                        further verification via e-mail, text message, WhatsApp,
                        or call as per the contact details provided by the
                        customer at the time of purchasing the Product.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.10</span>{' '}
                     <p>
                        Once the Solruf’s executive is satisfied with the
                        verification and/or clarification of the customer, they
                        shall confirm such request for return by the customer
                        and shall initiate a process for a replacement or
                        refund, as the case may be, within 3 days of accepting
                        such requests.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>1.11</span>{' '}
                     <p>
                        In case the customer has initiated a return request
                        which has been accepted by the Company’s executive, the
                        customer shall have an option to buy a similar or any
                        other Product / Services based on the customer’s
                        preferences as communicated to Solruf’s executive;
                        however, in the event the Product or service to be
                        replaced is out of stock / capacity, then the customer
                        shall be given a refund of the amount paid by the
                        Customer. However, such refund shall not include the
                        shipping cost as that shall be borne by the customer
                        unless as specified in this Policy.
                     </p>
                  </NumberedTypography>
               </Box>
            </Box>

            <Box>
               <Typography variant='h6' gutterBottom>
                  2. REFUND POLICY
               </Typography>
               <Box sx={{ ml: 5 }}>
                  <NumberedTypography>
                     <span>2.1</span>{' '}
                     <p>
                        Solruf shall reserve the right to cancel the order in
                        case the customer or the vendor/installer does not
                        follow / adhere to the Company’s policies including but
                        not limited to terms of use, privacy policy, vendor
                        policy, payment and delivery timeline and designated
                        party agreements. Payment and delivery timeline. In such
                        an event, Solruf shall charge a penalty fees as shall be
                        communicated to the customer/vendor/installer and the
                        remaining amount shall be returned to the
                        customer/vendor/ installer into the source payment
                        account.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.2</span>{' '}
                     <p>
                        Solruf shall be responsible for carrying out the return
                        and refund processes initiated by the Customer including
                        the payment procedure and the shipping procedure subject
                        to the vendor’s approval, Solruf approval and the terms
                        of the Solruf’s Return and Refund Policy.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.3</span>{' '}
                     <p>
                        All refund requests shall be processed within 7 (seven)
                        working days of completion of the return or cancellation
                        of the Product and Service in the customer’s source
                        account.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.4</span>{' '}
                     <p>
                        If a customer cancels an order while the order has not
                        been dispatched, in such case the amount shall be
                        refunded within 4 working days of cancellation subject
                        to deduction of cost incurred, if any. However, in the
                        event a customer cancels an order after it has been
                        dispatched but not delivered to the customer, a sum
                        amounting to two-way shipping charges and handling cost
                        as applicable shall be deducted and the rest shall be
                        refunded within 7 days of cancellation.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.5</span>{' '}
                     <p>
                        No refund shall be initiated in the event the Product or
                        Service has been received by the Customer.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.6</span>{' '}
                     <p>
                        The Company shall refund the shipping cost along with
                        the cost of the Product to the Customer, only in the
                        event where (i) a damaged Product is delivered to
                        customer, wherein such damage is due to the fault
                        directly attributable to the Company or the logistics
                        partner of the Company; or (ii) a wrong Product that is
                        a Product different than the Product ordered by the
                        customer has been delivered.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.7</span>{' '}
                     <p>
                        Any Product and services purchased in pursuance of an
                        ongoing offer shall be replaced in accordance with this
                        Policy.
                     </p>
                  </NumberedTypography>

                  <NumberedTypography>
                     <span>2.8</span>{' '}
                     <p>
                        The Company shall initiate all refunds in the original
                        source account of the customer through which payment for
                        the Product and/or Service was made by the customer.
                     </p>
                  </NumberedTypography>
               </Box>
            </Box>

            <Box>
               <NumberedTypography>
                  <span>3</span>{' '}
                  <p>
                     This Policy is subject to change without prior notice. The
                     Company shall have the final discretion for accepting the
                     return and refund requests. The customer is hence
                     recommended to read this Policy from time to time to be
                     abreast with any such changes.
                  </p>
               </NumberedTypography>
            </Box>
         </Container>
      </Box>
   );
};

export default ReturnPolicy;
