import { Box } from '@mui/material';
import React from 'react';
import { extractVideoId } from '../../utils/utils';

const EmbedYTVideo = ({ videoUrl, width = '560', height = '315', sx }) => {
   // const videoUrl = 'https://www.youtube.com/watch?v=6mNE1kQEjjU'; // Replace with your YouTube video URL
   // Extract the video ID from the URL
   // const videoId = videoUrl ? videoUrl.match(/v=([^&]+)/)[1] : '';
   const videoInfo = extractVideoId(videoUrl);

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', ...sx }}>
         <Box
            sx={{
               position: 'relative',
               p: 2,
               overflow: 'hidden',
               boxShadow: '0px 4px 24px  0 rgba(0, 69, 184, 0.15)',
               borderRadius: '15px',
               border: '2px solid #ffd05b',
               '& iframe': {
                  borderRadius: '10px',
               },
            }}
         >
            <iframe
               title='YouTube Video Player'
               width={width}
               maxWidth='100%'
               height={height}
               src={
                  videoInfo.isShorts
                     ? `https://www.youtube.com/shorts/${videoInfo.videoId}`
                     : `https://www.youtube.com/embed/${videoInfo.videoId}`
               }
               frameBorder='0'
               allowFullScreen
            ></iframe>
         </Box>
      </Box>
   );
};

export default EmbedYTVideo;
