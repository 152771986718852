import {
  Container,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import ItemList from "../../components/ItemList/ItemList";

// steppers
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SolrufTextField from "../../components/SolrufTextField/SolrufTextField";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea";
// select

import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import "./Checkout.css";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useForm } from "react-hook-form";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SolrufModal from "../../components/Custom/SolrufModal/SolrufModal";
import CheckoutImg from "../../media/Svg/CheckoutImg.svg";
import Statement1 from "../../media/Svg/Statement1.svg";
import Statement2 from "../../media/Svg/Statement2.svg";
import Statement3 from "../../media/Svg/Statement3.svg";
import { axiAuth, axiosInstance } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/Custom/PrimaryButton/PrimaryButton";
import {
  BankDetailBox,
  DeliveredToBox,
  Form,
  ItemContainer,
  OtpBox,
  OtpInputBox,
  StepNavigatorBox,
  StepperBox,
  TotalPriceContainer,
  Ul,
} from "./checkout.style";
import { toast } from "react-toastify";
// import useAuth from '../../hooks/useAuth';
import PageTitle from "../../components/PageTitle/PageTitle";
import Coupon from "../../components/Coupon/Coupon";
import CouponAppliedCard from "../../components/Coupon/CouponAppliedCard";
import {
  calculateOnlyPrice,
  calculateTotalPrice,
  // calculateTotalSumDimensionsAndWeight,
  getProductNames,
} from "./checkout.utils";
// import axios from 'axios';

// import { loginToShipRocket } from '../../components/CheckoutModalContent/checkout.api';
import { removeCart } from "../../redux/slices/newCartSlice/newCartSlice";

const steps = [
  "Step 1 - Address",
  "Step 2 - OTP Confirmation",
  "Step 3 - Payment",
];

const Checkout = () => {
  const [district, setDistrict] = useState("");
  const [activeState, setActiveState] = useState(0);
  const dispatch = useDispatch();
  // const { user, role } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pinCode: "",
    },
  });
  const [couponInfo, setCouponInfo] = useState({
    less: 0,
    title: "",
    expiresAt: "",
    valid: false,
  });

  const watchPinCode = watch("pinCode");

  const [debouncedPinCode] = useDebounce(watchPinCode, 600);
  const [phone, setPhone] = useState("");
  const [hash, setHash] = useState("");
  const [userData, setUserData] = useState({});
  const [checkModal, setCheckModal] = useState(false);

  const { newCart } = useSelector((state) => state.newCart);

  console.log(newCart);

  const [total, setTotal] = useState();
  const [grandTotal, setGrandTotal] = useState(0);
  const [discount, setDiscount] = useState(null);

  // const [minFreightCharge, setMinFreightCharge] = useState(0);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentID, setPaymentId] = useState("");
  const [affordabilityCompleted, setAffordabilityCompleted] = useState(false);

  const handleActiveStateChange = (e) => {
    if (activeState === 0) {
      setActiveState(1);
    } else if (activeState === 1) {
      setActiveState(2);
    }
  };

  const handleBack = (e) => {
    e.stopPropagation();
    setActiveState(activeState - 1);
  };
  const verifyHandle = () => {
    setCheckModal(true); //will check later
    setActiveState(2 - 1);
  };

  // const checkUnderStandHandle = () => {
  //    setCheckModal(false);
  //    setActiveState(2);
  //
  // };

  useEffect(() => {
    if (debouncedPinCode.length !== 6) return;
    axiAuth
      .get(`api/pin-code/search?pin_code=${debouncedPinCode}`)
      .then(({ data }) => {
        setIndiaState(data.pin_code.state);
        setDistrict(data.pin_code.district);
      })
      .catch((err) => {});
  }, [debouncedPinCode]);

  const [otp, setOtp] = useState("");

  // useEffect(() => {

  //   if (activeState === 2) {
  //     const widgetConfig = {
  //       key: "rzp_test_5n3r2EkkJI8wZe", // Replace with your actual Test Key ID
  //       amount: grandTotal * 100, // Amount in paise
  //     };

  //     const rzpAffordabilitySuite = new window.RazorpayAffordabilitySuite(
  //       widgetConfig
  //     );

  //     rzpAffordabilitySuite.render();
  //   }
  // }, [activeState, grandTotal]);

  const submitHandler = async (formData, e) => {
    if (activeState === 0) {
      setPhone(formData.phone);
      setUserData(formData);
      axiosInstance
        .post(`api/mobile/send-otp`, {
          mobile: formData.phone,
          type: "checkout",
        })
        .then(({ data }) => {
          setHash(data.hash);
          handleActiveStateChange();
        })
        .catch((err) => {
          toast.warn("Otp sent fail");
        });

      return;
    }

    if (activeState === 1) {
      // verify and proceed
      //  need to uncomment below codes
      // axiosInstance
      //    .post(`api/mobile/verify-otp`, {
      //       mobile: formData.phone,
      //       otp,
      //       hash,
      //    })
      //    .then((res) => {
      //       verifyHandle();
      //       setHash('');
      //       setActiveState(2);
      //    })
      //    .catch((err) => {
      //       toast.warn(err.response.data.message);
      //    });
      verifyHandle();
      setHash("");
      setActiveState(2);
    }

    if (activeState === 2) {
      // making the actual order api call and payment
      const paymentData = {
        ...formData,
        city: district,
        pincode: formData.pinCode,
        state: indiaState,
      };
      setCheckModal(false);
      await handlePayment(paymentData);
    }
  };

  const handlePayment = async (formData) => {
    try {
      const {
        data: { order },
      } = await axiAuth.post(
        `https://api-dev-v2.solruf.com/api/v2/payment/create`,
        // `http://localhost:5000/api/v2/payment/create`,
        {
          amount: grandTotal,
          orderDetails: {
            products: newCart.map((cartItem) => ({
              qty: cartItem.quantity,
              price: calculateOnlyPrice(cartItem),
              id: cartItem.product_id,
            })),
            product_name: getProductNames(newCart),
            couponInfo,
            minFreightCharge: 100,
            formData,
          },
        }
      );

      // need later start

      const options = {
        // key: "rzp_test_5n3r2EkkJI8wZe",
        key: 'rzp_live_Aqc829jh4Ocj5u',
        amount: order.amount,
        currency: "INR",
        name: "Solruf",
        description: `Payment for ${getProductNames(newCart)}`,
        image:
          "https://i.ibb.co/rws9hV3/Whats-App-Image-2023-05-27-at-12-56-54.jpg",
        order_id: order.id,
        handler: async function (response) {
          try {
            const {
              // razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
            } = response;
            const { status, data } = await axiAuth.post(
              'https://api-dev-v2.solruf.com/api/v2/payment/verification',
              // "http://localhost:5000/api/v2/payment/verification",
              {
                razorpay_payment_id,
                razorpay_order_id: order.id,
                razorpay_signature,
                amount: Math.round(grandTotal),
                product_count: newCart.map((cartItem) => cartItem.product_id),
                formData,
              }
            );

            if (status === 200) {
              setPaymentId(data.razorpay_payment_id);
              setPaymentSuccess(true);

              await axiAuth.get("/api/shoppingcarts/clear");

              dispatch(removeCart());
              setActiveState(3);
            }
          } catch (error) {}
        },
        prefill: {
          name: formData?.name,
          email: "test@mail.com",
          contact: formData?.phone,
        },
        notes: {
          address: "Mumbai",
        },
        theme: {
          color: "#ffd05b",
        },
        config: {
          display: {
            blocks: {
              utib: {
                //name for Axis block
                name: "Pay using Axis Bank",
                instruments: [
                  {
                    method: "card",
                    issuers: ["UTIB"],
                  },
                  {
                    method: "netbanking",
                    banks: ["UTIB"],
                  },
                ],
              },
              other: {
                //  name for other block
                name: "Other Payment modes",
                instruments: [
                  {
                    method: "card",
                    issuers: ["ICIC"],
                  },
                  {
                    method: "netbanking",
                  },
                  {
                    method: "upi",
                    apps: ["google_pay", "phonepe"],
                  },
                ],
              },
            },
            hide: [
              {
                method: "",
              },
            ],
          },
        },
      };

      // Open Razorpay Payment Modal
      const razor = new window.Razorpay(options);
      razor.open();

      // need later end
    } catch (error) {
      console.log(error);
    }
  };

  const [indiaState, setIndiaState] = useState("state");

  const statesOfIndia = useSelector((state) => state.utils.statesOfIndia);

  const handleStateChange = (e) => {
    setIndiaState(e.target.value);
  };

  const watchPhone = watch("phone");

  const resentOtpHandler = (e) => {
    axiosInstance
      .post(`api/mobile/send-otp`, {
        mobile: watchPhone,
        type: "checkout",
      })
      .then(({ data }) => {
        setHash(data.hash);
        toast.success("OTP resent successfully");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const totalCartPrice = calculateTotalPrice(newCart);
    setTotal(totalCartPrice);
    const gTotal = totalCartPrice;
    if (couponInfo.valid) {
      const percentage = couponInfo.less;
      const discount = (percentage / 100) * gTotal;
      setDiscount(discount);
      const discountedGrandTotal = gTotal - discount;
      setGrandTotal(discountedGrandTotal);
    } else {
      setGrandTotal(gTotal);
      setDiscount(null);
    }
  }, [couponInfo.less, couponInfo.valid, newCart]);

  const couponRemoveHandler = () => {
    setCouponInfo({ less: 0, title: "", expiresAt: "", valid: false });
  };

  // useEffect(() => {
  //    if (!watchPinCode || watchPinCode.length < 6) return;

  //    loginToShipRocket().then((token) => {
  //       const config = {
  //          headers: {
  //             Authorization: `Bearer ${token}`,
  //          },
  //       };

  //       const { totalHeight, totalLength, totalBreadth, totalWeight } =
  //          calculateTotalSumDimensionsAndWeight(newCart);

  //       console.log({ totalHeight, totalLength, totalBreadth, totalWeight });

  //       axios
  //          .get(
  //             `https://apiv2.shiprocket.in/v1/external/courier/serviceability/?cod=1&pickup_postcode=444606&delivery_postcode=${watchPinCode}&weight=${totalWeight}&length=${totalLength}&breadth=${totalBreadth}&height=${totalHeight}`,
  //             config
  //          )
  //          .then((data) => {
  //             console.log(data.data);
  //             setMinFreightCharge(
  //                Math.min(
  //                   ...data.data?.data.available_courier_companies?.map(
  //                      (c) => c.rate
  //                   )
  //                )
  //             );
  //          })
  //          .catch((err) => {
  //             // toast.error(
  //             //    err?.response?.data?.message || 'Something Went Wrong'
  //             // );
  //          });
  //    });

  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [watchPinCode, newCart]);

  return (
    <Box sx={{ py: 4, background: "#f3f3f3" }}>
      <PageTitle title="Checkout" />
      <Container maxWidth="xl" sx={{ p: [0, 2] }}>
        <Grid container spacing={2}>
          {/* ========= left side ========= */}

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              textAlign="center"
              fontWeight={600}
              gutterBottom
              component="h1"
            >
              Shopping Cart
            </Typography>
            <ItemContainer>
              {newCart.length > 0 &&
                newCart.map((cartItem) => <ItemList item={cartItem} />)}
            </ItemContainer>
          </Grid>

          {/* ========= right side ========= */}

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: "#fff",
                boxShadow: "0px 4px 24px 0  rgba(0, 69, 184, 0.15)",
                borderRadius: "8px",
                mb: 2.5,
              }}
            >
              <TotalPriceContainer>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  direction={"row"}
                >
                  <Typography variant="h4" fontWeight={600}>
                    Total Price
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    Rs.{total}
                  </Typography>
                </Stack>

                {couponInfo.valid && discount && (
                  <Typography
                    fontWeight={600}
                    sx={{
                      color: "primary.dark",
                      fontSize: ["1rem", "1.5rem", "1.7rem"],
                    }}
                  >
                    Coupon Discount: Rs. {discount}
                  </Typography>
                )}

                <Typography
                  fontWeight={600}
                  sx={{
                    color: "primary.dark",
                    fontSize: ["1rem", "1.5rem", "1.7rem"],
                  }}
                >
                  Grand Total: Rs. {grandTotal}
                </Typography>
              </TotalPriceContainer>

              <StepperBox
                sx={{
                  width: "100%",
                  background: "#fff",
                  borderRadius: "8px",
                }}
              >
                <Stepper
                  activeStep={activeState}
                  alternativeLabel
                  className="stepper__box"
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </StepperBox>
              {/* ================== changing area ================== */}
              {/*  coupon section */}
              {activeState === 0 && !couponInfo?.valid && (
                <Coupon couponInfo={couponInfo} setCouponInfo={setCouponInfo} />
              )}

              {couponInfo.valid && (
                <CouponAppliedCard
                  couponInfo={couponInfo}
                  couponRemoveHandler={couponRemoveHandler}
                />
              )}

              <Form component="form" onSubmit={handleSubmit(submitHandler)}>
                {activeState === 0 && (
                  <DeliveredToBox component="form">
                    <Typography
                      variant="h4"
                      fontWeight={600}
                      sx={{ mb: "2rem" }}
                    >
                      Delivered To :
                    </Typography>
                    <SolrufTextField
                      size="small"
                      label="Name"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          background: "#ffffff",
                        },
                      }}
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Name is required",
                        },
                      })}
                      error={errors?.name}
                      helperText={errors.name && errors.name.message}
                    />
                    <Box sx={{ display: "flex", my: 1 }}>
                      <SolrufTextField
                        size="small"
                        label="E-mail Address"
                        sx={{ mr: 1, background: "#ffffff" }}
                        {...register("userEmail", {
                          required: {
                            value: true,
                            message: "User Email is Required",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        error={errors?.userEmail}
                        helperText={
                          errors.userEmail && errors.userEmail.message
                        }
                      />
                      <SolrufTextField
                        size="small"
                        type="number"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: "Phone is Required",
                          },
                        })}
                        error={errors?.phone}
                        helperText={errors.phone && errors.phone.message}
                        label="Phone Number"
                        sx={{ background: "#ffffff" }}
                      />
                    </Box>
                    <CustomTextArea
                      placeholder="Address"
                      style={{ background: "#ffffff", marginTop: 0 }}
                      {...register("address", {
                        required: {
                          value: true,
                          message: "Address is required",
                        },
                      })}
                      errorMessage={errors?.address?.message}
                    />
                    <Box sx={{ display: "flex", mt: 2, mb: 1.6 }}>
                      <SolrufTextField
                        size="small"
                        label="Pin code"
                        sx={{ background: "#ffffff", mr: 1 }}
                        {...register("pinCode", {
                          required: {
                            value: true,
                            message: "Pin Code is required",
                          },
                        })}
                        error={errors.pinCode ? true : false}
                        helperText={errors.pinCode && errors.pinCode.message}
                      />
                      <SolrufTextField
                        size="small"
                        label="City/District/Town"
                        sx={{ background: "#ffffff" }}
                        value={district}
                      />
                    </Box>

                    <SolrufTextField
                      size="small"
                      label="State"
                      value={indiaState}
                      sx={{ background: "#fff" }}
                      select
                      onChange={handleStateChange}
                    >
                      {statesOfIndia.map((state) => (
                        <MenuItem value={state}>{state}</MenuItem>
                      ))}
                    </SolrufTextField>
                  </DeliveredToBox>
                )}

                {activeState === 1 && (
                  <OtpBox>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography variant="h5" fontWeight={600}>
                        Confirm Address Details
                      </Typography>
                    </Box>
                    <Ul>
                      <li>
                        <Typography fontWeight={600}>
                          <PersonIcon sx={{ mr: 1 }} /> Name
                        </Typography>
                        <Typography>{userData.name}</Typography>
                      </li>
                      <li>
                        <Typography fontWeight={600}>
                          <EmailIcon sx={{ mr: 1 }} /> E-mail
                        </Typography>
                        <Typography>{userData.userEmail}</Typography>
                      </li>
                      <li>
                        <Typography fontWeight={600}>
                          <PhoneIcon sx={{ mr: 1 }} /> Phone
                        </Typography>
                        <Typography>{userData.phone}</Typography>
                      </li>
                      <li>
                        <Typography fontWeight={600}>
                          <LocationOnIcon sx={{ mr: 1 }} /> Location
                        </Typography>
                        <Typography>{userData.address}</Typography>
                      </li>
                      <li>
                        <Typography fontWeight={600}>
                          <ApartmentIcon sx={{ mr: 1 }} /> City
                        </Typography>
                        <Typography>{district}</Typography>
                      </li>
                    </Ul>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      textAlign="center"
                      mt={5}
                    >
                      Enter OTP
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      textAlign="center"
                    >
                      Sent to {phone}
                    </Typography>

                    <OtpInputBox>
                      <input
                        type="text"
                        placeholder="Enter OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                      />
                      {/* <span>00:45</span> */}
                      <button
                        type="button"
                        onClick={resentOtpHandler}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Resent Otp
                      </button>
                    </OtpInputBox>
                  </OtpBox>
                )}

                {activeState === 3 && paymentSuccess && (
                  <BankDetailBox>
                    <Stack
                      sx={{ my: 2, mx: "auto", fontWeight: "500" }}
                      direction="row"
                      alignItems="center"
                      gap={3}
                    >
                      <CheckCircleRoundedIcon sx={{ color: "#3FB500" }} />
                      <Typography variant="h5" sx={{ color: "#3FB500" }}>
                        Order Confirmed! Transaction Id: {paymentID}
                      </Typography>
                    </Stack>
                  </BankDetailBox>
                )}

                {activeState !== 2 && (
                  <StepNavigatorBox>
                    {activeState === 1 && (
                      <PrimaryButton
                        onClick={handleBack}
                        IconStart={ArrowBackIcon}
                        sx={{
                          flex: "1",
                          boxShadow: "none",
                          borderRadius: "0 0 0 8px",
                          background: "#f3f3f3",
                          py: [1.5, 1],
                          position: ["fixed", "relative"],
                          bottom: 0,
                          left: 0,
                          width: "50%",
                        }}
                      >
                        Previous
                      </PrimaryButton>
                    )}
                    {activeState === 0 && (
                      <PrimaryButton
                        sx={{
                          px: "4rem",
                          ml: [0, "auto"],
                          mb: [0, 3],
                          mr: [0, 5],
                          py: 1,
                          width: ["100%", "auto"],
                          position: ["fixed", "relative"],
                          bottom: 0,
                        }}
                        type="submit"
                        IconEnd={ArrowForwardIcon}
                      >
                        Next Step
                      </PrimaryButton>
                    )}
                    {activeState === 1 && (
                      <PrimaryButton
                        // onClick={verifyHandle}
                        IconEnd={ArrowForwardIcon}
                        sx={{
                          flex: "1",
                          boxShadow: "none",
                          borderRadius: "0 0 8px 0",
                          py: [1.5, 1],
                          position: ["fixed", "relative"],
                          bottom: 0,
                          right: 0,
                          width: "50%",
                        }}
                        type="submit"
                      >
                        Verify & Proceed
                      </PrimaryButton>
                    )}
                  </StepNavigatorBox>
                )}
                {/* ====== modal area ====== */}
                <SolrufModal
                  open={checkModal}
                  onClose={() => setCheckModal(false)}
                >
                  {/* <div id="razorpay-affordability-widget"> </div> */}
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <img src={CheckoutImg} alt="I understand" />
                    <h5
                      className="mt-4"
                      style={{ color: "#000000", fontSize: "22px" }}
                    >
                      SOLRUF won’t take money from your bank Automatically
                    </h5>
                    <p className="mt-3">
                      Manually transfer the money from your bank
                    </p>
                  </Box>
                  {/* <div id="razorpay-affordability-widget"> </div> */}
                  <Stack sx={{ mt: 5 }}>
                    <p>
                      <img
                        width="35"
                        className="mr-2 mt-2"
                        src={Statement1}
                        alt="statement1"
                      />{" "}
                      Solruf team will share the Bank details with you for
                      payment
                    </p>
                    <p>
                      <img
                        width="35"
                        className="mr-2 mt-2"
                        src={Statement2}
                        alt="statement2"
                      />{" "}
                      Transfer the Product amount through NEFT, IMPS, UPI
                    </p>
                    <p>
                      <img
                        width="40"
                        className="mr-1 mt-2"
                        src={Statement3}
                        alt="statement3"
                      />{" "}
                      Solruf will deliver the order within 7 working days of
                      order
                    </p>
                  </Stack>

                  <PrimaryButton
                    sx={{ width: "100%", marginTop: "40px" }}
                    onClick={() => handleSubmit(submitHandler)()}
                    // onClick={() => setCheckModal(false)}
                  >
                    I Understand
                  </PrimaryButton>
                </SolrufModal>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Checkout;
