export const formatDocumentUrl = (documents) => {
   return documents
      .filter((document) => document.error.length === 0)
      .map((document) => {
         return {
            name: document.file.givenName,
            url: document.url,
         };
      });
};

// errors version of formatDocumentUrl
export const formatDocumentUrl0 = (documents) => {
   return documents
      .filter((document) => document?.errors?.length === 0)
      .map((document) => {
         return {
            name: document.file.givenName,
            url: document.url,
         };
      });
};

export const formatDocumentUrl1 = (documents) => {
   return documents
      .filter((document) => document.error.length === 0)
      .map((document) => {
         return {
            name: document.file.givenName,
            url: document.url,
         };
      });
};

export const formatDocumentsWithNameAndUrl = (documents) => {
   return documents
      .filter((document) => document.errors.length === 0)
      .map((document) => {
         return {
            name: document.file.givenName
               ? document.file.givenName
               : document.file.name,
            url: document.url,
         };
      });
};

export const formatYoutubeVideoUrl = (url) => {
   // format youtube video url
   return (
      url &&
      url
         .replace('watch?v=', 'embed/')
         .replace('/shorts/', '/embed/')
         .split('?')[0]
         .split('&')[0]
   );
};

// counting due date from today
export const countDueDate = (date, days) => {
   const copy = new Date(Number(date));
   copy.setDate(date.getDate() + days);
   return copy;
};

export const arraysAreEqual = (a1, a2) => {
   if (a1.length !== a2.length) {
      return false;
   }
   for (let i = 0; i < a1.length; i++) {
      if (Array.isArray(a1[i]) && Array.isArray(a2[i])) {
         if (!arraysAreEqual(a1[i], a2[i])) {
            return false;
         }
      } else if (a1[i] !== a2[i]) {
         return false;
      }
   }
   return true;
};

export const goToWhatsapp = () => {
   window.location.href =
      'https://api.whatsapp.com/send?phone=+918976588792&text=Hello%20there%21%20I%20would%20like%20to%20know%20more%20about%20SOLRUF%20or%20would%20like%20to%20purchase%20a%20solar%20product.%20Can%20you%20help%20us%20with%20this%3F';
};

export function createFormDataFromObject(dataObject) {
   const formData = new FormData();

   for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
         formData.append(key, dataObject[key]);
      }
   }

   return formData;
}

export const getTimeStamp = () => {
   const timestamp = Date.now();
   return new Date(timestamp).toLocaleString();
};

// get current time in this format 2023-06-15 11:11
export function getCurrentTime() {
   const currentDate = new Date();

   const year = currentDate.getFullYear();
   const month = String(currentDate.getMonth() + 1).padStart(2, '0');
   const day = String(currentDate.getDate()).padStart(2, '0');
   const hours = String(currentDate.getHours()).padStart(2, '0');
   const minutes = String(currentDate.getMinutes()).padStart(2, '0');

   return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function convertToParagraph(text) {
   if (!text || text.length < 1) return;
   const lines = text.split('\n');
   let paragraph = '';

   for (let line of lines) {
      line = line.trim(); // Remove leading/trailing whitespace

      if (line) {
         paragraph += `${line}<br><br>`; // Add line with <br> tag
      }
   }

   return paragraph;
}

export const extractVideoId = (videoUrl) => {

   const regularVideoMatch = videoUrl.match(
      /(?:youtu.be\/|youtube\.com\/watch\?v=)([^&]+)/
   );
   const shortsVideoMatch = videoUrl.match(
      /(?:youtu.be\/|youtube\.com\/shorts\/|v=)([^&]+)/
   );

   if (regularVideoMatch) {
      return { videoId: regularVideoMatch[1], isShorts: false };
   } else if (shortsVideoMatch) {
      return { videoId: shortsVideoMatch[1], isShorts: true };
   } else {
      return { videoId: '', isShorts: false };
   }
};

export const calculateFlatRate = (loanAmount, rate, tenure) => {
   if (!loanAmount || !rate || !tenure) {
      return 'Loan Amount, Rate of Interest and Tenure is required';
   }

   const loanAmountNumber = +loanAmount;
   const rateNumber = +rate;
   const tenureNumber = +tenure;


   const totalInterest = (loanAmountNumber * rateNumber * tenureNumber) / 100;
   const totalAmount = loanAmountNumber + totalInterest;
   const emi = totalAmount / (tenureNumber * 12);
   return { emi, totalInterest, totalAmount };
};

export const calculateReducingRate = (loanAmount, rate, tenure) => {
   if (!loanAmount || !rate || !tenure) {
      return 'Loan Amount, Rate of Interest and Tenure is required';
   }

   const loanAmountNumber = +loanAmount;
   const rateNumber = +rate;
   const tenureNumber = +tenure;

   const monthlyInterestRate = rateNumber / (12 * 100);
   const emi =
      (loanAmountNumber * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -tenureNumber * 12));
   const totalAmount = emi * tenureNumber * 12;
   const totalInterest = totalAmount - loanAmountNumber;
   return { emi, totalInterest, totalAmount };
};

export const sortAttribute = (viewPoint, attributeList = []) => {
console.log(attributeList)
   return attributeList.sort((a, b) => {
      // Extract the "product_feature_list" level from attribute_values
      const levelA = a.attribute_values[0].views[viewPoint].level;
      const levelB = b.attribute_values[0].views[viewPoint].level;
    
      // Compare levels, lower levels first
      return levelA - levelB;
    });
}

export const calculateBomTotalPrice = (data) => {
   let totalPrice = 0;
 
   data?.forEach((item) => {
     const {
       properties: {
         product_price,
         units_per_quantity,
         igst,
         cgst,
         sgst
       }
     } = item;
 
     const price = parseFloat(product_price);
     const quantity = parseInt(units_per_quantity, 10);
     const igstPercent = parseFloat(igst);
     const cgstPercent = parseFloat(cgst);
     const sgstPercent = parseFloat(sgst);
 
     const totalBeforeTax = price * quantity;
     const totalTax = (totalBeforeTax * (igstPercent + cgstPercent + sgstPercent)) / 100;
     const totalWithTax = totalBeforeTax + totalTax;
 
     totalPrice += totalWithTax;
   });
 
   return totalPrice.toFixed(2);
 };
 
 
export function generateOrderId() {
   const prefix = 'order_';
   const randomChars = generateRandomChars(14);
   return `${prefix}${randomChars}`;
}

function generateRandomChars(length) {
   const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   let result = '';
   for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars.charAt(randomIndex);
   }
   return result;
}




export function truncateString(string, length) {
   if (string.length <= length) {
       return string;
   } else {
       return string.slice(0, length) + "...";
   }
}

export const handleExternalLink = (e, url) => {
   e.preventDefault();
   window.open(url, '_blank');
 };

 export function capitalizeAndSeparateWords(str) {
   // Split the string by camel case or underscore
   let words = str.split(/(?=[A-Z])|_/);
 
   // Capitalize each word and join them with a space
   return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
 }
