import React, { useEffect, useState } from 'react';
import PrimaryButton from '../Custom/PrimaryButton/PrimaryButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Bounce } from 'react-reveal';
import { keyframes } from '@mui/system';

const shakyAnimation = keyframes`
0%, 100% {
  transform: translateX(0);
}
10%, 30%, 50%, 70%, 90% {
  transform: translateX(-5px) rotate(-5deg);
}
20%, 40%, 60%, 80% {
  transform: translateX(5px) rotate(5deg);
}

`;

const LinkToWhatsappButton = ({ message, destination }) => {
   const phoneNumber = `+91${destination}`; // Replace with your WhatsApp number

   const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
   )}`;

   const [animate, setAnimate] = useState(false);

   useEffect(() => {
      const interval = setInterval(() => {
         setAnimate(true);
         setTimeout(() => {
            setAnimate(false);
         }, 2000); // Animation duration
      }, 5000); // Animation interval

      return () => {
         clearInterval(interval);
      };
   }, []);

   return (
      <Bounce>
         <PrimaryButton
            variant='contained'
            color='primary'
            href={whatsappUrl}
            target='_blank'
            rel='noopener noreferrer'
            IconEnd={WhatsAppIcon}
            sx={{
               display: 'flex',
               width: '100%',
               maxWidth: '360px',
               mx: 'left',
               fontSize: '1rem',
               textAlign: 'center',
               
               py: 1.5,
               bgcolor: '#25D366',
               color: '#ffffff',
               '& .MuiSvgIcon-root': {
                  fontSize: '28px !important',
                  fontWeight: 'bold',
                  animation: `${
                     animate ? shakyAnimation : 'none'
                  } 2s ease-in-out`,
               },
               '&:hover': {
                  bgcolor: '#ffffff',
                  color: '#25D366',
               },
            }}
         >
            Create this product enquiry
         </PrimaryButton>
      </Bounce>
   );
};

export default LinkToWhatsappButton;
