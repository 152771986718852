import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CopyText from "../../components/CopyText/CopyText";
import LinkToWhatsappButton from "../../components/LinkToWhatsappButton/LinkToWhatsappButton";

// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const EnquiryProductDetails = ({
  productDetails,
  showVendorName,
  purchasePage,
  pathname,
  checkout,
}) => {
  const averagePrice = productDetails?.attributes?.find(
    (item) => item.name === "Average Price"
  );

  const price = averagePrice?.attribute_values[0]?.value || 0;
  useEffect(() => {
    // const affordabilityWidget = new window.RazorpayAffordabilityWidget({
    //   key: "rzp_test_5n3r2EkkJI8wZe", // Replace with your live/test key
    //   amount: grandTotal, // Example amount in paise (78.88 INR)
    //   currency: "INR",
    // });
    // key: "rzp_test_5n3r2EkkJI8wZe",
    const widgetConfig = {
      key: "rzp_live_Aqc829jh4Ocj5u",
      amount: price * 100, // Amount in paise
    };

    const rzpAffordabilitySuite = new window.RazorpayAffordabilitySuite(
      widgetConfig
    );

    rzpAffordabilitySuite.render();
  }, [price]);

  console.log({ productDetails });

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "#000000",
        }}
      >
        {productDetails.product_name}
      </Typography>

      {showVendorName && (
        <Typography
          sx={{
            color: "gray",
            fontSize: "12px",
          }}
          component="h1"
        >
          {/*  this is the name of vendor not the product */}
          {productDetails.name}
        </Typography>
      )}

      {/* <PrimaryButton
            sx={{ mt: 5 }}
            onClick={() =>
               navigate(
                  `/checkout-product/${productDetails.product_id}/${productDetails.product_slug}`
               )
            }
         >
            Buy Now
         </PrimaryButton> */}

      <Box sx={{ mt: 4 }}>
        <Typography
          sx={{
            color: "#000000",
            fontSize: "12px",
            mb: 1,
          }}
        >
          Share your personalized product link with your potential customers.
        </Typography>
        <Box>
          {/* <CopyText
                  title={`products/${productDetails?.product_id}/${productDetails?.product_slug}`}
               /> */}
          <CopyText title={`products/${productDetails?.product_id}`} />
        </Box>
      </Box>

      <Stack
        direction="column"
        sx={{
          mt: 4,
        }}
        rowGap={2}
      >
        {!purchasePage && (
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: 500,
              color: "#000000",
            }}
          >
            Avg. Pricing :{" "}
            {`${averagePrice?.attribute_values[0]?.value || "n/a"} ${
              averagePrice?.attribute_values[0]?.value_unit || ""
            }`}
          </Typography>
        )}

        {/* <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
               }}
            >
               <LocalShippingIcon />

               <Typography
                  sx={{
                     color: '#000000',
                     fontSize: '12px',
                     fontWeight: 'bold',
                     ml: 2,
                  }}
               >
                  Fast Delivery
               </Typography>
            </Box> */}
        {/* <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
               }}
            >
               <MonetizationOnIcon />
               <Typography
                  sx={{
                     color: '#000000',
                     fontSize: '12px',
                     fontWeight: 'bold',
                     ml: 2,
                  }}
               >
                  Instant vendor warranty support
               </Typography>
            </Box>
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
               }}
            >
               <SupportAgentIcon />
               <Typography
                  sx={{
                     color: '#000000',
                     fontSize: '12px',
                     fontWeight: 'bold',
                     ml: 2,
                  }}
               >
                  24 x 7 Support
               </Typography>
            </Box> */}
      </Stack>
      <Typography
        color="error"
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          mt: 4,
        }}
      >
        Get access to top 10 verified source suppliers for your enquiry.
      </Typography>

      {/* <Box sx={{mb: 2, mt: 5}}>
            <LinkToWhatsappButton
               message={`I would like to know more about: https://links.solruf.com/products/${productDetails?.product_id}/${productDetails?.product_slug}`}
               destination='8976588792'
            />
         </Box> */}
      <div id="razorpay-affordability-widget"> </div>
    </Box>
  );
};

export default EnquiryProductDetails;
