import SolrufTextField from "../../SolrufTextField/SolrufTextField";

const TextFieldWithRegister = ({ label, name, register, errors, ...rest }) => (
  <SolrufTextField
    size="small"
    type="text"
    label={label}
    {...register(name, {
      required: {
        value: true,
        message: `${label} is required`,
      },
      ...rest,
    })}
    helperText={errors[name]?.message}
    error={errors[name]}
    {...rest}
  />
);

export default TextFieldWithRegister;
