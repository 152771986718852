import { axiAuth } from './axiosInstance';

const validateGST = async (fieldValue) => {
   try {
      if (fieldValue.length < 15)
         return 'GST Number must be at least 15 characters long';

      const response = await axiAuth.post('api/verify-gst', {
         gstin: fieldValue,
      });

      if (response.status !== 200) {
         return 'Invalid GST Number';
      }
      return true; // Return true if GST number is valid
   } catch (error) {
      if (error.response && error.response.status !== 200) {
         return 'Invalid GST Number';
      }
      return 'Error validating GST Number';
   }
};

export default validateGST;
